import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";

const StyledDataGridWrapper = styled(Box)(({ theme }) => ({
  "& .MuiDataGrid-root": {
    border: "none",
    backgroundColor: "white",
    fontFamily: "Lexend",
  },
  "& .MuiDataGrid-cell": {
    borderBottom: "none !important",
    overflow: "unset!important",
    whiteSpace: "normal",
    lineHeight: "normal",
    minHeight: "50px",
    maxHeight: "none !important",
    wordWrap: "break-word",
    padding: "6px 6px",
    "&:focus": {
      outline: "none!important",
    },
  },
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "white",
    border: "none",
    color: "#93989A",
    fontSize: "12px",
  },
  "& .MuiDataGrid-footerContainer": {
    border: "none",
    backgroundColor: "white",
    "& .MuiTablePagination-toolbar": {
      color: "#93989A",
    },
  },
  "& .MuiDataGrid-columnHeaderTitleContainerContent": {
    overflow: "visible!important",
  },
  "& .MuiCheckbox-root": {
    display: "none",
  },
  "& .MuiDataGrid-row": {
    backgroundColor: "white",
    border: "1px solid #F3F3F3",
    color: "#93989A",
    borderLeft: "none",
    borderRight: "none",
    minHeight: "50px",
    maxHeight: "none !important",
    transition: "transform 0.2s ease-in-out", // Add transition for smooth hover effect
    "&:hover": {
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
      zIndex: 1,
      color: "#202226",
      cursor: "pointer",
      backgroundColor: "inherit",
    },
  },
  "& .MuiDataGrid-overlay": {
    // Target the overlay text directly and apply background color
    backgroundColor: "white",
    color: "#93989A",
  },
  "& .striped": {
    backgroundColor: "#F8F8F8",
    "&:hover": {
      backgroundColor: "#F8F8F8 !important",
    },
  },
  "& .MuiDataGrid-row:hover .MuiDataGrid-cell--with-file": {
    color: "#007bff",
    textDecoration: "underline",
  },
}));

const _CallDataGrid = ({
  rows,
  columns,
  onRowClick,
  height,
  loading,
  rowHeight = 50,
  checkboxSelection,
  onSelectionModelChange,
  localeText,
  selectionModel,
  isRowSelectable,
}) => {
  const [pageSize, setPageSize] = useState(25); // Default page size

  const getRowClassName = (params) => {
    return params.indexRelativeToCurrentPage % 2 === 0 ? "" : "striped";
  };

  return (
    <StyledDataGridWrapper height={height}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={pageSize}
        // pageSizeOptions={[1, 50, 100]} // Set custom page size options
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)} // Update page size
        loading={loading}
        onRowClick={onRowClick}
        localeText={{ noRowsLabel: localeText || "No data" }}
        checkboxSelection={checkboxSelection}
        onSelectionModelChange={onSelectionModelChange}
        selectionModel={selectionModel}
        getRowClassName={getRowClassName}
        isRowSelectable={isRowSelectable}
        disableColumnFilter
        disableColumnMenu
        sx={{
          fontFamily: "Lexend",
          fontSize: "12px",
          color: "#93989A",
          "& .MuiDataGrid-columnHeader:focus-within": {
            outline: "none!important",
          },
          "& .MuiCircularProgress-root": {
            display: "flex!important",
          },
          "& .MuiDataGrid-columnHeaderTitleContainerContent, .MuiDataGrid-cellCheckbox": {
            span: {
              display: "flex",
              color: "#E4E6F2",
              "&:hover": {
                color: "#B6B9CE",
                backgroundColor: "transparent",
              },
              "&.Mui-checked": {
                color: "black",
                backgroundColor: "transparent",
              },
            },
          },
        }}
      />
    </StyledDataGridWrapper>
  );
};

export default _CallDataGrid;
