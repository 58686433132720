import React from 'react';

function ActionCalender({ color }) {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.1123 10.1921C21.1526 10.9799 21.1526 11.9475 21.1526 13.1921C21.1526 15.9878 21.1526 17.3856 20.6959 18.4882C20.0869 19.9584 18.9189 21.1265 17.4487 21.7354C16.3461 22.1921 14.9482 22.1921 12.1526 22.1921C9.35694 22.1921 7.95911 22.1921 6.85649 21.7354C5.38632 21.1265 4.21827 19.9584 3.60931 18.4882C3.15259 17.3856 3.15259 15.9878 3.15259 13.1921C3.15259 11.9475 3.15259 10.9799 3.19289 10.1921M21.1123 10.1921C21.0621 9.21038 20.9493 8.50778 20.6959 7.89604C20.0869 6.42587 18.9189 5.25783 17.4487 4.64886C17.0672 4.49085 16.6504 4.3875 16.1526 4.31991M21.1123 10.1921L3.19289 10.1921M3.19289 10.1921C3.24311 9.21038 3.35592 8.50778 3.60931 7.89604C4.21827 6.42587 5.38632 5.25783 6.85649 4.64886C7.23797 4.49085 7.65478 4.3875 8.15259 4.31991M8.15259 2.19214L8.15259 4.31991M8.15259 4.31991L8.15259 6.19214M8.15259 4.31991C9.09365 4.19214 10.3242 4.19214 12.1526 4.19214C13.981 4.19214 15.2115 4.19214 16.1526 4.31991M16.1526 2.19214L16.1526 4.31991M16.1526 4.31991L16.1526 6.19214M8.16259 14.1921L8.15259 14.1921M8.16259 18.1921L8.15259 18.1921M12.1626 14.1921L12.1526 14.1921M12.1626 18.1921L12.1526 18.1921M16.1626 14.1921L16.1526 14.1921M16.1626 18.1921L16.1526 18.1921" stroke={color || "#B0B7BD"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default ActionCalender;