import React from "react";
import { styled } from "@mui/system";

const StyledSvg = styled('svg')(({ theme, sx }) => ({
    ...sx,
}));

const CheckboxIcon = ({ sx }) => (
    <StyledSvg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        sx={sx}
    >
        <path
            d="M3 12.5C3 9.7 3 8.3 3.5 7.2C4.1 5.7 5.3 4.5 6.7 3.9C7.8 3.5 9.2 3.5 12 3.5C14.8 3.5 16.2 3.5 17.3 3.9C18.8 4.5 20 5.7 20.5 7.2C21 8.3 21 9.7 21 12.5C21 15.3 21 16.7 20.5 17.8C20 19.3 18.8 20.5 17.3 21C16.2 21.5 14.8 21.5 12 21.5C9.2 21.5 7.8 21.5 6.7 21C5.3 20.5 4.1 19.3 3.5 17.8C3 16.7 3 15.3 3 12.5Z"
            stroke="#B0B7BD"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </StyledSvg>
);

export default CheckboxIcon;
