import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import ClientLimitReached from "components/ClientLimitReachedModal";
import GlobalPageComponent from "components/GlobalPageComponent/GlobalPageComponent";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useNavigate } from "react-router-dom";
import CreateBigFiveAssessment from "hooks/assessmentHooks/theBigFiveQueries/useCreateBigFiveAssessment";
import AssignAssessmentModal from "components/Assessments/AssignAssessmentModal";
import AssignAssessmentToTeamModal from "components/Assessments/AssignAssessmentToTeamModal";
import axios from "axios";
import { generateClient } from "aws-amplify/data";
import AssessmentsTable from "components/Assessments/AssessmentsTable";
import { _IndividualAssessmentsFilter } from "components/Assessments/IndividualAssessmentsFilter";
import { _TeamAssessmentsFilter } from "components/Assessments/TeamAssessmentsFilter";
import GeneralBigFiveScore from "components/Assessments/TheBigFive/GeneralBigFiveScore";
import { toast } from "react-toastify";
import { checkUserGroupAlignment } from "helpers/userCognitoGroupCheck";
import * as Frigade from "@frigade/react";
/**
 * @type {import('aws-amplify/data').Client<import('../amplify/data/resource').Schema>}
 */

const resourceClient = generateClient({ authMode: "userPool" });

const CoachAssessments = ({ user, userData }) => {
  const [selectedAssessment, setSelectedAssessment] = useState(
    "The Big Five Personality Test"
  );

  console.log("******* user data: ", userData)
  const [teamData, setTeamData] = useState([]);
  const [individualData, setIndividualData] = useState([]);
  const [open, setOpen] = useState(false);
  const [openAssignToStudent, setOpenAssignToStudent] = useState(false);
  const [openAssignToTeam, setOpenAssignToTeam] = useState(false);
  const [clientData, setClientData] = useState([]);
  const [clientStatusArray, setClientStatusArray] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredAssessments, setFilteredAssessments] = useState([]);
  const [teams, setTeams] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState("none");
  const [selectedTeamId, setSelectedTeamId] = useState("none");
  const [coacheeData, setCoacheeData] = useState([]);
  const [coachData, setCoachData] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [viewAssessment, setViewAssessment] = useState({});
  const [assessmentsData, setAssessmentsData] = useState([]);
  const [alignmentChecked, setAlignmentChecked] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  const navigate = useNavigate();

  const handleOpenAssignToStudentModal = () => {
    setOpenAssignToStudent(true);
  };

  const handleOpenAssignToTeamModal = () => {
    setOpenAssignToTeam(true);
  };
  useEffect(() => {
    console.log("user for subscription", user?.sub);
    const updateSub = resourceClient.models.assessments
      .onUpdate({
        filter: {
          user_id: {
            contains: `${user?.sub}`,
          },
        },
      })
      .subscribe({
        authMode: "userPool",
        next: (data) => {
          console.log("Subscription Update:", data);

          // Check if the required fields are updated
          const updatedAssessment = data.element;
          const requiredFields = [
            "summary",
            "communication_style",
            "communication_tips",
          ];

          if (
            updatedAssessment &&
            requiredFields.every(
              (field) =>
                updatedAssessment[field] !== undefined &&
                updatedAssessment[field] !== null
            )
          ) {
            toast.success("Assessment Analysis ready", {
              duration: 3000,
              position: "top-center",
            });
          }
        },
        error: (error) => console.warn("Subscription Error:", error),
      });

    // Clean up on component unmount
    return () => updateSub.unsubscribe();
  }, [user?.sub]);

  const collapsed = useSelector((state) => state.layout.collapsed);

  const fetchClientData = async () => {
    try {
      const userId = user?.sub;
      let response;
      let members = null;
      let teams = null;
      if (userData?.companyInformation) {
        await checkUserGroupAlignment(
          user?.sub,
          user?.role,
          userData?.companyInformation?.company_id
        );
        switch (user?.role) {
          case "OWNER":
            response = await axios.get(
              `${process.env.REACT_APP_API_ENDPOINT}/zoom/owner-fetch-company-information`,
              {
                params: {
                  userId: userId,
                },
              }
            );
            members = response.data.members;
            teams = response.data.teams;
            break;
          case "SUPERADMINS":
            response = await axios.get(
              `${process.env.REACT_APP_API_ENDPOINT}/zoom/super-admin-fetch-company-members`,
              {
                params: {
                  userId: userId,
                  userGroup: user?.role,
                },
              }
            );
            members = response.data.members;
            teams = response.data.teams;
            break;
          case "ADMINS":
            response = await axios.get(
              `${process.env.REACT_APP_API_ENDPOINT}/zoom/admin-fetch-company-members`,
              {
                params: {
                  userId: userId,
                  userGroup: user?.role,
                },
              }
            );
            members = response.data.members;
            teams = response.data.myTeamsAdmin;
            break;
          case "USERS":
            response = await axios.get(
              `${process.env.REACT_APP_API_ENDPOINT}/zoom/user-fetch-company-members`,
              {
                params: {
                  userId: userId,
                  userGroup: user?.role,
                },
              }
            );
            members = response.data.members;
            teams = response.data.myTeams;
            break;
          default:
            break;
        }
      } else {
        response = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-client-by-coach-test`,
          {
            params: {
              coach_id: user.sub,
            },
          }
        );
      }

      if (members.length === 0) {
        setLoading(false);
        setClientData([]);
        return;
      }

      // Sort the teams alphabetically by team_name before setting the state
      if (teams && teams.length > 0) {
        teams = teams.sort((a, b) => a.team_name.localeCompare(b.team_name));
      }

      setClientData(members);
      setTeams(teams);

      console.log("members: ", members);
      console.log("res teams: ", teams);
    } catch (error) {
      console.log("error in fetching coachees: ", error);
    }
  };

  const fetchAssessmentData = async () => {
    try {
      setLoading(true);
      const userId = user?.sub;
      let assessments = [];

      // Fetch assessments based on the user's role
      if (user?.role === "OWNER" || user?.role === "SUPERADMINS") {
        assessments = await fetchAllMemberAssessments();
      } else {
        assessments = await fetchUserAssessments(userId);
      }

      const coachAssessments = await fetchCoachAssessments(userId);

      // Filter, sort, and merge the assessments
      assessments = mergeAndSortAssessments(
        assessments,
        coachAssessments,
        userId
      );

      // Fetch user names for the assessments
      const userNames = await fetchUserNames(assessments, userId);

      // Attach user names and count of completed assessments to the data
      assessments = attachUserNamesAndCounts(assessments, userNames);

      setAssessmentsData(assessments);

      // Find the latest completed assessment for the current user and set it as viewable
    const latestUserAssessment = assessments.find(
      (assessment) => assessment.user_id === userId && assessment.completed
    );
    if (latestUserAssessment) {
      setViewAssessment(latestUserAssessment);
    }

      console.log("Final combined assessments data: ", assessments);
    } catch (error) {
      console.error("Error fetching assessment data:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch assessments for all members
  const fetchAllMemberAssessments = async () => {
    const userIds = clientData?.map((member) => member.user_id);
    console.log("user ids for assessment fetch: ", userIds)
    const memberAssessmentsPromises = userIds?.map((memberId) =>
      resourceClient.models.assessments.list({
        filter: { user_id: { eq: memberId } },
      })
    );
    const memberAssessmentsArray = await Promise.all(memberAssessmentsPromises);
    console.log("mem assessments array: ", memberAssessmentsArray.flatMap((response) => response.data))
    return memberAssessmentsArray.flatMap((response) => response.data);
  };

  // Fetch assessments where the user is the coachee
  const fetchUserAssessments = async (userId) => {
    const { data } = await resourceClient.models.assessments.list({
      filter: { user_id: { eq: userId } },
    });
    return data;
  };

  // Fetch assessments where the user is the coach
  const fetchCoachAssessments = async (userId) => {
    const { data } = await resourceClient.models.assessments.list({
      filter: { coach_id: { eq: userId } },
    });
    // Filter out assessments where both user_id and coach_id are the current user
    return data;
  };

  // Merge and sort assessments, keeping only the latest one if both user_id and coach_id match
  const mergeAndSortAssessments = (
    coacheeAssessments,
    coachAssessments,
    userId
  ) => {
    // Sort by updatedAt or createdAt
    const compareByDateAndUser = (a, b) => {
      if (a.user_id === userId && b.user_id !== userId) return -1;
      if (b.user_id === userId && a.user_id !== userId) return 1;
      return (
        new Date(b.updatedAt || b.createdAt) -
        new Date(a.updatedAt || a.createdAt)
      );
    };

    const combinedData = [...coacheeAssessments, ...coachAssessments];

    // Reduce to keep only the latest assessment where both user_id and coach_id match
    const uniqueAssessments = combinedData.reduce((acc, assessment) => {
      // If user_id matches coach_id and it's the same user, only keep the latest one
      if (
        assessment.user_id === assessment.coach_id &&
        assessment.user_id === userId
      ) {
        const existing = acc.find((item) => item.user_id === userId);
        if (
          !existing ||
          new Date(existing.updatedAt || existing.createdAt) <
            new Date(assessment.updatedAt || assessment.createdAt)
        ) {
          acc = acc.filter((item) => item.user_id !== userId);
          acc.push(assessment);
        }
      } else {
        acc.push(assessment);
      }
      return acc;
    }, []);

    return uniqueAssessments.sort(compareByDateAndUser);
  };

  // Fetch user names for the assessments
  const fetchUserNames = async (assessments, userId) => {
    const uniqueUserIds = [
      ...new Set([...assessments.map((a) => a.user_id), userId]),
    ];
    const { data: userNames } = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-name-by-user-id`,
      {
        userIds: uniqueUserIds,
      }
    );
    return userNames.reduce((acc, user) => {
      acc[user.user_id] = user.name;
      return acc;
    }, {});
  };

  // Attach user names and count of completed assessments to each assessment
  const attachUserNamesAndCounts = (assessments, userNamesMap) => {
    // Count the number of completed assessments for each user
    const assessmentsCountMap = assessments.reduce((acc, assessment) => {
      if (assessment.completed) {
        acc[assessment.user_id] = (acc[assessment.user_id] || 0) + 1;
      }
      return acc;
    }, {});

    // Attach the user names and completed assessment count to each assessment
    return assessments.map((assessment) => ({
      ...assessment,
      user_name: userNamesMap[assessment.user_id] || "Unknown Coachee",
      total_completed_assessments_count:
        assessmentsCountMap[assessment.user_id] || 0,
    }));
  };

// Create options for the user selection dropdown based on filtered assessments data
const assessmentDataOptions = [
  { key: "Name", value: "none" }, // Default option
  ...assessmentsData
    ?.map((assessment) => ({
      key: assessment.user_name || "Unknown", // Display name
      value: assessment.user_id, // Use user_id as the value
    }))
    .filter(
      (item, index, self) =>
        self.findIndex((t) => t.value === item.value) === index // Ensure uniqueness by user_id
    )
    .sort((a, b) => a.key.localeCompare(b.key)), // Sort alphabetically by user_name (key)
];


  let teamFilterOptions;
  // Build the team options for the team filter
  teamFilterOptions = [
    { key: "Team", value: "none" }, // Default option
    ...teams?.map((team) => ({
      key: team.team_name,
      value: team.team_id,
    })),
  ];

  useEffect(() => {
    let filteredData = assessmentsData;

    // Filter by team if a team is selected
    if (selectedTeamId !== "none" && selectedTeamId !== undefined) {
      const selectedTeam = teams.find(
        (team) => team.team_id === selectedTeamId
      );
      const teamMemberUserIds = selectedTeam
        ? selectedTeam.members.map((member) => member.user_id)
        : [];
      filteredData = filteredData.filter((assessment) =>
        teamMemberUserIds.includes(assessment.user_id)
      );
    }

    // Filter by user if a user is selected
    if (selectedUserId !== "none" && selectedUserId !== undefined) {
      filteredData = filteredData.filter(
        (assessment) => assessment.user_id === selectedUserId
      );
    }

    setFilteredAssessments(filteredData); // Ensure filteredData is being set to filteredAssessments
    console.log("Filtered assessments: ", filteredData);
  }, [selectedTeamId, selectedUserId, assessmentsData, teams]);

  // Update this useEffect to check for data loading status
  useEffect(() => {
    const fetchAllData = async () => {
      setLoading(true);
      await fetchClientData();
      if(!userData?.companyInformation){
        setLoading(false)
      }
    };

    if (user) {
      fetchAllData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  // UseEffect for fetching assessment data
  useEffect(() => {
    const fetchAndSetAssessmentData = async () => {
        await fetchAssessmentData();
      setLoading(false); 
    };

    fetchAndSetAssessmentData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientData]);

  return (
    <GlobalPageComponent user={user} userData={userData}>
      <Frigade.Tour
        flowId="flow_USgvDBYB"
        defaultOpen={true}
        sequential={true}
        dismissible={true}
        css={{
          ".fr-card": {
            backgroundColor: "#EFF3F6",
          },
          ".fr-button-primary": {
            backgroundColor: "white",
            color: "black",
            borderColor: "black",
            borderRadius: "50px",
            borderWidth: "1px",
            borderStyle: "solid",
            transition: "background-color 0.3s, color 0.3s",
            "&:hover": {
              backgroundColor: "black",
              color: "white",
            },
          },
        }}
      />
      <ClientLimitReached user={user} userData={userData} />
      <AssignAssessmentModal
        user={user}
        userData={userData}
        open={openAssignToStudent}
        clientData={clientData}
        assessment={selectedAssessment}
        fetchData={fetchAssessmentData}
        setOpenAssignToStudent={setOpenAssignToStudent}
      />
      <AssignAssessmentToTeamModal
        user={user}
        userData={userData}
        open={openAssignToTeam}
        assessment={selectedAssessment}
        fetchData={fetchAssessmentData}
        setOpenAssignToTeam={setOpenAssignToTeam}
        teams={teams}
      />
      <Box
        sx={{
          backgroundColor: "#EFF3F6",
          width: "100%",
          height: "100%",
          padding: "16px",
          display: {
            xs: !collapsed ? "none" : "block",
          },
          overflowY: "auto",
        }}
      >
        {/* <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}
                >
                    <Typography
                        sx={{
                            color: "black",
                            fontFamily: "Poppins",
                            fontSize: "36px",
                            fontWeight: 300,
                            marginBottom: "16px",
                            }}
                        >
                            Assessments
                        </Typography>
                    </Box> */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            height: "100%",
          }}
        >
          <Box
            sx={{
              background: "white",
              borderRadius: "40px",
              padding: "24px",
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              width: "100%",
              alignItems: "center",
              gap: { xs: "16px", md: "0px" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                width: { xs: "90%", md: "40%" },
              }}
            >
              <span id="hint-anchor-2">
                <Typography
                  sx={{
                    color: "#8F8F8F",
                    fontSize: "18px",
                    fontFamily: "Lexend",
                    fontWeight: 400,
                  }}
                >
                  The assessments page is your portal into managing the teams
                  and the individuals that you coach. You can assign tests,
                  check the scores, and even compare results!
                </Typography>
              </span>
            </Box>

            <Divider
              orientation="vertical"
              flexItem
              color="#EFF3F6"
              sx={{ marginLeft: "30px" }}
            />

            <Box
              sx={{
                width: { xs: "100%", md: "40%" },
                display: "flex", // Use flexbox for alignment
                justifyContent: "center", // Center horizontally
                alignItems: "center", // Center vertically
              }}
            >
              <Box
                sx={{
                  display: "flex", // Flexbox to center content
                  justifyContent: "center", // Horizontal centering
                  alignItems: "center", // Vertical centering
                  height: "75px", // Set a height to center content vertically (optional, can adjust)
                  width: "70%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: "18px",
                      fontFamily: "Lexend",
                      fontWeight: 400,
                      textTransform: "none",
                    }}
                  >
                    {selectedAssessment
                      ? selectedAssessment === "The Big Five Personality Test"
                        ? "The OCEAN Personality Assessment"
                        : "Assessment"
                      : ""
                    }
                  </Typography>
                  {/* <FormControl sx={{ width: 150 }}>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={selectedAssessment ?? ""}
                                            onChange={handleChange}
                                            open={open}              // Controls the open state of Select
                                            onOpen={handleOpen}       // Set open to true
                                            onClose={handleClose}     // Set open to false
                                            displayEmpty
                                            autoWidth
                                            renderValue={(selected) => {
                                                return selected ? "Change" : "Select";
                                            }}
                                            inputProps={{
                                                MenuProps: {
                                                    MenuListProps: {
                                                        sx: {
                                                            backgroundColor: 'white',
                                                            color: "black",
                                                        }
                                                    }
                                                }
                                            }}
                                            IconComponent={(props) => (
                                                <Box
                                                    onClick={open ? handleClose : handleOpen}  // Handle open/close on icon click
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    {open ? <KeyboardArrowUpIcon sx={{ color: "black" }} /> : <KeyboardArrowDownIcon sx={{ color: "black" }} />}
                                                </Box>
                                            )}
                                            sx={{
                                                color: "black", // Text color
                                                height: "32px", // Adjust height here
                                                padding: "0 6px", // Adjust padding
                                                fontSize: "14px", // Optional: Adjust font size to match height
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "black", // Border color
                                                    borderRadius: "40px", // Custom border radius
                                                },
                                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "black", // Hover border color
                                                },
                                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "black", // Focused border color
                                                },
                                            }}
                                        >
                                            <MenuItem value="The Big Five Personality Test">The Big Five Personality Test</MenuItem>
                                        </Select>
                                    </FormControl> */}
                </Box>
              </Box>
            </Box>
            <Divider
              orientation="vertical"
              flexItem
              color="#EFF3F6"
              sx={{ marginLeft: "0px" }}
            />

            <Box
              sx={{
                width: { xs: "100%", md: "40%" },
                display: "flex",
                flexDirection: "column",
                gap: { xs: "12px", md: "8px" },
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                  width: "60%",
                }}
              >
                <Button
                  sx={{
                    background: "white",
                    border: "1px solid black",
                    padding: "10px",
                    color: "black",
                    borderRadius: "50px",
                    width: "60%",
                    "&:disabled": {
                      background: "#d3d3d3",
                      color: "#B7B7B7",
                      border: "none",
                      cursor: "not-allowed",
                    },
                    transition: "all 0.3s",
                      "&:hover": {
                        cursor: "pointer",
                        background: "black",
                        borderColor: "black",
                      },
                      "&:hover *": {
                        color: "white",
                      },
                      "&:hover svg *": {
                        stroke: "white",
                      },
                  }}
                  disabled={selectedAssessment === "" || !selectedAssessment}
                  onClick={() =>
                    navigate(
                      `/platform/coach/assessments/${selectedAssessment}/${user.sub}/-1/${user.sub}`
                    )
                  }
                >
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      fontWeight: "500",
                      textTransform: "capitalize",
                      letterSpacing: "0.05em",
                      lineHeight: "18px",
                    }}
                    data-cy="take-test-button"
                  >
                    <span id="hint-anchor-3">Take Test</span>
                  </Typography>
                </Button>
                <Button
                  sx={{
                    background: "white",
                    border: "1px solid black",
                    padding: "10px",
                    color: "black",
                    borderRadius: "50px",
                    width: "60%",
                    "&:disabled": {
                      background: "#d3d3d3",
                      color: "#B7B7B7",
                      border: "none",
                      cursor: "not-allowed",
                    },
                    transition: "all 0.3s",
                      "&:hover": {
                        cursor: "pointer",
                        background: "black",
                        borderColor: "black",
                      },
                      "&:hover *": {
                        color: "white",
                      },
                      "&:hover svg *": {
                        stroke: "white",
                      },
                  }}
                  disabled={
                    !selectedAssessment ||
                    !user?.sub ||
                    filteredAssessments.length === 0 // Use combined filtered data
                  }
                  onClick={() => {
                    setSelectedUserId(user.sub);

                    const firstCompletedAssessment = filteredAssessments.find(
                      (assessment) => assessment.completed
                    );

                    if (firstCompletedAssessment) {
                      setViewAssessment(firstCompletedAssessment);
                    }
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      fontWeight: "500",
                      textTransform: "capitalize",
                      letterSpacing: "0.05em",
                      lineHeight: "18px",
                    }}
                  >
                    <span id="hint-anchor-4">My Results</span>
                  </Typography>
                </Button>
              </Box>
              {(user?.role === "OWNER" ||
                user?.role === "SUPERADMINS" ||
                user?.role === "ADMINS") &&
                userData?.accessTo === 'platform' && (
                <Button
                  sx={{
                    background:
                      "linear-gradient(315deg, #0076BA 0%, #03093A 60%, #242F8C 100%)",
                    padding: "10px",
                    borderRadius: "50px",
                    width: "60%",
                    "&:disabled": {
                      background: "#d3d3d3",
                      color: "#B7B7B7",
                      border: "none",
                      cursor: "not-allowed",
                    },
                  }}
                  disabled={
                    user?.role === "SUPERADMINS"
                      ? userData?.teamsInformation?.length === 0
                      : userData?.myTeamsInformation?.members?.length === 0
                  }
                  onClick={() => {
                    handleOpenAssignToTeamModal();
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      fontWeight: "500",
                      color: "white",
                      textTransform: "none",
                      letterSpacing: "0.05em",
                      lineHeight: "18px",
                    }}
                  >
                    {user?.role === "SUPERADMINS" || user?.role === "OWNER"
                      ? "Assign to a Team"
                      : "Assign to my Team"}
                  </Typography>
                </Button>
              )}
              {userData?.companyInformation && user?.role !== "PRIVATE" && userData?.accessTo === 'platform' && (
                <Button
                  sx={{
                    background: "white",
                    border: "1px solid black",
                    color: "black",
                    padding: "10px",
                    borderRadius: "50px",
                    width: "60%",
                    "&:disabled": {
                      background: "#d3d3d3",
                      color: "#B7B7B7",
                      border: "none",
                      cursor: "not-allowed",
                    },
                    transition: "all 0.3s",
                      "&:hover": {
                        cursor: "pointer",
                        background: "black",
                        borderColor: "black",
                      },
                      "&:hover *": {
                        color: "white",
                      },
                      "&:hover svg *": {
                        stroke: "white",
                      },
                  }}
                  disabled={selectedAssessment === "" || !selectedAssessment}
                  onClick={handleOpenAssignToStudentModal}
                >
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      fontWeight: "500",
                      textTransform: "none",
                      letterSpacing: "0.05em",
                      lineHeight: "18px",
                    }}
                  >
                    Assign to a Member
                  </Typography>
                </Button>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: "8px",
              width: "100%",
              height: "100%",
              flexGrow: 1,
            }}
          >
            <Box
              sx={{
                background: "white",
                display: "flex",
                flexDirection: "column",
                width: { xs: "100%", md: "50%" },
                borderRadius: "40px",
                padding: "24px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    color: "black",
                    fontSize: "28px",
                    fontFamily: "Poppins",
                    fontWeight: 300,
                    wordWrap: "break-word",
                  }}
                >
                  <span id="hint-anchor-1">Individuals</span>
                </Typography>
                {/* INSERT FILTER HERE */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  {userData?.companyInformation && user?.role !== "PRIVATE" && (
                    <_TeamAssessmentsFilter
                      value={selectedTeamId}
                      handleChange={setSelectedTeamId}
                      items={teamFilterOptions}
                    />
                  )}

                  <_IndividualAssessmentsFilter
                    value={selectedUserId}
                    handleChange={setSelectedUserId} // Update selectedUserId state when filter changes
                    items={assessmentDataOptions} // Use combined data options for filtering
                  />
                </Box>
              </Box>
              {
                loading ? (
                  <Box
                    sx={{
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : filteredAssessments.length > 0 ? (
                  <Box
                    sx={{
                      height: "100%",
                      flexGrow: 1, // Ensures the table grows and fills available height
                      overflow: "hidden", // Prevents content overflow outside the box
                    }}
                  >
                    <AssessmentsTable
                      user={user}
                      individualData={filteredAssessments}
                      loading={loading}
                      setViewAssessment={setViewAssessment}
                      viewAssessment={viewAssessment}
                    />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "black",
                        fontSize: "18px",
                        fontFamily: "Poppins",
                        fontWeight: 300,
                        width: "60%",
                      }}
                    >
                      Please assign the assessment to a coachee using the button on the top section.
                    </Typography>
                  </Box>
                )
              }
            </Box>
            <Box
              sx={{
                background: "white",
                display: "flex",
                flexDirection: "column",
                width: { xs: "100%", md: "50%" },
                borderRadius: "40px",
                padding: "24px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    color: "black",
                    fontSize: "28px",
                    fontFamily: "Poppins",
                    fontWeight: 300,
                    wordWrap: "break-word",
                  }}
                >
                  Results
                </Typography>
                {viewAssessment && viewAssessment.id && (
                  <Button
                    sx={{
                      background: "white",
                      border: "1px solid black",
                      color: "black",
                      padding: "10px",
                      borderRadius: "50px",
                      "&:disabled": {
                        background: "#d3d3d3",
                        color: "#B7B7B7",
                        border: "none",
                        cursor: "not-allowed",
                      },
                      transition: "all 0.3s",
                      "&:hover": {
                        cursor: "pointer",
                        background: "black",
                        borderColor: "black",
                      },
                      "&:hover *": {
                        color: "white",
                      },
                      "&:hover svg *": {
                        stroke: "white",
                      },
                    }}
                    onClick={() =>
                      navigate(
                        `/platform/coach/assessments/view/individual-breakdown/${viewAssessment.id}/${viewAssessment.user_id}`
                      )
                    }
                  >
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "12px",
                        fontWeight: "500",
                        textTransform: "none",
                        letterSpacing: "0.05em",
                        lineHeight: "18px",
                      }}
                      data-cy="expand-score-button"
                    >
                      <span id="hint-anchor-5">Expand Score</span>
                    </Typography>
                  </Button>
                )}
              </Box>
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  marginTop: "50px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                    overflow: "auto",
                  }}
                >
                  <GeneralBigFiveScore assessmentData={viewAssessment} />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    alignItems: "center",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: 18,
                      fontFamily: "Lexend",
                      fontWeight: 400,
                      width: "60%",
                      maxHeight: "230px",
                      overflowY: "auto",
                    }}
                  >
                    {viewAssessment && viewAssessment.id
                      ? ""
                      : "The chart above will showcase your score’s overview, after you complete a test or selecting one from the list."}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </GlobalPageComponent>
  );
};

export default CoachAssessments;

const tabStyle = {
  color: "black",
  fontSize: 12,
  fontWeight: 700,
  letterSpacing: 1.5,
  fontFamily: "Lexend",
  borderBottom: "1px solid #E8E8E8",
  "&.Mui-selected": {
    borderBottom: "1px solid #000",
  },
};
