import React from 'react';

function AlterCallLogoIcon({ color = '#111111' }) {
  return (
    <svg width="200" height="32" viewBox="0 0 200 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M50.6315 18.6094L43.7742 18.6094L42.2972 21.862L40.9434 21.862L46.5699 9.68652L47.8534 9.68652L53.4799 21.862L52.1085 21.862L50.6315 18.6094ZM50.1568 17.5658L47.2029 11.0258L44.2489 17.5658L50.1568 17.5658Z" fill={color}/>
      <path d="M59.0361 9.68652L60.3373 9.68652L60.3373 20.7488L67.2297 20.7488L67.2297 21.862L59.0361 21.862L59.0361 9.68652Z" fill={color}/>
      <path d="M74.1579 10.7997L69.8325 10.7997L69.8325 9.68652L79.7844 9.68652L79.7844 10.7997L75.459 10.7997L75.459 21.862L74.1579 21.862L74.1579 10.7997Z" fill={color}/>
      <path d="M94.079 20.7488L94.079 21.862L85.3755 21.862L85.3755 9.68652L93.8153 9.68652L93.8153 10.7997L86.6766 10.7997L86.6766 15.1307L93.0416 15.1307L93.0416 16.2265L86.6766 16.2265L86.6766 20.7488L94.079 20.7488Z" fill={color}/>
      <path d="M109.059 21.862L106.246 17.9484C105.929 17.9832 105.613 18.0006 105.261 18.0006L101.955 18.0006L101.955 21.862L100.654 21.862L100.654 9.68652L105.261 9.68652C108.391 9.68652 110.29 11.2519 110.29 13.861C110.29 15.7742 109.27 17.1135 107.476 17.6875L110.483 21.862L109.059 21.862ZM108.989 13.861C108.989 11.9129 107.687 10.7997 105.226 10.7997L101.955 10.7997L101.955 16.9048L105.226 16.9048C107.687 16.9048 108.989 15.7742 108.989 13.861Z" fill={color}/>
      <path d="M115.793 15.7741C115.793 12.2084 118.536 9.58203 122.246 9.58203C124.04 9.58203 125.657 10.1734 126.748 11.3562L125.921 12.1737C124.919 11.1648 123.688 10.73 122.282 10.73C119.31 10.73 117.077 12.8868 117.077 15.7741C117.077 18.6614 119.31 20.8182 122.282 20.8182C123.688 20.8182 124.919 20.3834 125.921 19.3572L126.748 20.1747C125.657 21.3574 124.04 21.9662 122.229 21.9662C118.536 21.9662 115.793 19.3398 115.793 15.7741Z" fill={color}/>
      <path d="M140.445 18.6094L133.587 18.6094L132.11 21.862L130.756 21.862L136.383 9.68652L137.666 9.68652L143.293 21.862L141.921 21.862L140.445 18.6094ZM139.97 17.5658L137.016 11.0258L134.062 17.5658L139.97 17.5658Z" fill={color}/>
      <path d="M148.849 9.68652L150.15 9.68652L150.15 20.7488L157.043 20.7488L157.043 21.862L148.849 21.862L148.849 9.68652Z" fill={color}/>
      <path d="M162.721 9.68652L164.022 9.68652L164.022 20.7488L170.915 20.7488L170.915 21.862L162.721 21.862L162.721 9.68652Z" fill={color}/>
      <path d="M24.1577 21.4497C25.8714 24.4147 27.5861 27.3822 29.3172 30.3775C28.8173 30.6603 28.3322 30.9352 27.8176 31.2264C26.1255 28.3001 24.4399 25.3849 22.7664 22.49C17.3854 25.5971 12.0436 25.5971 6.65424 22.4905C4.98443 25.3781 3.29824 28.2949 1.60359 31.2258C1.09736 30.9394 0.611208 30.6644 0.103395 30.3775C1.83028 27.389 3.54501 24.422 5.26397 21.4482C1.48997 18.133 -0.32357 14.005 0.047382 9.01554C0.303138 5.57806 2.01892 2.02088 4.27582 0C4.66738 0.404594 5.05947 0.80971 5.45103 1.21483C-0.100574 6.62195 0.822049 15.5021 6.16228 19.8946C9.00414 14.9783 11.8434 10.0657 14.7106 5.10551C17.5746 10.0605 20.4138 14.9726 23.2541 19.8868C28.4036 15.7175 29.6686 6.93037 23.9247 1.17928C24.3263 0.793505 24.7284 0.40773 25.1316 0.021432C30.7101 5.03599 31.4214 15.7107 24.1572 21.4497L24.1577 21.4497ZM7.54411 20.9505C12.0944 24.0169 18.1252 23.5903 21.8675 20.9338C19.4912 16.822 17.1138 12.7092 14.7106 8.55135C12.3057 12.7118 9.92835 16.8251 7.54411 20.95L7.54411 20.9505Z" fill={color}/>
      <mask id="mask0_8196_21925" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="179" y="9" width="21" height="14">
        <path d="M190.173 22.0167L189.094 19.4164L183.438 19.4164L182.359 22.0167L179.47 22.0167L184.9 9.88184L187.685 9.88184L193.132 22.0167L190.173 22.0167ZM184.343 17.2841L188.207 17.2841L186.275 12.6382L184.343 17.2841Z" fill={color}/>
        <path d="M197.181 22.0167L197.181 9.88184L200 9.88184L200 22.0167L197.181 22.0167Z" fill={color}/>
      </mask>
      <g mask="url(#mask0_8196_21925)">
        <rect x="138.864" y="-15.6641" width="71.5803" height="47.1542" fill={color}/>
        <rect x="138.864" y="-15.6641" width="71.5803" height="47.1542" stroke="#F3F3F3"/>
      </g>
    </svg>
  );
}

export default AlterCallLogoIcon;
