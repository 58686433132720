import React, { useState, useEffect } from "react";
import { Box, Button, Typography, Tooltip } from "@mui/material";
import axios from "axios";
import UsersTable from "./Table/UsersTable";
import AdminAddMemberModal from "./AddMembers/AdminAddMemberModal";
import ToolBar from "./ToolBar/ToolBar";
import AddPrivateUserModal from "./AddMembers/AddPrivateUserModal";
import { generateClient } from "aws-amplify/data";
import { toast } from "react-toastify";
import { checkUserGroupAlignment } from "helpers/userCognitoGroupCheck";
import * as Frigade from "@frigade/react";
import AdminPortalActionIcon from "../Icons/AdminPortalAction.svg";
import ActionOnUserModal from "./UserActions/ActionOnUserModal";

// const resourceClient = generateClient({authMode: 'userPool'});
/**
 * @type {import('aws-amplify/data').Client<import('../amplify/data/resource').Schema>}
 */
const resourceClient = generateClient({ authMode: "userPool" });

const User = ({ user, userData }) => {
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false); // For loading state
  const [companyMembers, setCompanyMembers] = useState([]); // Store company members here
  const [companyInformation, setCompanyInformation] = useState([]);
  const [teamsInformation, setTeamsInformation] = useState([]);
  const [myTeamInformation, setMyTeamInformation] = useState([]);
  const [myPrivateUsers, setMyPrivateUsers] = useState([]);
  const [openAddMemberModal, setOpenAddMemberModal] = useState(false); // Modal state
  const [openAddPrivateUserModal, setOpenAddPrivateUserModal] = useState(false);
  const [availableSeats, setAvailableSeats] = useState("");
  const [selectedUser, setSelectedUser] = useState(false)
  const [openActionOnUserModal, setOpenActionOnUserModal] = useState(false)

  console.log("user data: ", user);

  // Function to fetch company members
  const fetchCompanyData = async () => {
    try {
      setLoading(true);

      checkUserGroupAlignment(
        user?.sub,
        user?.role,
        userData?.companyInformation?.company_id
      );

      const { data: response } = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/user-fetch-company-members`,
        {
          params: {
            userId: user?.sub,
            userGroup: user?.role,
          },
        }
      );
      setCompanyMembers(response.members);
      setCompanyInformation(response.companyInformation);
      setTeamsInformation(response.teams);
      setMyTeamInformation(response.myTeam);
      setMyPrivateUsers(response.myPrivateUsers)
      console.log("res: ", response)
      console.log("my priv users: ", response.myPrivateUsers)

      const { data: responseSeats, errors } =
        await resourceClient.queries.checkCoacheeInviteAllowance({
          userID: response?.companyInformation?.owner_user_id,
        });

      console.log("seat info: ", responseSeats, errors);

      setAvailableSeats(
        responseSeats.coachee_seat_tier_allowance -
          responseSeats.coachee_seat_count
      );

      console.log("response from fetch company information: ", response);
      setLoading(false);
    } catch (error) {
      console.log("Error fetching company members: ", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCompanyData();
  }, []);

  const columns = [
    {
      field: "full_name",
      headerName: "Full Name",
      flex: 0.7,
      renderCell: (params) => (
        <Tooltip title={params.value || ""}>
          <Typography
            sx={{
              color: "#1B114A",
              fontSize: 14,
              fontFamily: "Lexend",
              fontWeight: "300",
              wordWrap: "break-word",
              textTransform: "capitalize"
            }}
          >
            {params.value}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      renderCell: (params) => (
        <Typography
        sx={{ color: '#93989A', fontSize: 14, fontFamily: 'Lexend', fontWeight: '400', wordWrap: 'break-word' }}
        >
          {params.value}
        </Typography>
      )
    },
    {
      field: "team_name",
      headerName: "Team",
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={Array.isArray(params.value)
          ? params.value.join(", ")
          : params.value || "No Team"}>
          <Typography
            sx={{
              color: "black",
              fontSize: 14,
              fontFamily: "Lexend",
              fontWeight: "400",
              wordWrap: "break-word",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              textTransform: "capitalize"
            }}
          >
            {Array.isArray(params.value)
              ? params.value.join(", ")
              : params.value || "No Team"}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: "title",
      headerName: "Title",
      flex: 0.6,
      renderCell: (params) => (
        <Tooltip title={params.value || ""}>
          <Typography
            sx={{
              color: "#93989A",
              fontSize: 14,
              fontFamily: "Lexend",
              fontWeight: "400",
              wordWrap: "break-word",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              textTransform: "capitalize"
            }}
            title={params.value} // Tooltip for overflowed content
          >
            {params.value || ""}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: "invite_status",
      headerName: "Status",
      flex: 0.5,
      renderCell: (params) => (
        <Tooltip title={params.value || ""}>
          <Typography
            sx={{
              color: params.value === "accepted" ? "#00F218" : "orange",
              fontSize: 14,
              fontFamily: "Lexend",
              fontWeight: "400",
              wordWrap: "break-word",
              textTransform: "capitalize"
            }}
          >
            {params.value}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: "team_leader",
      headerName: "Team Leader",
      flex: 1,
      renderCell: (params) => {
        // Ensure params.value is defined and is an array
        const teamLeaders = Array.isArray(params.value) ? params.value : [];

        // Join the team leader names with a comma, or use a placeholder text
        const leaderNames =
          teamLeaders.length > 0 ? teamLeaders.join(", ") : "No Team Leader";

        return (
          <Tooltip title={leaderNames || ""}>
            <Typography
              sx={{
                color: "#1B114A",
                fontSize: 14,
                fontFamily: "Lexend",
                fontWeight: "300",
                wordWrap: "break-word",
                textTransform: "capitalize"
              }}
            >
              {leaderNames}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.5,
      renderCell: (params) => {
        // Compare the row's user_id with the current user's id
        if (
          params.row.user_id &&
          (params.row.user_id === companyInformation?.owner_user_id &&
          user?.sub !== companyInformation?.owner_user_id) ||
          !myPrivateUsers.includes(params.row.user_id)
        ) {
          return null; // Hide the action button if user_id matches the current user's id
        }

        return (
          <Box sx={{ display: "flex", gap: "8px" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "4px",
                alignItems: "center",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              onClick={() => handleActionOnUser(params.row)}
            >
              <AdminPortalActionIcon />
              <Typography
                sx={{
                  color: "#93989A",
                  fontSize: 14,
                  fontFamily: "Lexend",
                  fontWeight: "400",
                  wordWrap: "break-word",
                }}
              >
                Actions
              </Typography>
            </Box>
          </Box>
        );
      },
    },
  ];

  const rows = companyMembers.map((member, index) => {
    const teams = Array.isArray(member.teams)
      ? member.teams.map((team) => team.team_name || "").join(", ")
      : "No Team"; // Default to 'No Team' if teams array is not present or empty

    // Create a Set to keep track of unique admin IDs to avoid duplicates
    const uniqueAdminNames = new Set();

    // Iterate over each team to collect the admin names
    member.teams.forEach((team) => {
      if (team.team_admins) {
        team.team_admins.forEach((admin) => {
          uniqueAdminNames.add(admin.full_name);
        });
      }
    });
    // Convert the Set to an array to list all unique admin names
    const adminFullNames = Array.from(uniqueAdminNames);

    console.log("admin full names: ", adminFullNames);

    return {
      id: index,
      user_id: member.user_id || "",
      full_name: member.full_name || "",
      email: member.email || "",
      team_name: teams,
      title: member.company_role_name || "No Title",
      invite_status: member.invite_status || "",
      team_leader: adminFullNames,
      role: member.role
    };
  });

  const handleActionOnUser = (member) => {
    setSelectedUser(member);
    handleOpenActionOnUserModal();
  };

  const handleopenAddPrivateUserModal = () => setOpenAddPrivateUserModal(true);
  const handleOpenActionOnUserModal = () => setOpenActionOnUserModal(true);

  return (
    <Box
      sx={{
        background: "white",
        borderRadius: "30px",
        paddingX: { xs: "30px", md: "50px" },
        paddingY: { xs: "30px", md: "30px" },
        height: "100%",
        overflow: "hidden",
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      <Frigade.Tour
        flowId="flow_6Qn3uhRC"
        defaultOpen={true}
        sequential={true}
        dismissible={true}
        css={{
          ".fr-card": {
            backgroundColor: "#EFF3F6",
          },
          ".fr-button-primary": {
            backgroundColor: "white",
            color: "black",
            borderColor: "black",
            borderRadius: "50px",
            borderWidth: "1px",
            borderStyle: "solid",
            transition: "background-color 0.3s, color 0.3s",
            "&:hover": {
              backgroundColor: "black",
              color: "white",
            },
          },
        }}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "30px",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              color: "black",
              fontSize: 28,
              fontFamily: "Montserrat",
              fontWeight: "400",
              wordWrap: "break-word",
            }}
          >
            <span id="hint-anchor-1">Teams & Members</span>
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: "15px",
            }}
          >
            <Typography
              sx={{
                color: "black",
                fontSize: 12,
                fontFamily: "Poppins",
                fontWeight: "500",
                textTransform: "capitalize",
                letterSpacing: "0.05em",
                lineHeight: "18px",
                wordWrap: "break-word",
                borderRadius: 50,
                padding: "12px",
                background: "#F1F1F1",
              }}
            >
              {`${availableSeats} seats are open`}
            </Typography>
            <Button
              onClick={handleopenAddPrivateUserModal}
              sx={{
                color: "black",
                fontSize: 12,
                fontFamily: "Poppins",
                fontWeight: "500",
                textTransform: "capitalize",
                letterSpacing: "0.05em",
                lineHeight: "18px",
                wordWrap: "break-word",
                borderRadius: 50,
                border: "2px black solid",
                transition: "all 0.3s",
                "&:hover": {
                  cursor: "pointer",
                  background: "black",
                  borderColor: "black",
                  color: "white",
                },
                "&:hover *": {
                  color: "white",
                },
                "&:hover svg *": {
                  stroke: "white",
                },
              }}
            >
              <span id="hint-anchor-5">Add Private Users</span>
            </Button>
          </Box>
        </Box>
        {/* Styled DataGrid Component */}
        <Box
          sx={{
            flexGrow: 1,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          <ToolBar
            user={user}
            selected={selected}
            fetchCompanyData={fetchCompanyData}
          />
          <UsersTable
            rows={rows}
            columns={columns}
            pageSize={25}
            rowsPerPageOptions={[10, 20, 30]}
            checkboxSelection={false}
            loading={loading}
            onSelectionModelChange={(newSelection) => setSelected(newSelection)}
            disableColumnFilter
            disableSelectionOnClick
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
            localeText={{
              noRowsLabel: "No data available",
            }}
            sx={{
              "& .MuiDataGrid-columnHeaderTitleContainerContent, .MuiDataGrid-cellCheckbox":
                {
                  span: {
                    display: "flex",
                    color: "#E4E6F2",
                    "&:hover": {
                      color: "#B6B9CE",
                      backgroundColor: "transparent",
                    },
                    "&.Mui-checked": {
                      color: "black",
                      backgroundColor: "transparent",
                    },
                  },
                },
            }}
          />
        </Box>
      </Box>
      <AddPrivateUserModal
        user={user}
        userData={userData}
        openAddPrivateUserModal={openAddPrivateUserModal}
        setOpenAddPrivateUserModal={setOpenAddPrivateUserModal}
        companyInformation={companyInformation}
        teamsInformation={teamsInformation}
        companyMembers={companyMembers}
        fetchCompanyData={fetchCompanyData}
      />
      <ActionOnUserModal
        user={user}
        userData={userData}
        openActionOnUserModal={openActionOnUserModal}
        setOpenActionOnUserModal={setOpenActionOnUserModal}
        companyMembers={companyMembers}
        companyInformation={companyInformation}
        teamsInformation={teamsInformation}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        myPrivateUsers={myPrivateUsers}
        fetchCompanyData={fetchCompanyData}
      />
    </Box>
  );
};

export default User;
