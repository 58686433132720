import { Box, Typography } from "@mui/material";
import {
    _ActionListFilterSelect,
    _ActionListFilterDateRange,
  } from "components/ActionListFilter";

const FilterComponents = ({ user, userData, filterClient, filterClientItems, setFilterClient, filterCoach, filterCoachItems, setFilterCoach, filterStartDate, setFilterStartDate, filterEndDate, setFilterEndDate, handleResetFilters }) => {
    return(
        <>
                <Box
                  sx={{
                    display: "flex",
                    gap: "8px",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: "8px",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      width: "100%",
                      '& > :nth-of-type(n)': {
                        flexBasis: "50%",
                      },
                    }}
                  >
                    {(userData.accessTo === "platform") && (
                      <_ActionListFilterSelect
                        value={filterClient}
                        items={filterClientItems}
                        handleChange={(e) => setFilterClient(e)}
                      />
                    )}
                    {user?.["custom:is_coachee"] === "true" && (
                        <_ActionListFilterSelect
                        value={filterCoach}
                        items={filterCoachItems}
                        handleChange={(e) => setFilterCoach(e)}
                      />
                    )}
                    <_ActionListFilterDateRange
                      startDate={filterStartDate}
                      endDate={filterEndDate}
                      handleStartDateChange={(date) => {
                        setFilterStartDate(date);
                      }}
                      handleEndDateChange={(date) => {
                        setFilterEndDate(date);
                      }}
                    />
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        maxWidth: "20%",
                        color: "#FF5E00",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        borderRadius: "45px",
                        border: "1px solid black",
                        paddingY: "4px",
                        paddingX: "20px",
                        justifyContent: "center",
                        textAlign: "center",
                        letterSpacing: "-0.32px",
                        "&:hover": {
                        cursor: "pointer",
                        background: "#FF5E00",
                        borderColor: "#FF5E00",
                        color: "white"
                      },
                      "&:hover *": {
                        color: "white",
                      },
                      "&:hover svg *": {
                        stroke: "white",
                      },
                        '& > :nth-of-type(n)': {
                          flexBasis: "50%",
                        },
                      }}
                      onClick={handleResetFilters}
                    >
                      Reset Filters
                    </Typography>
                  </Box>
                </Box>
        </>
    )
}

export default FilterComponents;