import React, { useState, useEffect } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import avatar from "assets/avatar.png";
import { getUrl } from "aws-amplify/storage";
import GlobalPageComponent from "components/GlobalPageComponent/GlobalPageComponent";
import ClientCards from "components/CoachClientCenter/ClientCards";
import ActivityFeed from "components/CoachClientCenter/ActivityFeed";
import AddClientCenter from "components/CoachClientCenter/AddClientCenter";
import PersonalTierUpgrade from "components/CoachClientCenter/PersonalTierUpgrade";
import ClientLimitReached from "components/ClientLimitReachedModal";
import { _LeadershipCenterTeamFilter } from "components/CoachClientCenter/LeadershipCenterTeamFilter";
import axios from "axios";
import { checkUserGroupAlignment } from "helpers/userCognitoGroupCheck";
import * as Frigade from "@frigade/react";

const ClientCenter = ({ user, userData }) => {
  const collapsed = useSelector((state) => state.layout.collapsed);
  const [clientData, setClientData] = useState([]);
  const [clientPicture, setClientPicture] = useState([]);
  const [filterEventItems, setFilterEventItems] = useState([
    { key: "Event Type", value: "none" },
  ]);
  const [filterClientItems, setFilterClientItems] = useState([
    { key: "Member", value: "none" },
  ]);
  const [filterParticipantItems, setFilterParticipantItems] = useState([
    { key: "Participant", value: "none" },
  ]);
  const [teamFilterItems, setTeamFilterItems] = useState([
    { key: "Team", value: "none" },
  ]);
  const [loading, setLoading] = useState(true);
  const [loadingActivityData, setLoadingActivityData] = useState(false);
  const [activityData, setActivityData] = useState([]);
  const [teams, setTeams] = useState([]);
  const [selectedTeamId, setSelectedTeamId] = useState("none");
  const [filteredClientData, setFilteredClientData] = useState([]);

  const isPrivate = user?.role === "PRIVATE";

  const fetchClientData = async () => {
    setLoading(true);
    try {
      const userId = user?.sub;
      const userGroup = user?.role;
      let response = null;

      if (userData?.companyInformation) {
        await checkUserGroupAlignment(
          user?.sub,
          user?.role,
          userData?.companyInformation?.company_id
        );
      }

      if (userData?.companyInformation && !isPrivate) {
        response = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT}/zoom/leadership-center-get-all-company-members`,
          {
            params: {
              user_id: userId,
              user_group: userGroup,
              company_id: userData?.companyInformation?.company_id,
            },
          }
        );
      } else if (userData?.companyInformation && isPrivate) {
        response = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT}/zoom/leadership-center-get-internal-users`,
          {
            params: {
              user_id: userId,
              user_group: userGroup,
              company_id: userData?.companyInformation?.company_id,
            },
          }
        );
      } else {
        response = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-client-by-coach-test`,
          {
            params: {
              coach_id: userId,
            },
          }
        );
      }

      console.log("response", response);

      const { client_data = [], teams = [] } = response.data || {};

      if (client_data.length === 0) {
        setLoading(false);
        setClientData([]);
        return;
      }
      const encodeFilePath = (path) => {
        const fileName = path.split("/").pop();
        return encodeURIComponent(fileName).replace(/%20/g, " ");
      };

      const fetchAvatarUrl = async (avatarPath) => {
        if (!avatarPath) return null;

        try {
          const encodedFileName = encodeFilePath(avatarPath);
          const filePath = `public/avatar/${encodedFileName}`;
          const { url } = await getUrl({
            path: filePath,
            expires: 900,
            validateObjectExistence: true,
          });

          return url ? url.href : null;
        } catch (error) {
          console.error("Error fetching avatar URL:", error);
          return null;
        }
      };

      const processedClientData = await Promise.all(
        client_data
          .filter((row) => row.user_id !== userId)
          .map(async (row) => ({
            ...row,
            avatar: await fetchAvatarUrl(row.avatar),
          }))
      );

      setClientData(processedClientData);
      setFilteredClientData(processedClientData);
      setTeams(teams);

      // Sort teams alphabetically by team_name
      const sortedTeams = [...teams].sort((a, b) =>
      a.team_name.localeCompare(b.team_name)
      );

      // Create sorted team filter options
      const teamFilterOptions = sortedTeams.map((team) => ({
      key: team.team_name,
      value: team.team_id.toString(), // Ensure value is a string
      }));

      // Add default option
      setTeamFilterItems([
        { key: "Team", value: "none" },
        ...teamFilterOptions,
      ]);

      const pictureData = [
        ...processedClientData.map((row) => row.avatar),
      ].filter((avatar) => avatar !== null);

      setClientPicture(pictureData);
    } catch (error) {
      console.error("Error fetching client data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchActivityData = async () => {
    setLoadingActivityData(true);
    try {
      const userId = user?.sub;
      const { data: responseData } = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/activityFeed`,
        {
          params: { coach_id: userId },
        }
      );

      console.log("responseData", responseData);

      if (responseData && responseData.length > 0) {
        // Sort the data by date_added in descending order
        const sortedData = responseData.sort(
          (a, b) => new Date(b.date_added) - new Date(a.date_added)
        );

        // Format the data to handle potential null or undefined values
        const formattedData = sortedData.map((row) => ({
          action: row.action ?? "N/A",
          actionId: row.action_id ?? "N/A",
          actionStatus: row.action_status ?? "N/A",
          avatar: row.avatar ?? "N/A",
          callId: row.call_id ?? "N/A",
          callName: row.call_name ?? "N/A",
          clientId: row.client_id !== "undefined" ? row.client_id : "N/A",
          coachId: row.coach_id ?? "N/A",
          coachName: row.coach_name ?? "N/A",
          dateAdded: row.date_added
            ? new Date(row.date_added).toLocaleString()
            : "N/A",
          eventType: row.event_type?.toString() ?? "N/A",
          id: row.id ?? "N/A",
          participantName: row.participant_name ?? "N/A",
          zoomDate: row.zoom_date
            ? new Date(row.zoom_date).toLocaleString()
            : "N/A",
        }));

        setActivityData(formattedData);

      // Extract unique events
      const uniqueEvents = [
        ...new Set(sortedData.map((row) => row.event_type?.toString()))
      ];

      // Filter out null values, ensure each is a string, sort alphabetically, then map
      const formattedEvents = uniqueEvents
        .filter((event) => event !== null && typeof event === "string")
        .sort() // Sort alphabetically
        .map((event) => ({
          key: event.replace(/_/g, " "), // Replace underscores with spaces
          value: event,
        }));

        console.log("Formatted events:", formattedEvents);

        // Extract unique coachees
        const uniqueCoachees = [
          ...new Set(sortedData.map((row) => row.coachee))
        ];

        // Filter out null values, sort alphabetically, then map
        const formattedCoachees = uniqueCoachees
          .filter((coachee) => coachee !== null)
          .sort() // Sort alphabetically
          .map((coachee) => ({
            key: coachee,
            value: coachee,
          }));

        const uniqueParticipants = [
          ...new Set(sortedData.map((row) => row.participant_name))
        ];
        
        // Filter out "N/A" and sort alphabetically
        const formattedParticipants = uniqueParticipants
          .filter((participant) => participant !== "N/A")
          .sort() // Sort alphabetically
          .map((participant) => ({
            key: participant,
            value: participant,
          }));          

        // Update the filter items
        setFilterEventItems([
          { key: "Event Type", value: "none" },
          ...formattedEvents,
        ]);
        setFilterClientItems([
          { key: "Member", value: "none" },
          ...formattedCoachees,
        ]);
        setFilterParticipantItems([
          { key: "Participant", value: "none" },
          ...formattedParticipants,
        ]);

        // Set the activity data
        setActivityData(sortedData);
      } else {
        setActivityData([]);
      }
    } catch (error) {
      console.error("Error fetching activity data:", error);
    } finally {
      setLoadingActivityData(false);
    }
  };

  // Team filter logic
  useEffect(() => {
    if (selectedTeamId === "none") {
      // If "none" is selected, show all clients
      setFilteredClientData(clientData);
    } else {
      // Find the selected team
      const selectedTeam = teams.find(
        (team) => team.team_id.toString() === selectedTeamId
      );

      if (selectedTeam) {
        const teamMemberUserIds = selectedTeam.members.map(
          (member) => member.user_id
        );

        // Filter clientData to only include clients in the selected team
        const filteredClients = clientData.filter((client) =>
          teamMemberUserIds.includes(client.user_id)
        );

        setFilteredClientData(filteredClients);
      } else {
        // If team not found, show all clients
        setFilteredClientData(clientData);
      }
    }
  }, [selectedTeamId, clientData, teams]);

  useEffect(() => {
    fetchClientData();
    fetchActivityData();
  }, []);

  if (userData.accessTo === "platform_professional")
    return (
      <GlobalPageComponent user={user} userData={userData}>
        <Box
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <PersonalTierUpgrade />
        </Box>
      </GlobalPageComponent>
    );

  return loading ? (
    <GlobalPageComponent user={user} userData={userData}>
      <Box
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        backgroundColor="EFF3F6"
      >
        <CircularProgress />
      </Box>
    </GlobalPageComponent>
  ) : (
    <>
      <GlobalPageComponent user={user} userData={userData}>
        <Frigade.Tour
          flowId="flow_FnM7KnhQ"
          defaultOpen={true}
          sequential={true}
          dismissible={true}
          css={{
            ".fr-card": {
              backgroundColor: "#EFF3F6",
            },
            ".fr-button-primary": {
              backgroundColor: "white",
              color: "black",
              borderColor: "black",
              borderRadius: "50px",
              borderWidth: "1px",
              borderStyle: "solid",
              transition: "background-color 0.3s, color 0.3s",
              "&:hover": {
                backgroundColor: "black",
                color: "white",
              },
            },
          }}
        />
        <ClientLimitReached user={user} userData={userData} />
        <Box
          sx={{
            backgroundColor: "#EFF3F6",
            width: "100%",
            height: "100%",
            padding: "16px",
            display: {
              xs: !collapsed ? "none" : "block",
            },
            overflowY: "auto",
          }}
        >
          {/* <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
              paddingBottom: "24px",
            }}
          >
            <Typography
              sx={{
                color: "black",
                fontSize: "36px",
                fontFamily: "Poppins",
                fontWeight: 300,
              }}
            >
              Leadership Center
            </Typography>
          </Box> */}
          {clientData?.length === 0 ? (
            <AddClientCenter
              user={user}
              loading={loading}
              setLoading={setLoading}
              fetchClientData={fetchClientData}
            />
          ) : (
            <Box
              sx={{
                display: "flex",
                gap: "8px",
                flexDirection: {
                  xs: "column",
                  lg: "row-reverse",
                },
                height: "100%",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  width: "100%",
                }}
              >
                {!isPrivate && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderRadius: "26px",
                      background: "white",
                      paddingY: "20px",
                      paddingX: "20px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "black",
                        fontFamily: "Montserrat",
                        fontSize: 28,
                      }}
                    >
                      <span id="hint-anchor-0">Members</span>
                    </Typography>
                    <_LeadershipCenterTeamFilter
                      value={selectedTeamId}
                      handleChange={setSelectedTeamId}
                      items={teamFilterItems}
                    />
                  </Box>
                )}
                <ClientCards
                  user={user}
                  userData={userData}
                  clientData={filteredClientData}
                  loading={loading}
                  setLoading={setLoading}
                  fetchClientData={fetchClientData}
                  avatar={avatar}
                  clientPicture={clientPicture}
                />
              </Box>

              <ActivityFeed
                activityData={activityData}
                avatar={avatar}
                clientPicture={clientPicture}
                filterEventItems={filterEventItems}
                filterClientItems={filterClientItems}
                filterParticipantItems={filterParticipantItems}
                loading={loadingActivityData}
              />
            </Box>
          )}
        </Box>
      </GlobalPageComponent>
    </>
  );
};

export default ClientCenter;
