import { DataGrid } from '@mui/x-data-grid';
import { styled } from "@mui/material/styles";

const UsersTable = styled(DataGrid)(({ theme }) => ({
    border: 'none',
    boxShadow: 'none',
    width: '100%', // Set full width for the grid
    minHeight: '300px', // Ensure minimum height for visibility
    height: 'auto', // Allow auto height adjustment

    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: 'none',
      borderBottom: '1px solid #F3F3F3',
      color: '#B6B6B6',
    },
    '& .MuiDataGrid-cell': {
      borderBottom: 'none',
      color: 'black',
      outline: 'none',
      '&:focus': {
        outline: 'none',
      },
    },
    '& .MuiDataGrid-row': {
      backgroundColor: 'white',
      borderBottom: '1px solid #F3F3F3',
      '&.even': {
        backgroundColor: "transparent",
      },
      '&.odd': {
        backgroundColor: theme.palette.grey[100],
      },
      "&:hover": {
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        zIndex: 1,
        color: "#202226",
        cursor: "pointer",
      },
    },
    '& .MuiDataGrid-footerContainer': {
      borderTop: 'none',
      boxShadow: 'none',
    },
    '& .MuiTablePagination-root': {
      border: 'none',
      color: 'black',
      '& .MuiTablePagination-caption': {
        color: 'black',
      },
      '& .MuiSelect-select': {
        color: 'black',
      },
      '& .MuiTablePagination-displayedRows': {
        color: 'black',
      },
    },
    '& .MuiDataGrid-virtualScroller': {
      borderRadius: 0,
      height: 'auto', // Adjust height dynamically
    },
    '& .MuiDataGrid-virtualScrollerContent': {
      minHeight: '100px', // Set a minimum height for the content
    },
    "& .MuiCheckbox-root": {
      display: "none",
    },
    '& .MuiDataGrid-columnHeader:focus': {
      outline: 'none',
    },
    '& .MuiDataGrid-columnHeader:focus-within': {
      outline: 'none',
    },
    '& .MuiIconButton-root': {
      color: 'black',
      '&:hover': {
        color: '#B6B6B6',
      },
    },
    '& .MuiDataGrid-overlay': {
      backgroundColor: 'transparent',
    },
    '& .MuiDataGrid-noRowsOverlay': {
      backgroundColor: 'transparent',
      color: 'black',
    },
    '& .MuiDataGrid-loadingOverlay': {
      backgroundColor: 'transparent',
    },
    '& .MuiDataGrid-checkboxInput': {
      '&:focus': {
        outline: 'none',
        boxShadow: 'none',
      },
    },
    '& .MuiCheckbox-root': {
      '&:focus': {
        outline: 'none',
        boxShadow: 'none',
      },
    },

    // Media Queries for small screens
    [theme.breakpoints.down('sm')]: {
      '& .MuiDataGrid-columnHeaders': {
        fontSize: '12px', // Smaller font size for small screens
      },
      '& .MuiDataGrid-cell': {
        fontSize: '12px', // Smaller font size for small screens
        padding: '4px', // Adjust padding
      },
      height: '300px', // Set a fixed height for smaller screens
      minHeight: '200px', // Minimum height for smaller screens
      '& .MuiDataGrid-virtualScroller': {
        overflowX: 'auto', // Enable horizontal scrolling on small screens
      },
    },
}));

export default UsersTable;