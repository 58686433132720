import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CloseIcon from '@mui/icons-material/Close';

export const _AssessmentsFilter = ({ value, handleChange, items }) => {
  const [opened, setOpened] = useState(false);

  // Display "Most Recent" when value is cleared or set to "none"
  const selectedDate = items.find(item => item.value === value)?.key || "Most Recent";

  return (
    <Box
      sx={{
        padding: "10px",
        position: "relative",
        borderWidth: opened ? "1px 1px 0" : "1px",
        borderColor: "#CBD0C8",
        borderStyle: "solid",
        borderRadius: opened ? "8px 8px 0 0" : "8px",
        width: "auto", 
        minWidth: "100px", 
        maxWidth: "100%", 
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          cursor: "pointer",
        }}
        onClick={() => setOpened(!opened)}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Typography
            sx={{
              fontFamily: "Poppins",
              color: "black",
              fontSize: "12px",
              fontWeight: 500,
              letterSpacing: "0.05em",
              lineHeight: "18px",
              textTransform: "capitalize",
            }}
          >
            {selectedDate}
          </Typography>
          {value !== "none" && value !== "" && value !== null && (
            <CloseIcon
              sx={{
                color: "black",
                fontSize: "10px",
                cursor: "pointer",
                background: "#AAA",
                borderRadius: "50%",
                padding: "2px",
              }}
              onClick={(e) => {
                e.stopPropagation();
                handleChange("none"); // Clear the filter and trigger most recent selection
              }}
            />
          )}
        </Box>
        <KeyboardArrowDownIcon
          sx={{
            color: "black",
            transition: "all 0.3s ease",
            transform: `rotate(${opened ? '180deg' : '0deg'})`,
            width: "12px",
            height: "12px",
          }}
        />
      </Box>

      <Box
        sx={{
          display: opened ? "flex" : "none",
          flexDirection: "column",
          position: "absolute",
          top: "30px",
          left: "-1px",
          zIndex: 10,
          width: "calc(100% + 2px)",
          borderWidth: "0 1px 1px",
          borderColor: "#CBD0C8",
          borderStyle: "solid",
          borderRadius: "0 0 8px 8px",
          maxHeight: "210px",
          overflowY: "auto",
          background: "white",
        }}
      >
        {items.map((item, index) => (
          <Box
            key={index}
            sx={{
              padding: "12px",
              fontFamily: "Lexend",
              fontSize: "10px",
              fontWeight: "500",
              lineHeight: "1.5",
              color: "black",
              background: "white",
              cursor: "pointer",
              "&:hover": {
                background: "#F5F6F4",
              },
            }}
            onClick={() => {
              setOpened(false);
              handleChange(item.value); // Select a specific assessment
            }}
          >
            {item.key}
          </Box>
        ))}
      </Box>

      {opened && (
        <Box
          sx={{
            position: "fixed",
            width: "100%",
            height: "100%",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 9,
          }}
          onClick={() => setOpened(false)}
        />
      )}
    </Box>
  );
};
