import React, { useState } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, CircularProgress, Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { formatDistanceToNow } from "date-fns";
import HistoryIcon from '@mui/icons-material/History';
import TheBigFiveHistoryModal from "./TheBigFive/TheBigFiveHistoryModal";
import AssessmentsHistoryIcon from "../../components/Icons/AssessmentHistory.svg"

const AssessmentsTable = ({ user, individualData, loading, viewAssessment, setViewAssessment }) => {
  const navigate = useNavigate();
  const [openHistoryModal, setOpenHistoryModal] = useState(false); // Modal open state
  const [selectedAssessment, setSelectedAssessment] = useState(null); // Selected assessment state

  const handleOpenHistoryModal = (assessment) => {
    setSelectedAssessment(assessment); // Set the selected assessment
    setOpenHistoryModal(true); // Open the modal
  };

  const handleCloseHistoryModal = () => {
    setOpenHistoryModal(false); // Close the modal
    setSelectedAssessment(null); // Clear the selected assessment
  };

  return (
    <Box sx={{ height: "100%" }}>
      {loading ? (
        <Box sx={{ display: "flex", padding: "30px", width: "100%", justifyContent: "center", alignItems: "center" }}>
          <CircularProgress sx={{ color: "black" }} />
        </Box>
      ) : (
        <>
          <TableContainer
            sx={{
              maxHeight: "600px",
              overflowY: "auto",
              paddingRight: "8px",
              width: "100%",
              "&::-webkit-scrollbar": {
                width: "8px", // Custom scrollbar width
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgb(207, 207, 207)", // Custom scrollbar thumb color
                borderRadius: "4px", // Rounded scrollbar thumb
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: "rgb(170, 170, 170)", // Thumb color on hover
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "transparent", // Custom scrollbar track
              },
            }}
          >
            <Table sx={{ width: "100%" }}>
              <TableHead>
                <TableRow
                  sx={{
                    borderBottom: "1px solid #F3F3F3",
                  }}
                >
                  <TableCell sx={{ color: 'black', fontSize: 13.63, fontFamily: 'Lexend', fontWeight: '500' }}>Name</TableCell>
                  <TableCell sx={{ color: 'black', fontSize: 13.63, fontFamily: 'Lexend', fontWeight: '500' }}>Completion Date</TableCell>
                  <TableCell sx={{ color: 'black', fontSize: 13.63, fontFamily: 'Lexend', fontWeight: '500' }}>Status</TableCell>
                  <TableCell sx={{ color: 'black', fontSize: 13.63, fontFamily: 'Lexend', fontWeight: '500' }}>Results</TableCell>
                  <TableCell sx={{ color: 'black', fontSize: 13.63, fontFamily: 'Lexend', fontWeight: '500', width: '20px' }}></TableCell>
                </TableRow>
              </TableHead>

              <TableBody sx={{ overflow: "auto" }}>
                {individualData.map((assessment, index) => {
                  const { user_name, completed, updatedAt, createdAt, user_id, id, test_type, coach_id, total_completed_assessments_count } = assessment;
                  const isSelected = viewAssessment && viewAssessment.id === id;

                  return (
                    <TableRow
                      key={index}
                      sx={{
                        '& td, & th': { borderBottom: 0 },
                        borderBottom: user_id === user?.sub ? "1px solid black" : "1px solid #F3F3F3",
                        background: index % 2 === 0 ? "white" : "#F9F9F9",
                        "&:hover": {
                          boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.2)", // Shift shadow downward more
                          zIndex: 1,
                          background: index % 2 === 0 ? "white" : "#F9F9F9",
                        },
                      }}
                    >
                      <TableCell sx={{ color: "#1B114A", fontFamily: "Lexend", fontSize: "16px", fontWeight: 200, display: "flex", flexDirection: "row", gap: "8px", alignItems: "center" }}>
                        <Avatar sx={{ width: "25px", height: "25px" }} src={''} />
                        <Typography sx={{ color: "#1B114A", fontSize: 15.90, fontWeight: 300, fontFamily: "Lexend" }}>
                          {user_name || "Unknown Coachee"}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ color: isSelected ? "#1B114A" : "#93989A", fontFamily: "Lexend", fontSize: 15.90, fontWeight: 400 }}>
                        {completed
                          ? new Date(updatedAt).toLocaleDateString()
                          : `Sent ${formatDistanceToNow(new Date(createdAt))} ago`}
                      </TableCell>
                      <TableCell sx={{ color: isSelected ? "#1B114A" : "#93989A", fontSize: 15.90, fontFamily: "Lexend", fontWeight: 400 }}>
                        {completed ? "Complete" : "Incomplete"}
                      </TableCell>
                      <TableCell sx={{ color: "black" }}>
                        {completed ? (
                          <Button
                            sx={{
                              color: isSelected ? "white" : "black",
                              background: isSelected ? "linear-gradient(315deg, #0076BA 0%, #03093A 60%, #242F8C 100%)" : "transparent",
                              borderRadius: "60px",
                            }}
                            variant="outlined"
                            onClick={() => setViewAssessment(assessment)}
                          >
                            <Typography sx={{ fontSize: "12px", fontWeight: 500, textTransform: "capitalize", fontFamily: "Poppins", letterSpacing: "0.05em", lineHeight: "18px" }} data-cy="view-assessment">
                              View
                            </Typography>
                          </Button>
                        ) : (
                          user?.sub === user_id ? (
                            <Button
                              sx={{ color: "black", background: "white", borderRadius: "60px" }}
                              variant="outlined"
                              disabled={!id}
                              onClick={() => navigate(`/platform/coachee/assessments/${test_type}/${coach_id}/${id}`)}
                            >
                              <Typography sx={{ fontSize: "12px", fontWeight: 500, textTransform: "capitalize", fontFamily: "Poppins", letterSpacing:"0.05em", lineHeight:"18px" }}>
                                My Test
                              </Typography>
                            </Button>
                          ) : (
                            <Typography sx={{ color: "#93989A", fontSize: "16px", fontFamily: "Lexend", marginLeft: "5px", textTransform:"capitalize" }}>
                              Pending
                            </Typography>
                          )
                        )}
                      </TableCell>
                      <TableCell sx={{ color: "#93989A", fontFamily: "Lexend", fontSize: 15.90, fontWeight: 400, width: "20px", padding: "0px" }}>
                        {total_completed_assessments_count > 0 ? (
                          <>
                          <Button 
                            sx={{  "&:hover": { cursor: "pointer" } }}
                            onClick={() => handleOpenHistoryModal(assessment)} // Open modal on click
                          >
                            <AssessmentsHistoryIcon />
                          </Button>
                          </>
                        ) : null}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          {/* The History Modal */}
          {selectedAssessment && (
            <TheBigFiveHistoryModal
              open={openHistoryModal}
              handleClose={handleCloseHistoryModal}
              userId={selectedAssessment.user_id}
              assessmentId={selectedAssessment.id}
              userName={selectedAssessment.user_name}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default AssessmentsTable;
