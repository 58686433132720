import React from 'react';

function ChevronUpArrowIcon({ color = '#111111', width = '24', height = '24' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
    <path stroke="#111" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 13.86a20.36 20.36 0 0 0-3.702-3.893.472.472 0 0 0-.596 0A20.359 20.359 0 0 0 8 13.86"/>
    </svg>
  );
}

export default ChevronUpArrowIcon;
