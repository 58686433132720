import React, { useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography,
  Collapse,
  IconButton,
} from "@mui/material";

import ClientLimitReached from "components/ClientLimitReachedModal";
import GlobalPageComponent from "components/GlobalPageComponent/GlobalPageComponent";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { generateClient } from "aws-amplify/data";
/**
 * @type {import('aws-amplify/data').Client<import('../amplify/data/resource').Schema>}
 */
import domains from "components/Assessments/TheBigFive/en/index";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { toast } from "react-toastify";
import { PDFDocument, StandardFonts, rgb, degrees, PDFName } from "pdf-lib";
import { saveAs } from "file-saver";
import altercallLogo from "assets/altercall1.png"; // Ensure this is a valid PNG file
import avatar from "assets/avatar.png";
import dayjs from "dayjs";
import SectionDropDownIcon from "components/Icons/SectionDropDownIcon.svg";

/**
 * @type {import('aws-amplify/data').Client<import('../amplify/data/resource').Schema>}
 */
const resourceClient = generateClient({ authMode: "userPool" });

const CoachIndividualBreakdown = ({ user, userData }) => {
  const navigate = useNavigate();
  const collapsed = useSelector((state) => state.layout.collapsed);
  const tagNames = [
    "General",
    "Openness",
    "Conscientiousness",
    "Extraversion",
    "Agreeableness",
    "Neuroticism",
  ];
  const [selectedTag, setSelectedTag] = useState(tagNames[0]);
  const [loading, setLoading] = useState(false);
  const [coacheeData, setCoacheeData] = useState([]);
  const [assessmentData, setAssessmentData] = useState([]);
  const [openCommunicationReport, setOpenCommunicationReport] = useState(false);
  const [openCommunicationStyle, setOpenCommunicationStyle] = useState(false);
  const [openCommunicationPreferences, setOpenCommunicationPreferences] =
    useState(false);
  const [loadingAnalysis, setLoadingAnalysis] = useState(false);
  const [progress, setProgress] = useState(0);
  const [loadingPDF, setLoadingPDF] = useState(false);

  const [openSections, setOpenSections] = useState(null);

  const { testId, userId, teamId, tab } = useParams();

  // Function to handle opening/closing sections
  const handleToggle = (section) => {
    setOpenSections((prevSection) =>
      prevSection === section ? null : section
    );
  };

  useEffect(() => {
    if (tab) {
      setSelectedTag(tab);
    }
  }, [tab]);

  const fetchCoacheeData = async () => {
    setLoading(true);
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/zoom/fetch-client-data-test`,
        {
          params: {
            client_id: userId,
            coach_id: user?.sub,
          },
        }
      );

      const { data: assessmentRes, erros } =
        await resourceClient.models.assessments.get({
          id: testId,
        });

      if (!response.data || !assessmentRes) {
        setLoading(false);
        return;
      }

      // Parse the results field
      const results = JSON.parse(assessmentRes.results);
      setAssessmentData({ ...assessmentRes, results });

      console.log("assessment data: ", assessmentRes);

      const data = response.data.client_data;
      data.avatar = data.avatar
        ? "https://d7joen7tb0xwx.cloudfront.net/public/" + data.avatar
        : null;
      setCoacheeData(data);

      console.log("assesments data: ", assessmentData);
    } catch (error) {
      console.error("Error fetching client data:", error);
    } finally {
      setLoading(false);
    }
  };

  const [assessmentReady, setAssessmentReady] = useState(false);

  useEffect(() => {
    fetchCoacheeData();
  }, []);

  useEffect(() => {
    console.log("user for subscription", user?.sub);
    const updateSub = resourceClient.models.assessments
      .onUpdate({
        filter: {
          user_id: {
            contains: `${user?.sub}`,
          },
        },
      })
      .subscribe({
        authMode: "userPool",
        next: (data) => {
          console.log("Subscription Update:", data);

          // Check if the required fields are updated
          const updatedFields = data.element.updatedFields || [];
          const requiredFields = [
            "summary",
            "communication_style",
            "communication_tips",
          ];

          if (requiredFields.every((field) => updatedFields.includes(field))) {
            toast.success("Assessment Analysis ready", {
              duration: 3000,
              position: "top-center",
            });
            setAssessmentReady(true);
          }
        },
        error: (error) => console.warn("Subscription Error:", error),
      });

    // Clean up on component unmount
    return () => updateSub.unsubscribe();
  }, [user?.sub]);

  useEffect(() => {
    if (assessmentReady) {
      fetchCoacheeData();
      setAssessmentReady(false);
    }
  }, [assessmentReady]);

  // Find the domain based on the selectedTag
  const selectedDomain = domains.find((domain) => domain.title === selectedTag);

  // Get the result for the selected domain
  const domainResult = assessmentData?.results?.[selectedDomain?.domain];

  const scaledScore = (facetScore, count) => {
    const maxScore = count * 5;
    return (facetScore / maxScore) * 10;
  };

  const domainColors = {
    G: "#000000", //General
    N: "#9865CF", // Neuroticism
    O: "#EF406E", // Openness to Experience
    A: "#F3AB54", // Agreeableness
    E: "#337BE8", // Extraversion
    C: "#49CA7A", // Conscientiousness
  };

  const facetColors = {
    0: "#9865CF",
    1: "#337BE8",
    2: "#EF406E",
    3: "#F3AB54",
    4: "#49CA7A",
    5: "#000000",
  };

  const domainOrder = ["O", "C", "E", "A", "N"];
  // Generate the PDF report
  const downloadPDF = async () => {
    console.log("Generating PDF report...");
    let name = coacheeData?.full_name || "Coachee";
    console.log("Generating PDF report for:", name);
    const pdfDoc = await PDFDocument.create();
    try {
      console.log("user:", user);
      console.log("domaindata:", domains);
      console.log("assessmentData:", assessmentData);
      console.log("coacheeData:", coacheeData);
      console.log("domainResult:", domainResult);
      console.log("selectedDomain:", selectedDomain);
      console.log("selectedTag:", selectedTag);
      console.log("scaledScore:", scaledScore);

      //page1
      const page1 = pdfDoc.addPage([1500, 1974]); // Custom size
      const svgToPng = async (svg) => {
        const svgBlob = new Blob([svg], { type: "image/svg+xml" });
        const url = URL.createObjectURL(svgBlob);
        const img = new Image();
        img.src = url;
        await new Promise((resolve) => (img.onload = resolve));
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        URL.revokeObjectURL(url);
        return canvas.toDataURL("image/png");
      };

      //page 1 background color:
      const backgroundSvg = `
                <svg width="1500" height="1974" viewBox="0 0 1500 1974" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0H1500V1974H0V0Z" fill="url(#paint0_linear_4460_1888)"/>
                <defs>
                    <linearGradient id="paint0_linear_4460_1888" x1="1298.72" y1="204.388" x2="-718.232" y2="1344.22" gradientUnits="userSpaceOnUse">
                    <stop/>
                    <stop offset="1" stop-color="#3480FF"/>
                    </linearGradient>
                </defs>
                </svg>
            `;

      const backgroundPng = await svgToPng(backgroundSvg);
      const backgroundImage = await pdfDoc.embedPng(backgroundPng);

      page1.drawImage(backgroundImage, {
        x: 0,
        y: 0,
        width: 1500,
        height: 1974,
      });

      const logoSvg3 = `
                    <svg width="271" height="52" viewBox="0 0 271 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M39.8853 35.7194C42.7146 40.6567 45.5457 45.5985 48.4038 50.5863C47.5785 51.0573 46.7776 51.5151 45.9278 52C43.1343 47.127 40.3512 42.2724 37.5881 37.4516C28.704 42.6258 19.8845 42.6258 10.9864 37.4525C8.22948 42.261 5.44551 47.1183 2.64758 51.9991C1.81178 51.5221 1.00913 51.0642 0.170708 50.5863C3.02186 45.6098 5.85294 40.6689 8.691 35.7168C2.46 30.1962 -0.534227 23.322 0.0782294 15.0132C0.500493 9.28892 3.33332 3.36529 7.05953 7.62939e-06C7.70601 0.673761 8.35337 1.34838 8.99985 2.02301C-0.166052 11.0273 1.35723 25.815 10.1742 33.1297C14.8662 24.9428 19.5538 16.762 24.2877 8.50201C29.0163 16.7533 33.704 24.9332 38.3934 33.1166C46.8954 26.1737 48.984 11.5409 39.5005 1.96382C40.1636 1.3214 40.8275 0.678984 41.4932 0.0356974C50.7036 8.38623 51.8779 26.1623 39.8844 35.7194H39.8853ZM12.4556 34.8881C19.9682 39.9943 29.9254 39.284 36.1041 34.8602C32.1807 28.013 28.2556 21.164 24.2877 14.2402C20.3172 21.1684 16.3921 28.0182 12.4556 34.8872V34.8881Z" fill="white"/>
                    <path d="M91.8662 28.2079H81.6535L79.4539 33.1328H77.4375L85.8172 14.6973H87.7288L96.1084 33.1328H94.0659L91.8662 28.2079ZM91.1592 26.6277L86.7599 16.7252L82.3606 26.6277H91.1592Z" fill="white"/>
                    <path d="M104.376 14.6974H106.314V31.4474H116.579V33.1329H104.376V14.6974Z" fill="white"/>
                    <path d="M126.898 16.3829H120.456V14.6974H135.278V16.3829H128.836V33.1329H126.898V16.3829Z" fill="white"/>
                    <path d="M156.564 31.4473V33.1328H143.602V14.6973H156.171V16.3828H145.539V22.9406H155.019V24.5998H145.539V31.4473H156.564Z" fill="white"/>
                    <path d="M178.887 33.1329L174.697 27.2072C174.226 27.2599 173.755 27.2862 173.231 27.2862H168.308V33.1329H166.37V14.6974H173.231C177.892 14.6974 180.72 17.0677 180.72 21.0181C180.72 23.9151 179.201 25.9431 176.53 26.8122L181.008 33.1329H178.887ZM178.782 21.0181C178.782 18.0685 176.845 16.3829 173.179 16.3829H168.308V25.627H173.179C176.845 25.627 178.782 23.9151 178.782 21.0181Z" fill="white"/>
                    <path d="M188.905 23.9178C188.905 18.5188 192.99 14.542 198.516 14.542C201.187 14.542 203.596 15.4374 205.219 17.2283L203.989 18.4661C202.496 16.9386 200.663 16.2802 198.568 16.2802C194.143 16.2802 190.817 19.5459 190.817 23.9178C190.817 28.2896 194.143 31.5554 198.568 31.5554C200.663 31.5554 202.496 30.8969 203.989 29.3431L205.219 30.5809C203.596 32.3718 201.187 33.2936 198.49 33.2936C192.99 33.2936 188.905 29.3168 188.905 23.9178Z" fill="white"/>
                    <path d="M225.622 28.208H215.409L213.21 33.1329H211.193L219.573 14.6974H221.485L229.864 33.1329H227.822L225.622 28.208ZM224.915 26.6278L220.516 16.7253L216.116 26.6278H224.915Z" fill="white"/>
                    <path d="M238.132 14.6974H240.07V31.4474H250.335V33.1329H238.132V14.6974Z" fill="white"/>
                    <path d="M258.797 14.6974H260.735V31.4474H271V33.1329H258.797V14.6974Z" fill="white"/>
                    </svg>
                `;

      const logoPng = await svgToPng(logoSvg3);
      const logoImage3 = await pdfDoc.embedPng(logoPng);

      page1.drawImage(logoImage3, {
        x: 191,
        y: page1.getHeight() - 150 - 75, // Adjusted for top position
        width: 391,
        height: 75,
        opacity: 1,
      });
      const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

      const gradientEllipseSvg = `
                <svg width="1495" height="1474" viewBox="0 0 1495 1474" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_4460_1889" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="1495" height="1474">
                    <rect width="1495" height="1474" rx="20" fill="url(#paint0_linear_4460_1889)"/>
                </mask>
                <g mask="url(#mask0_4460_1889)">
                    <circle opacity="0.3" cx="317.881" cy="1989.57" r="1459.04" stroke="#6EA4FF"/>
                    <path d="M-1081.79 2186.25C-1058.13 2364.38 -1001.27 2534.34 -912.797 2691.58L-861.788 2662.61C-946.495 2511.97 -1000.96 2349.16 -1023.63 2178.56C-1047.4 1999.53 -1034.99 1819.69 -986.696 1644.06C-938.4 1468.42 -857.133 1307.51 -745.143 1165.81C-636.975 1028.94 -504.837 915.624 -352.412 829.008C-199.988 742.392 -34.9421 686.894 138.096 663.998C317.236 640.308 497.198 652.801 672.964 701.133C848.73 749.465 1009.78 830.742 1151.62 942.695C1288.62 1050.85 1402.06 1182.94 1488.79 1335.3C1575.53 1487.66 1631.12 1652.62 1654.1 1825.55C1677.88 2004.59 1665.45 2184.42 1617.15 2360.06C1568.85 2535.69 1487.59 2696.6 1375.62 2838.31C1267.97 2974.55 1136.53 3087.43 985.006 3173.89L1012.9 3225.51C1171.51 3135.22 1309.04 3017.18 1421.69 2874.65C1538.55 2726.75 1623.36 2558.85 1673.75 2375.6C1724.14 2192.36 1737.1 2004.7 1712.29 1817.84C1688.32 1637.37 1630.29 1465.26 1539.81 1306.3C1449.34 1147.34 1330.96 1009.5 1187.99 896.652C1039.95 779.792 871.905 694.988 688.52 644.561C505.135 594.134 317.347 581.11 130.378 605.831C-50.1947 629.715 -222.399 687.643 -381.43 777.996C-540.46 868.35 -678.336 986.594 -791.206 1129.43C-908.085 1277.33 -992.879 1445.23 -1043.27 1628.48C-1093.66 1811.72 -1106.62 1999.38 -1081.81 2186.24L-1081.79 2186.25Z" fill="url(#paint1_linear_4460_1889)"/>
                </g>
                <defs>
                    <linearGradient id="paint0_linear_4460_1889" x1="1530.81" y1="-193.08" x2="224.575" y2="153.393" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#03070E"/>
                    <stop offset="1" stop-color="#478DFF"/>
                    </linearGradient>
                    <linearGradient id="paint1_linear_4460_1889" x1="-433.478" y1="621.878" x2="1518.83" y2="1401.08" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#3480FF" stop-opacity="0"/>
                    <stop offset="0.185" stop-color="#3480FF" stop-opacity="0.25"/>
                    <stop offset="0.426471" stop-color="#3480FF"/>
                    <stop offset="1"/>
                    </linearGradient>
                </defs>
                </svg>
                `;

      const gradientEllipsePng = await svgToPng(gradientEllipseSvg);
      const gradientEllipseImage = await pdfDoc.embedPng(gradientEllipsePng);

      page1.drawImage(gradientEllipseImage, {
        x: 5,
        y: page1.getHeight() - 404 - 1474, // Adjusted for top position
        width: 1495,
        height: 1474,
        opacity: 1,
      });
      // Draw the text "clientName Session Summary" in white color
      // page1.drawText("The Big Five", {
      // x: 191,
      // y: page1.getHeight() - 446 - 100, // Adjusted for top position
      // size: 86,
      // color: rgb(1, 1, 1), // White color
      // fontFamily: "Poppins",
      // width: 1066,
      // height: 300,
      // lineHeight: 100,
      // fontWeight: 300,
      // textAlign: 'left',
      // opacity: 1,
      // });

      // Draw the text "from" in white color
      page1.drawText(`The OCEAN`, {
        x: 191,
        y: page1.getHeight() - 546 - 100, // Adjusted for top position
        size: 86,
        color: rgb(1, 1, 1), // White color
        fontFamily: "Poppins",
        width: 1066,
        height: 300,
        lineHeight: 100,
        fontWeight: 300,
        textAlign: "left",
        opacity: 1,
      });

      // Draw the text "callName" in orange color
      page1.drawText(`Assessment Report`, {
        x: 191,
        y: page1.getHeight() - 646 - 100, // Adjusted for top position
        size: 86,
        color: rgb(1, 94 / 255, 0), // Orange color
        fontFamily: "Poppins",
        width: 1066,
        height: 300,
        lineHeight: 100,
        fontWeight: 400,
        textAlign: "left",
        opacity: 1,
      });
      const clientAvatarUrl = coacheeData.avatar || avatar;
      console.log("Client avatar URL:", clientAvatarUrl);

      try {
        const response = await fetch(clientAvatarUrl);
        if (!response.ok) {
          throw new Error(
            `Failed to fetch avatar image: ${response.statusText}`
          );
        }
        const avatarImageBytes = await response.arrayBuffer();
        const avatarImage = await pdfDoc.embedPng(avatarImageBytes);
        page1.drawImage(avatarImage, {
          x: 190,
          y: page1.getHeight() - 1413,
          width: 45,
          height: 45,
          opacity: 1,
        });
      } catch (error) {
        console.error("Error fetching or embedding avatar image:", error);
        // Use the imported default avatar directly if fetching fails
        const defaultAvatarBytes = await fetch(avatar).then((res) =>
          res.arrayBuffer()
        );
        const defaultAvatarImage = await pdfDoc.embedPng(defaultAvatarBytes);
        page1.drawImage(defaultAvatarImage, {
          x: 190,
          y: page1.getHeight() - 1413,
          width: 45,
          height: 45,
          opacity: 1,
        });
      }

      // Add text using the standard Helvetica font
      page1.drawText(`${name}`, {
        x: 248, // Adjusted to match the left position
        y: page1.getHeight() - 1403, // Adjusted for top position
        size: 24,
        //font: helveticaFont, // Assuming Lexend is not available in PDFKit, using helveticaFont
        color: rgb(1, 1, 1),
        width: 170, // Adjusted to match the width
        height: 30, // Adjusted to match the height
        lineHeight: 30,
        fontWeight: 300,
        textAlign: "left",
        opacity: 1, // Adjusted to match the opacity
      });
      // Draw the "Learn More Here" rectangle

      const learnMoreSvg = `
                    <svg width="273" height="58" viewBox="0 0 273 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" y="0.5" width="272" height="57" rx="28.5" fill="white"/>
                    <rect x="0.5" y="0.5" width="272" height="57" rx="28.5" fill="#FF5E00"/>
                    <rect x="0.5" y="0.5" width="272" height="57" rx="28.5" stroke="#FF5E00"/>
                    </svg>
                `;

      const learnMorePng = await svgToPng(learnMoreSvg);
      const learnMoreImage = await pdfDoc.embedPng(learnMorePng);

      page1.drawImage(learnMoreImage, {
        x: 190,
        y: page1.getHeight() - 1582,
        width: 273,
        height: 58,
        opacity: 1,
      });

      // Draw the "Learn More Here" text
      page1.drawText("Learn More Here", {
        x: 220,
        y: page1.getHeight() - 1582 + 15.5, // Adjusted to center the text vertically
        size: 24,
        color: rgb(1, 1, 1), // White color
        width: 273,
        height: 58,
        lineHeight: 35,
        fontWeight: 500,
        textAlign: "center",
        opacity: 1,
      });

      // Add a hyperlink to the "Learn More Here" text
      const linkAnnotation = pdfDoc.context.obj({
        Type: "Annot",
        Subtype: "Link",
        Rect: [
          225,
          page1.getHeight() - 1582,
          225 + 273,
          page1.getHeight() - 1582 + 58,
        ],
        Border: [0, 0, 0],
        A: {
          Type: "Action",
          S: "URI",
          URI: "https://altercall.com/aai",
        },
      });

      // Add the annotation to the page's annotations array
      page1.node.set(
        PDFName.of("Annots"),
        pdfDoc.context.obj([linkAnnotation])
      );
      const customdate = assessmentData?.updatedAt
        ? dayjs(assessmentData?.updatedAt).format("MMMM D, YYYY")
        : "";
      console.log("customdate:", customdate);
      page1.drawText(`${customdate}`, {
        x: 193,
        y: page1.getHeight() - 1740,
        size: 24,
        color: rgb(1, 1, 1),
        width: 247,
        height: 30,
        lineHeight: 35, // Updated line height
        fontWeight: 300,
        textAlign: "left",
        opacity: 1, // Updated opacity to 0
      });
      const vectorFooterwhiteSvg = `
                      <svg width="49" height="52" viewBox="0 0 49 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M39.8853 35.7194C42.7146 40.6567 45.5457 45.5985 48.4038 50.5863C47.5785 51.0573 46.7776 51.5151 45.9278 52C43.1343 47.127 40.3512 42.2723 37.5881 37.4516C28.704 42.6258 19.8845 42.6258 10.9864 37.4525C8.22948 42.261 5.44551 47.1183 2.64758 51.9991C1.81178 51.5221 1.00913 51.0642 0.170708 50.5863C3.02186 45.6098 5.85294 40.6689 8.691 35.7167C2.46 30.1962 -0.534227 23.322 0.0782294 15.0132C0.500493 9.28891 3.33332 3.36528 7.05953 0C7.70601 0.673753 8.35337 1.34838 8.99985 2.023C-0.166052 11.0273 1.35723 25.815 10.1742 33.1297C14.8662 24.9428 19.5538 16.762 24.2877 8.502C29.0163 16.7533 33.704 24.9332 38.3934 33.1166C46.8954 26.1737 48.984 11.5409 39.5005 1.96381C40.1636 1.32139 40.8275 0.678976 41.4932 0.0356898C50.7036 8.38622 51.8779 26.1623 39.8844 35.7194H39.8853ZM12.4556 34.8881C19.9682 39.9943 29.9254 39.284 36.1041 34.8602C32.1807 28.013 28.2556 21.164 24.2877 14.2402C20.3172 21.1684 16.3921 28.0182 12.4556 34.8872V34.8881Z" fill="white"/>
                      </svg>
                    `;

      const vectorFooterwhitePng = await svgToPng(vectorFooterwhiteSvg);
      const vectorFooterwhiteImage = await pdfDoc.embedPng(
        vectorFooterwhitePng
      );

      page1.drawImage(vectorFooterwhiteImage, {
        x: 1378,
        y: page1.getHeight() - 1852,
        width: 48.64,
        height: 52,
        opacity: 1,
      });

      const page2 = pdfDoc.addPage([1500, 1974]); // Custom size
      page2.drawRectangle({
        x: 0,
        y: 0,
        width: page2.getWidth(),
        height: page2.getHeight(),
        color: rgb(0, 0, 0),
        opacity: 1,
      });
      const page2BackgroundSvg = `<svg width="1500" height="1040" viewBox="0 0 1500 1040" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="1500" height="1040" fill="url(#paint0_linear_4572_672)"/>
                    <defs>
                    <linearGradient id="paint0_linear_4572_672" x1="1603" y1="469.578" x2="851.945" y2="-1485.91" gradientUnits="userSpaceOnUse">
                    <stop/>
                    <stop offset="0.59" stop-color="#3480FF" stop-opacity="0.86"/>
                    <stop offset="1" stop-color="white" stop-opacity="0.1"/>
                    </linearGradient>
                    </defs>
                    </svg>
                    `;
      const page2BackgroundPng = await svgToPng(page2BackgroundSvg);
      const page2BackgroundImage = await pdfDoc.embedPng(page2BackgroundPng);

      // Draw the gradient background from y = 0 to y = 781
      page2.drawImage(page2BackgroundImage, {
        x: 0,
        y: page2.getHeight() - 1040, // Start from the top
        width: page2.getWidth(), // Full width of the page
        height: 1040, // Height of 781
        opacity: 1, // Set opacity to 1
      });
      page2.drawRectangle({
        x: 0,
        y: page2.getHeight() - 1040 - 16, // Positioned at the top of the white rectangle
        width: 1500, // Full width of the page
        height: 16, // Height of 16px
        color: rgb(1, 94 / 255, 0), // Orange color (rgba(255, 94, 0, 1))
        opacity: 1, // Set opacity to 1
      });

      // Draw a white rectangle for the rest of the page
      page2.drawRectangle({
        x: 0,
        y: 0, // Start from the bottom
        width: page2.getWidth(), // Full width of the page
        height: page2.getHeight() - 1039, // Height from y = 0 to y = 1040
        color: rgb(1, 1, 1), // White color
      });
      page2.drawImage(logoImage3, {
        x: 80,
        y: page2.getHeight() - 33 - 44, // Adjusted for top position
        width: 229,
        height: 44,
        opacity: 1, // Adjusted opacity
      });
      //main cotainer
      const containerSvg = `
                      <svg width="515" height="65" viewBox="0 0 515 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.5" y="0.5" width="514" height="64" rx="32" fill="white" fill-opacity="0.4" stroke="white"/>
                        </svg>`;
      const containerPng = await svgToPng(containerSvg);
      const containerImage = await pdfDoc.embedPng(containerPng);

      page2.drawImage(containerImage, {
        x: 925,
        y: page2.getHeight() - 22 - 65, // Adjusted for top position
        width: 515,
        height: 65,
        opacity: 1,
      });
      const innerContainerSvg = `
                        <svg width="275" height="57" viewBox="0 0 275 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.5" y="0.5" width="274" height="56" rx="28" fill="white" stroke="white"/>
                        </svg>`;
      const innerContainerPng = await svgToPng(innerContainerSvg);
      const innerContainerImage = await pdfDoc.embedPng(innerContainerPng);

      page2.drawImage(innerContainerImage, {
        x: 930,
        y: page2.getHeight() - 26 - 57, // Adjusted for top position
        width: 275,
        height: 57,
        opacity: 1,
      });
      try {
        const response = await fetch(clientAvatarUrl);
        if (!response.ok) {
          throw new Error("Failed to fetch client avatar.");
        }
        const avatarImageBytes = await response.arrayBuffer();
        const avatarImage = await pdfDoc.embedPng(avatarImageBytes);
        page2.drawImage(avatarImage, {
          x: 940,
          y: page2.getHeight() - 32 - 45, // Adjusted for top position
          width: 45,
          height: 45,
          opacity: 1, // Adjusted opacity
        });
      } catch (error) {
        console.error("Error fetching or embedding avatar image:", error);
        // Use the imported default avatar directly if fetching fails
        const defaultAvatarBytes = await fetch(avatar).then((res) =>
          res.arrayBuffer()
        );
        const defaultAvatarImage = await pdfDoc.embedPng(defaultAvatarBytes);
        page2.drawImage(defaultAvatarImage, {
          x: 940,
          y: page2.getHeight() - 32 - 45, // Adjusted for top position
          width: 45,
          height: 45,
          opacity: 1, // Adjusted opacity
        });
      }

      // Draw client name
      page2.drawText(`${name}`, {
        x: 998, // Adjusted to match the left position
        y: page2.getHeight() - 36 - 30, // Adjusted for top position
        size: 24,
        font: helveticaFont, // Assuming Lexend is not available in PDFKit, using helveticaFont
        color: rgb(0, 0, 0),
        width: 170, // Adjusted to match the width
        height: 30, // Adjusted to match the height
        lineHeight: 30,
        fontWeight: 300,
        textAlign: "left",
        opacity: 1, // Adjusted to match the opacity
      });
      // Draw the custom date with the specified layout
      page2.drawText(`${customdate}`, {
        x: 1251,
        y: page2.getHeight() - 45 - 19, // Adjusted for top position
        size: 24,
        color: rgb(1, 1, 1),
        width: 155,
        height: 19,
        lineHeight: 35, // Updated line height
        fontWeight: 300,
        textAlign: "left",
        opacity: 1, // Updated opacity to 1
      });
      // Draw the "Report Overview" text
      // Define the spacing between each heading
      const headingSpacing = 50;

      // Text wrap function
      const wrapText = (text, maxWidth, fontSize, font) => {
        const words = text.split(" ");
        let lines = [];
        let currentLine = words[0];

        for (let i = 1; i < words.length; i++) {
          const word = words[i];
          const width = font.widthOfTextAtSize(
            currentLine + " " + word,
            fontSize
          );
          if (width < maxWidth) {
            currentLine += " " + word;
          } else {
            lines.push(currentLine);
            currentLine = word;
          }
        }
        lines.push(currentLine);
        return lines;
      };
      // Draw the "Communication Report" text
      page2.drawText("Assessment Summary", {
        x: 80,
        y: page2.getHeight() - 150 - 30, // Adjusted for top position
        size: 30,
        color: rgb(1, 1, 1), // White color
        fontFamily: "Poppins",
        width: 540,
        height: 75,
        lineHeight: 75,
        fontWeight: 300,
        textAlign: "left",
        opacity: 1,
      });

      const reportoverview =
        assessmentData?.summary || "The summary is being generated.";
      const reportFontSize = 14;
      const reportMaxWidth = 1320; // Updated width according to layout
      let reportLines = wrapText(
        reportoverview,
        reportMaxWidth,
        reportFontSize,
        helveticaFont
      );

      // Calculate the total height needed for the report overview text
      let reportTotalHeight = reportLines.length * reportFontSize * 1.2;
      // Adjust the y position dynamically based on the total height
      let reportCurrentY = page2.getHeight() - 150 - 70; // Updated top position according to layout

      // Check if the text exceeds the allowed height
      if (reportCurrentY - reportTotalHeight < 400) {
        // If it exceeds, cut the text to the nearest full stop
        let truncatedText = reportoverview;
        while (reportCurrentY - reportTotalHeight < 400) {
          const lastFullStopIndex = truncatedText.lastIndexOf(".");
          if (lastFullStopIndex === -1) break; // No full stop found, exit loop
          truncatedText = truncatedText.substring(0, lastFullStopIndex + 1);
          reportLines = wrapText(
            truncatedText,
            reportMaxWidth,
            reportFontSize,
            helveticaFont
          );
          reportTotalHeight = reportLines.length * reportFontSize * 1.2;
        }
      }

      reportLines.forEach((line, index) => {
        page2.drawText(line, {
          x: 80, // Updated left position according to layout
          y: reportCurrentY - index * reportFontSize * 1.2,
          size: reportFontSize,
          font: helveticaFont,
          color: rgb(1, 1, 1),
          width: reportMaxWidth,
          height: 97, // Updated height according to layout
          lineHeight: 24,
          fontWeight: 400,
          textAlign: "left",
          opacity: 1,
          letterSpacing: -0.03,
        });
      });

      // Draw the "Assessment Summary" text
      page2.drawText("Communication Report", {
        x: 80,
        y: reportCurrentY - reportTotalHeight - headingSpacing, // Adjusted for equal spacing
        size: 30,
        color: rgb(1, 1, 1), // White color
        fontFamily: "Poppins",
        width: 490,
        height: 75,
        lineHeight: 75,
        fontWeight: 300,
        textAlign: "left",
        opacity: 1,
      });

      const summary =
        assessmentData?.communication_tips ||
        "The Communication Report is being generated.";
      const summaryFontSize = 14;
      const summaryMaxWidth = 1320;
      let summaryLines = wrapText(
        summary,
        summaryMaxWidth,
        summaryFontSize,
        helveticaFont
      );

      let summaryCurrentY =
        reportCurrentY - reportTotalHeight - headingSpacing - 30; // Adjusted top position according to layout
      let summaryTotalHeight = summaryLines.length * summaryFontSize * 1.2;

      // Check if the text exceeds the allowed height
      if (summaryCurrentY - summaryTotalHeight < 640) {
        // If it exceeds, cut the text to the nearest full stop
        let truncatedText = summary;
        while (summaryCurrentY - summaryTotalHeight < 640) {
          const lastFullStopIndex = truncatedText.lastIndexOf(".");
          if (lastFullStopIndex === -1) break; // No full stop found, exit loop
          truncatedText = truncatedText.substring(0, lastFullStopIndex + 1);
          summaryLines = wrapText(
            truncatedText,
            summaryMaxWidth,
            summaryFontSize,
            helveticaFont
          );
          summaryTotalHeight = summaryLines.length * summaryFontSize * 1.2;
        }
      }

      summaryLines.forEach((line, index) => {
        page2.drawText(line, {
          x: 80, // Updated left position according to layout
          y: summaryCurrentY - index * summaryFontSize * 1.2,
          size: summaryFontSize,
          font: helveticaFont,
          color: rgb(1, 1, 1),
          width: summaryMaxWidth,
          height: 409, // Updated height according to layout
          lineHeight: 24,
          fontWeight: 400,
          textAlign: "left",
          opacity: 1,
          letterSpacing: -0.03,
        });
      });

      let comm_style =
        assessmentData?.communication_style ||
        "The communication style is being generated.";
      page2.drawText("Communication Style", {
        x: 80,
        y: summaryCurrentY - summaryTotalHeight - headingSpacing, // Adjusted for equal spacing
        size: 30,
        color: rgb(1, 1, 1), // White color
        fontFamily: "Poppins",
        width: 490,
        height: 75,
        lineHeight: 75,
        fontWeight: 300,
        textAlign: "left",
        opacity: 1,
      });

      const comm_styleFontSize = 14;
      const commStyleMaxWidth = 1320;
      const commStyleLines = wrapText(
        comm_style,
        commStyleMaxWidth,
        comm_styleFontSize,
        helveticaFont
      );

      let commStyleCurrentY =
        summaryCurrentY - summaryTotalHeight - headingSpacing - 30; // Adjusted top position according to layout
      let commStyleTotalHeight =
        commStyleLines.length * comm_styleFontSize * 1.2;

      // Check if the text exceeds the allowed height
      if (commStyleCurrentY - commStyleTotalHeight < 830) {
        // If it exceeds, reduce the font size and re-wrap the text
        comm_styleFontSize = 14;
        commStyleLines = wrapText(
          comm_style,
          commStyleMaxWidth,
          comm_styleFontSize,
          helveticaFont
        );
        commStyleTotalHeight = commStyleLines.length * comm_styleFontSize * 1.2;

        // If it still exceeds, cut the text to the nearest full stop
        if (commStyleCurrentY - commStyleTotalHeight < 830) {
          let truncatedText = comm_style;
          while (commStyleCurrentY - commStyleTotalHeight < 830) {
            const lastFullStopIndex = truncatedText.lastIndexOf(".");
            if (lastFullStopIndex === -1) break; // No full stop found, exit loop
            truncatedText = truncatedText.substring(0, lastFullStopIndex + 1);
            commStyleLines = wrapText(
              truncatedText,
              commStyleMaxWidth,
              comm_styleFontSize,
              helveticaFont
            );
            commStyleTotalHeight =
              commStyleLines.length * comm_styleFontSize * 1.2;
          }
        }
      }

      // Draw the communication style text
      commStyleLines.forEach((line, index) => {
        page2.drawText(line, {
          x: 80, // Updated left position according to layout
          y: commStyleCurrentY - index * comm_styleFontSize * 1.2,
          size: comm_styleFontSize,
          font: helveticaFont,
          color: rgb(1, 1, 1),
          width: commStyleMaxWidth,
          height: 409, // Updated height according to layout
          lineHeight: 24,
          fontWeight: 400,
          textAlign: "left",
          opacity: 1,
          letterSpacing: -0.03,
        });
      });

      // Check if the communication preference is available
      let comm_pref =
        assessmentData?.communication_preference ||
        "The communication preference is being generated.";

      // Draw "Communication Preference" heading
      page2.drawText("Communication Preference", {
        x: 80,
        y: commStyleCurrentY - commStyleTotalHeight - headingSpacing, // Adjusted for equal spacing
        size: 30,
        color: rgb(1, 1, 1), // Black color
        fontFamily: "Poppins",
        width: 490,
        height: 75,
        lineHeight: 75,
        fontWeight: 300,
        textAlign: "left",
        opacity: 1,
      });

      // Wrap the communication preference text
      const commPrefFontSize = 14;
      const commPrefMaxWidth = 1320;
      let commPrefLines = wrapText(
        comm_pref,
        commPrefMaxWidth,
        commPrefFontSize,
        helveticaFont
      );

      // Adjust top position according to layout
      let commPrefCurrentY =
        commStyleCurrentY - commStyleTotalHeight - headingSpacing - 30;
      let commPrefTotalHeight = commPrefLines.length * commPrefFontSize * 1.2;

      // Truncate the text if it exceeds the allowed height
      let iterationCount = 0;
      const maxIterations = 10; // To prevent infinite loop
      let truncatedText = comm_pref;

      while (
        commPrefCurrentY - commPrefTotalHeight < 1020 &&
        iterationCount < maxIterations
      ) {
        const lastFullStopIndex = truncatedText.lastIndexOf(".");
        if (lastFullStopIndex === -1) break; // No full stop found, exit loop

        truncatedText = truncatedText.substring(0, lastFullStopIndex + 1);
        commPrefLines = wrapText(
          truncatedText,
          commPrefMaxWidth,
          commPrefFontSize,
          helveticaFont
        );
        commPrefTotalHeight = commPrefLines.length * commPrefFontSize * 1.2;

        iterationCount++;
      }

      // Draw the communication preference text
      commPrefLines.forEach((line, index) => {
        page2.drawText(line, {
          x: 80, // Updated left position according to layout
          y: commPrefCurrentY - index * commPrefFontSize * 1.2,
          size: commPrefFontSize,
          font: helveticaFont,
          color: rgb(1, 1, 1),
          width: commPrefMaxWidth,
          height: 409, // Updated height according to layout
          lineHeight: 24,
          fontWeight: 400,
          textAlign: "left",
          opacity: 1,
          letterSpacing: -0.03,
        });
      });

      // Draw the orange rectangle at the specified position
      page2.drawRectangle({
        x: 0,
        y: page2.getHeight() - 1032 - 16, // Positioned at the specified top position
        width: 1500, // Specified width
        height: 16, // Specified height
        color: rgb(1, 94 / 255, 0), // Orange color (#FF5E00)
        opacity: 1, // Specified opacity
      });
      // Draw the "Score Overview" text
      page2.drawText("Score Overview", {
        x: 119,
        y: page2.getHeight() - 1089 - 75, // Adjusted for top position
        size: 50,
        color: rgb(0, 0, 0), // White color
        fontFamily: "Poppins",
        width: 381,
        height: 75,
        lineHeight: 75,
        fontWeight: 300,
        textAlign: "left",
        opacity: 1,
      });
      // Define domain colors
      const domainColors = {
        Openness: rgb(243 / 255, 112 / 255, 146 / 255), // Openness to Experience
        Conscientiousness: rgb(73 / 255, 202 / 255, 122 / 255), // Conscientiousness
        Extraversion: rgb(51 / 255, 123 / 255, 232 / 255), // Extraversion
        Agreeableness: rgb(243 / 255, 171 / 255, 84 / 255), // Agreeableness
        Neuroticism: rgb(152 / 255, 101 / 255, 207 / 255), // Neuroticism
      };

      // Define domain order
      const domainOrder = [
        "Openness",
        "Conscientiousness",
        "Extraversion",
        "Agreeableness",
        "Neuroticism",
      ];

      // Mapping between full trait names and their corresponding keys in `assessmentData.results`
      const domainMapping = {
        Openness: "O",
        Conscientiousness: "C",
        Extraversion: "E",
        Agreeableness: "A",
        Neuroticism: "N",
      };

      // Starting point for drawing the Score Overview section
      let startX = 350; // Initial X position for trait titles
      let startY = page2.getHeight() - 1300; // Adjust the Y position as needed based on page layout
      const circleRadius = 12; // Radius for the filled and empty circles
      const circleGap = 10; // Gap between circles
      const textSpacing = 50; // Space between the trait title, circles, and score text

      // Draw each trait
      domainOrder.forEach((domainTitle) => {
        const domainKey = domainMapping[domainTitle]; // Map full trait name to corresponding key ('A', 'C', etc.)
        const domainColor = domainColors[domainTitle] || rgb(0, 0, 0);
        const domainData = assessmentData.results[domainKey] || {}; // Use the mapped key to access the score
        console.log("domainData: get from assessment data", domainData);

        const scoreValue = Number(domainData.score) || 0;
        console.log("scoreValue:", scoreValue);

        const scaledScore = Math.round((scoreValue / 120) * 100);
        const filledCircles = Math.floor(scaledScore / 10);
        const emptyCircles = 10 - filledCircles;

        // Draw Trait Title
        page2.drawText(domainTitle, {
          x: startX,
          y: startY,
          size: 31,
          color: domainColor, // Use domainColor
          font: helveticaFont,
        });

        // Draw Circles
        let currentX = startX + 350; // Position for first circle
        for (let i = 0; i < filledCircles; i++) {
          page2.drawCircle({
            x: currentX,
            y: startY + circleRadius / 2,
            size: circleRadius,
            color: domainColor,
            fill: domainColor,
          });
          currentX += circleRadius * 2 + circleGap;
        }
        for (let i = 0; i < emptyCircles; i++) {
          page2.drawCircle({
            x: currentX,
            y: startY + circleRadius / 2,
            size: circleRadius,
            color: rgb(239 / 255, 243 / 255, 246 / 255), // #EFF3F6 color
            fill: rgb(239 / 255, 243 / 255, 246 / 255), // #EFF3F6 color
          });
          currentX += circleRadius * 2 + circleGap;
        }

        // Draw Score
        page2.drawText(`${scaledScore} / 100`, {
          x: currentX + textSpacing,
          y: startY,
          size: 24,
          color: rgb(143 / 255, 143 / 255, 143 / 255), // #8F8F8F color
          font: helveticaFont,
        });

        // Move Y position for the next trait
        startY -= 70; // Adjust spacing between traits
      });
      // Draw the vertical orange rectangle
      page2.drawRectangle({
        x: 115,
        y: page2.getHeight() - 1682 - 82, // Adjusted for top position
        width: 8,
        height: 82,
        color: rgb(1, 94 / 255, 0), // Orange color (#FF5E00)
        opacity: 1,
      });
      // Draw the first line of text
      page2.drawText("Continue reading the report to learn about", {
        x: 145,
        y: page2.getHeight() - 1687 - 24, // Adjusted for top position
        size: 20,
        font: helveticaFont, // Assuming Lexend is not available in PDFKit, using helveticaFont
        color: rgb(160 / 255, 160 / 255, 160 / 255), // #A0A0A0 color
        lineHeight: 24,
        fontWeight: 400,
        textAlign: "left",
        opacity: 1,
        letterSpacing: -0.03,
      });

      // Draw the second line of text
      page2.drawText("each of your scores and our suggested", {
        x: 145,
        y: page2.getHeight() - 1711 - 24, // Adjusted for top position
        size: 20,
        font: helveticaFont, // Assuming Lexend is not available in PDFKit, using helveticaFont
        color: rgb(160 / 255, 160 / 255, 160 / 255), // #A0A0A0 color
        lineHeight: 24,
        fontWeight: 400,
        textAlign: "left",
        opacity: 1,
        letterSpacing: -0.03,
      });

      // Draw the third line of text
      page2.drawText("improvements to your communication.", {
        x: 145,
        y: page2.getHeight() - 1735 - 24, // Adjusted for top position
        size: 20,
        font: helveticaFont, // Assuming Lexend is not available in PDFKit, using helveticaFont
        color: rgb(160 / 255, 160 / 255, 160 / 255), // #A0A0A0 color
        lineHeight: 24,
        fontWeight: 400,
        textAlign: "left",
        opacity: 1,
        letterSpacing: -0.03,
      });

      // Draw the horizontal line with the specified properties
      page2.drawRectangle({
        x: -38,
        y: page2.getHeight() - 1863, // Adjusted for top position
        width: 1606,
        height: 0,
        color: rgb(235 / 255, 237 / 255, 242 / 255), // #EBEDF2 color
        borderWidth: 2,
        borderColor: rgb(235 / 255, 237 / 255, 242 / 255), // #EBEDF2 color
        opacity: 1,
      });
      // Draw the text "Learn more about L.A.R.A at www.altercall.com/aai"
      page2.drawText("Learn more about L.A.R.A at", {
        x: 499,
        y: page2.getHeight() - 1933,
        size: 20,
        color: rgb(0, 0, 0),
        fontFamily: "lexend",
        width: 894,
        height: 41,
        lineHeight: 24,
        fontWeight: 400,
        textAlign: "left",
        opacity: 1,
        letterSpacing: -0.03,
      });

      // Draw the clickable link "www.altercall.com/aai"
      page2.drawText("www.altercall.com/aai", {
        x: 770,
        y: page2.getHeight() - 1933,
        size: 20,
        color: rgb(0, 0, 1),
        fontFamily: "lexend",
        width: 894,
        height: 41,
        lineHeight: 24,
        fontWeight: 400,
        textAlign: "left",
        opacity: 1,
        letterSpacing: -0.03,
        underline: true,
      });

      page2.drawText("02", {
        x: 1437,
        y: page2.getHeight() - 1933,
        size: 18,
        color: rgb(255 / 255, 94 / 255, 0 / 255),
        width: 18,
        height: 19,
        opacity: 1,
      });
      const vectorFooterBlackSvg = `
                            <svg width="199" height="38" viewBox="0 0 199 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M29.2884 26.1026C31.3661 29.7107 33.445 33.322 35.5438 36.9669C34.9377 37.3111 34.3496 37.6457 33.7256 38C31.6742 34.439 29.6306 30.8913 27.6016 27.3685C21.0779 31.1496 14.6015 31.1496 8.06751 27.3691C6.04305 30.8831 3.99873 34.4326 1.94416 37.9994C1.33042 37.6508 0.74102 37.3162 0.125354 36.9669C2.219 33.3302 4.29792 29.7196 6.38195 26.1007C1.80642 22.0664 -0.392292 17.043 0.0574452 10.9712C0.36752 6.78805 2.44771 2.45925 5.18394 0C5.65866 0.492358 6.13403 0.985352 6.60875 1.47835C-0.121935 8.05839 0.99664 18.8648 7.47106 24.2101C10.9165 18.2274 14.3587 12.2492 17.8349 6.213C21.3072 12.2428 24.7494 18.2204 28.1929 24.2006C34.4361 19.1269 35.9698 8.4337 29.0059 1.43509C29.4928 0.965632 29.9803 0.496175 30.4692 0.026081C37.2325 6.12839 38.0948 19.1186 29.2878 26.1026H29.2884ZM9.14637 25.4951C14.663 29.2266 21.9748 28.7075 26.5119 25.4748C23.6309 20.471 20.7486 15.466 17.8349 10.4063C14.9193 15.4692 12.037 20.4748 9.14637 25.4945V25.4951Z" fill="black"/>
                            </svg>
                        `;
      const vectorFooterBlackPng = await svgToPng(vectorFooterBlackSvg);
      const vectorFooterBlackImage = await pdfDoc.embedPng(
        vectorFooterBlackPng
      );

      page2.drawImage(vectorFooterBlackImage, {
        x: 54,
        y: page2.getHeight() - 1933,
        width: 199,
        height: 38,
        opacity: 1,
      });
      //new page for all domains making

      const domainSections = [
        {
          title: "Openness",
          score: assessmentData?.results?.O?.score ?? 0,
          communicationStyle: assessmentData?.communication_style ?? "",
          page: "03", // Page number where this domain appears in the PDF
        },
        {
          title: "Conscientiousness",
          score: assessmentData?.results?.C?.score ?? 0,
          communicationStyle: assessmentData?.communication_style ?? "",
          page: "04",
        },
        {
          title: "Extraversion",
          score: assessmentData?.results?.E?.score ?? 0,
          communicationStyle: assessmentData?.communication_style ?? "",
          page: "05",
        },
        {
          title: "Agreeableness",
          score: assessmentData?.results?.A?.score ?? 0,
          communicationStyle: assessmentData?.communication_style ?? "",
          page: "06",
        },
        {
          title: "Neuroticism",
          score: assessmentData?.results?.N?.score ?? 0,
          communicationStyle: assessmentData?.communication_style ?? "",
          page: "07",
        },
      ];

      const domainData = domainSections.map((section) => {
        const domainKey = domainMapping[section.title];
        const selectedDomain =
          domains.find((domain) => domain.domain === domainKey) || {};
        const domainInfo = {
          title: section.title,
          shortDescription:
            selectedDomain.shortDescription ||
            "No description available for this domain.",
          description:
            selectedDomain.description ||
            "No description available for this result.",
          results: selectedDomain.results || [],
          facets:
            selectedDomain.facets.map((facet, index) => {
              const facetData =
                assessmentData?.results[domainKey]?.facet[index + 1] || {};
              const scoreValue = Number(facetData.score) || 0;
              const countValue = Number(facetData.count) || 1;
              const scaledFacetScore = Math.min(
                Math.max(
                  Math.round(((scoreValue / (countValue * 5)) * 100) / 10),
                  0
                ),
                10
              );
              return {
                title: facet.title,
                text: facet.text,
                score: scaledFacetScore,
              };
            }) || [],
        };
        return domainInfo;
      });

      console.log("domainData:", domainData);
      console.log("domainSections:", domainSections);
      for (const [index, domainSec] of domainSections.entries()) {
        const page = pdfDoc.addPage([1500, 1974]); // Custom size
        // Draw the background for the domain page
        const domainBackgroundSvg = `
                            <svg width="1500" height="650" viewBox="0 0 1500 650" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 0H1500V650H0V0Z" fill="url(#paint0_linear_4906_592)"/>
                            <defs>
                            <linearGradient id="paint0_linear_4906_592" x1="1298.72" y1="67.3009" x2="624.254" y2="1224.85" gradientUnits="userSpaceOnUse">
                            <stop/>
                            <stop offset="1" stop-color="#3480FF"/>
                            </linearGradient>
                            </defs>
                            </svg>
                            `;

        const domainBackgroundPng = await svgToPng(domainBackgroundSvg);
        const domainBackgroundImage = await pdfDoc.embedPng(
          domainBackgroundPng
        );

        page.drawImage(domainBackgroundImage, {
          x: 0,
          y: page.getHeight() - 650,
          width: 1500,
          height: 650,
          opacity: 1,
        });
        // Draw the logo image
        page.drawImage(logoImage3, {
          x: 80,
          y: page.getHeight() - 33 - 44, // Adjusted for top position
          width: 229,
          height: 44,
          opacity: 1, // Adjusted opacity
        });
        //outer container
        const domainContainerSvg = `
                            <svg width="289" height="65" viewBox="0 0 289 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.5" y="0.5" width="288" height="64" rx="32" fill="white" fill-opacity="0.4" stroke="white"/>
                            </svg>
                            `;

        const domainContainerPng = await svgToPng(domainContainerSvg);
        const domainContainerImage = await pdfDoc.embedPng(domainContainerPng);

        page.drawImage(domainContainerImage, {
          x: 1138,
          y: page.getHeight() - 22 - 65,
          width: 289,
          height: 65,
          opacity: 1,
        });
        //inner container
        const domainInnerContainerSvg = `
                            <svg width="281" height="57" viewBox="0 0 281 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.5" y="0.5" width="280" height="56" rx="28" fill="white" stroke="white"/>
                            </svg>
                            `;

        const domainInnerContainerPng = await svgToPng(domainInnerContainerSvg);
        const domainInnerContainerImage = await pdfDoc.embedPng(
          domainInnerContainerPng
        );

        page.drawImage(domainInnerContainerImage, {
          x: 1142,
          y: page.getHeight() - 26 - 57,
          width: 281,
          height: 57,
          opacity: 1,
        });
        try {
          const response = await fetch(clientAvatarUrl);
          if (!response.ok) {
            throw new Error("Failed to fetch client avatar.");
          }
          const avatarImageBytes = await response.arrayBuffer();
          const avatarImage = await pdfDoc.embedPng(avatarImageBytes);
          page.drawImage(avatarImage, {
            x: 1152,
            y: page.getHeight() - 32 - 45, // Adjusted for top position
            width: 45,
            height: 45,
            opacity: 1, // Adjusted opacity
          });
        } catch (error) {
          console.error("Error fetching or embedding avatar image:", error);
          // Use the imported default avatar directly if fetching fails
          const defaultAvatarBytes = await fetch(avatar).then((res) =>
            res.arrayBuffer()
          );
          const defaultAvatarImage = await pdfDoc.embedPng(defaultAvatarBytes);
          page.drawImage(defaultAvatarImage, {
            x: 1152,
            y: page.getHeight() - 32 - 45, // Adjusted for top position
            width: 45,
            height: 45,
            opacity: 1, // Adjusted opacity
          });
        }

        // Draw client name
        page.drawText(`${name}`, {
          x: 1210, // Adjusted to match the left position
          y: page.getHeight() - 36 - 30, // Adjusted for top position
          size: 24,
          font: helveticaFont, // Assuming Lexend is not available in PDFKit, using helveticaFont
          color: rgb(0, 0, 0),
          width: 170, // Adjusted to match the width
          height: 30, // Adjusted to match the height
          lineHeight: 30,
          fontWeight: 300,
          textAlign: "left",
          opacity: 1, // Adjusted to match the opacity
        });

        // Draw the domain title
        page.drawText(domainSec.title, {
          x: 425,
          y: page.getHeight() - 140,
          size: 67,
          color: rgb(1, 1, 1),
          fontFamily: "Poppins",
          fontWeight: 300,
          lineHeight: 75,
        });

        // Draw the domain score
        const domainScore = Number(domainSec.score) || 0;
        console.log("domainScore:", domainScore);
        let domainscore1 = Math.round((domainScore / 120) * 100);
        let colorPie = domainColors[domainSec.title];
        console.log("colorPie:", colorPie);

        // Ensure colorPie is a valid RGB object
        let colorPieHex = "000000"; // Default to black if invalid
        if (colorPie.type === "RGB") {
          const r = Math.round(colorPie.red * 255);
          const g = Math.round(colorPie.green * 255);
          const b = Math.round(colorPie.blue * 255);
          colorPieHex = [r, g, b]
            .map((x) => x.toString(16).padStart(2, "0"))
            .join("");
        }
        console.log("colorPieHex:", colorPieHex);

        // Draw the pie circle with domain score
        const pieCircleSvg = `
                            <svg width="191" height="191" viewBox="0 0 191 191" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="95.1235" cy="95.1235" r="95.1235" fill="#${colorPieHex}" />
                                <text x="50%" y="50%" text-anchor="middle" fill="white" font-size="88" font-family="Poppins" font-weight="300" dy=".3em">${domainscore1}</text>
                            </svg>`;

        const pieCirclePng = await svgToPng(pieCircleSvg);
        const pieCircleImage = await pdfDoc.embedPng(pieCirclePng);

        page.drawImage(pieCircleImage, {
          x: 79,
          y: page.getHeight() - 151 - 190.25,
          width: 191,
          height: 191,
          opacity: 1,
        });
        //about your score text
        page.drawText("Your Category Score", {
          x: 75,
          y: page.getHeight() - 366 - 41, // Adjusted for top position
          size: 20,
          color: rgb(1, 1, 1), // White color
          font: helveticaFont, // Assuming Lexend is not available in PDFKit, using helveticaFont
          width: 225,
          height: 41,
          lineHeight: 24,
          fontWeight: 700,
          textAlign: "center",
          opacity: 1,
          letterSpacing: -0.03,
        });
        // Draw the short description
        const shortDescriptionLines = wrapText(
          domainData[index].shortDescription,
          920,
          32,
          helveticaFont
        );
        let shortDescriptionY = page.getHeight() - 225;

        shortDescriptionLines.forEach((line, lineIndex) => {
          page.drawText(line, {
            x: 425,
            y: shortDescriptionY - lineIndex * 42,
            size: 32,
            color: rgb(1, 1, 1),
            fontWeight: 300,
            fontFamily: "lexend",
            lineHeight: 42,
            letterSpacing: -0.03,
            textAlign: "left",
            opacity: 1,
          });
        });
        //about you text
        // Draw the "About You" text
        page.drawText("ABOUT YOU", {
          x: 425,
          y: page.getHeight() - 375,
          size: 26,
          color: rgb(1, 1, 1), // White color
          fontFamily: "Lexend",
          width: 563.5,
          height: 18,
          lineHeight: 39,
          fontWeight: 700,
          textAlign: "left",
          opacity: 1,
          letterSpacing: 2,
        });
        // Draw the vertical orange rectangle
        page.drawRectangle({
          x: 430.58,
          y: page.getHeight() - 407 - 187, // Adjusted for top position
          width: 8.93,
          height: 187,
          color: rgb(1, 94 / 255, 0), // Orange color (#FF5E00)
          opacity: 1,
        });

        //draw result low text
        // Determine the score category and corresponding text
        let resultText = "";
        if (domainscore1 >= 0 && domainscore1 <= 40) {
          resultText =
            domainData[index].results.find((result) => result.score === "low")
              ?.text || "";
        } else if (domainscore1 > 40 && domainscore1 <= 70) {
          resultText =
            domainData[index].results.find(
              (result) => result.score === "neutral"
            )?.text || "";
        } else if (domainscore1 > 70 && domainscore1 <= 100) {
          resultText =
            domainData[index].results.find((result) => result.score === "high")
              ?.text || "";
        }

        // Remove newline characters from resultText
        resultText = resultText.replace(/\n/g, " ");

        // Draw the result text
        const resultTextLines = wrapText(resultText, 946.24, 20, helveticaFont);
        let resultTextY = page.getHeight() - 490;

        resultTextLines.forEach((line, lineIndex) => {
          page.drawText(line, {
            x: 461.82,
            y: resultTextY - lineIndex * 28,
            size: 20,
            color: rgb(1, 1, 1),
            fontFamily: "lexend",
            lineHeight: 28,
            fontWeight: 400,
            letterSpacing: -0.03,
            textAlign: "left",
            opacity: 1,
          });
        });
        // Draw the communication style
        // const communicationStyleLines = wrapText(`Communication Style: ${domainSec.communicationStyle}`, 946.24, 20, helveticaFont);
        // let communicationStyleY = page.getHeight() - 476;

        // communicationStyleLines.forEach((line, lineIndex) => {
        //     page.drawText(line, {
        //         x: 461.82,
        //         y: communicationStyleY - (lineIndex * 28),
        //         size: 20,
        //         color: rgb(1, 1, 1),
        //         fontFamily: "lexend",
        //         lineHeight: 28,
        //         fontWeight: 400,
        //         letterSpacing: -0.03,
        //         textAlign: 'left',
        //         opacity: 1,
        //     });
        // });
        // Draw the horizontal orange rectangle
        page.drawRectangle({
          x: 0,
          y: page.getHeight() - 646 - 16, // Adjusted for top position
          width: 1500, // Specified width
          height: 16, // Specified height
          color: rgb(1, 94 / 255, 0), // Orange color (#FF5E00)
          opacity: 1, // Specified opacity
        });

        // Draw the facets
        let facetY = page.getHeight() - 720; // Starting y position for the first facet
        const maxY = page.getHeight() - 1600; // Maximum y position for the facets
        const gapBetweenFacets =
          (facetY - maxY) / (domainData[index].facets.length - 1); // Calculate the gap between facets

        domainData[index].facets.forEach((facet, facetIndex) => {
          // Draw the facet title
          page.drawText(`${facet.title.toUpperCase()}`, {
            x: 403, // Adjusted X position based on CSS (left: 78px)
            y: facetY,
            size: 26, // Font size based on CSS
            color: domainColors[domainSec.title],
            fontWeight: 700, // Bold
            fontFamily: "lexend",
            lineHeight: 39, // Line height from your CSS
            letterSpacing: 2, // Letter spacing from your CSS
            textAlign: "left",
            opacity: 1,
            width: 693, // Width from CSS (width: 693px)
            height: 18,
          });

          // Draw the circles representing the facet score
          let newscore = facet.score * 10;
          const filledCircles = Math.floor(newscore / 10);
          const emptyCircles = 10 - filledCircles;
          const circleRadius = 12; // Radius for circle
          const circleGap = 10; // Gap between circles
          let currentX = 827; // Adjust X position based on CSS (left: 627px)

          // Filled circles
          for (let i = 0; i < filledCircles; i++) {
            page.drawCircle({
              x: currentX,
              y: facetY,
              size: circleRadius,
              color: domainColors[domainSec.title], // Use domain color
              fill: domainColors[domainSec.title],
            });
            currentX += circleRadius * 2 + circleGap;
          }

          // Empty circles
          for (let i = 0; i < emptyCircles; i++) {
            page.drawCircle({
              x: currentX,
              y: facetY,
              size: circleRadius,
              color: rgb(239 / 255, 243 / 255, 246 / 255),
              fill: rgb(239 / 255, 243 / 255, 246 / 255),
            });
            currentX += circleRadius * 2 + circleGap;
          }

          // Draw the facet score
          page.drawText(`score: ${newscore}/100`, {
            x: 1300, // Positioned based on your layout (left: 1002px)
            y: facetY,
            size: 18, // Font size
            color: rgb(0, 0, 0),
            font: helveticaFont,
            width: 114,
            height: 26,
            opacity: 1,
          });

          // Draw the facet text
          const facetTextLines = wrapText(
            facet.text.replace(/\n/g, " "),
            1040,
            16,
            helveticaFont
          );
          let facetTextY = facetY - 60; // Adjust the Y position for the text
          facetTextLines.forEach((line, lineIndex) => {
            page.drawText(line, {
              x: 410, // Adjusted X position based on CSS (left: 78px)
              y: facetTextY - lineIndex * 22, // Adjusted line spacing
              size: 16,
              color: rgb(0, 0, 0),
              font: helveticaFont,
              fontFamily: "Lexend",
              fontWeight: 400,
              lineHeight: 22,
              letterSpacing: -0.03,
              textAlign: "left",
              opacity: 1,
            });
          });

          // Draw the horizontal line with the specified properties if not the last iteration
          if (facetIndex < domainData[index].facets.length - 1) {
            page.drawRectangle({
              x: 340,
              y: facetTextY - facetTextLines.length * 22 - 20, // Adjusted for top position
              width: 1160,
              height: 7,
              color: rgb(239 / 255, 243 / 255, 246 / 255), // #EFF3F6 color
              opacity: 1,
            });

            // Update the y position for the next facet, adding 50 units after the line
            facetY = facetTextY - facetTextLines.length * 22 - 20 - 50;
          } else {
            // Update the y position for the next facet without adding the line
            facetY -= gapBetweenFacets;
          }
        });
        // Draw a vertical line with the specified properties
        page.drawRectangle({
          x: 27,
          y: page.getHeight() - 739,
          width: 8,
          height: 44,
          color: rgb(255 / 255, 94 / 255, 0 / 255),
          opacity: 1,
        });

        // Draw the "Key Scoring Criteria" title
        page.drawText("Key Scoring Criteria:", {
          x: 55,
          y: page.getHeight() - 728,
          size: 20,
          font: helveticaFont,
          color: rgb(160 / 255, 160 / 255, 160 / 255), // Changed to #A0A0A0
          width: 225,
          height: 24,
          lineHeight: 24,
          fontWeight: 700,
          textAlign: "left",
          opacity: 1,
          letterSpacing: -0.03,
        });
        // Draw the content text
        const contentText =
          "Measures creativity, curiosity, and openness to new experiences.";
        const highText = "• High: Imaginative, open-minded, enjoys novelty.";
        const lowText =
          "• Low: Prefers routine, practical, less open to change.";

        // Draw the content text
        const contentFontSize = 20;
        const contentMaxWidth = 225;
        const contentLines = wrapText(
          contentText,
          contentMaxWidth,
          contentFontSize,
          helveticaFont
        );

        let contentCurrentY = page.getHeight() - 776; // Adjusted top position according to layout
        contentLines.forEach((line, index) => {
          page.drawText(line, {
            x: 55, // Adjusted X position based on layout
            y: contentCurrentY - index * contentFontSize * 1.2,
            size: contentFontSize,
            font: helveticaFont,
            color: rgb(160 / 255, 160 / 255, 160 / 255), // #A0A0A0 color
            width: contentMaxWidth,
            height: 24,
            lineHeight: 24,
            fontWeight: 700,
            textAlign: "left",
            opacity: 1,
            letterSpacing: -0.03,
          });
        });

        // Draw the high text
        const highFontSize = 20;
        const highMaxWidth = 225;
        const highLines = wrapText(
          highText,
          highMaxWidth,
          highFontSize,
          helveticaFont
        );

        let highCurrentY =
          contentCurrentY - contentLines.length * contentFontSize * 1.2 - 20; // Adjusted top position according to layout
        highLines.forEach((line, index) => {
          page.drawText(line, {
            x: 55, // Adjusted X position based on layout
            y: highCurrentY - index * highFontSize * 1.2,
            size: highFontSize,
            font: helveticaFont,
            color: rgb(160 / 255, 160 / 255, 160 / 255), // #A0A0A0 color
            width: highMaxWidth,
            height: 24,
            lineHeight: 24,
            fontWeight: 700,
            textAlign: "left",
            opacity: 1,
            letterSpacing: -0.03,
          });
        });

        // Draw the low text
        const lowFontSize = 20;
        const lowMaxWidth = 225;
        const lowLines = wrapText(
          lowText,
          lowMaxWidth,
          lowFontSize,
          helveticaFont
        );

        let lowCurrentY =
          highCurrentY - highLines.length * highFontSize * 1.2 - 20; // Adjusted top position according to layout
        lowLines.forEach((line, index) => {
          page.drawText(line, {
            x: 55, // Adjusted X position based on layout
            y: lowCurrentY - index * lowFontSize * 1.2,
            size: lowFontSize,
            font: helveticaFont,
            color: rgb(160 / 255, 160 / 255, 160 / 255), // #A0A0A0 color
            width: lowMaxWidth,
            height: 24,
            lineHeight: 24,
            fontWeight: 700,
            textAlign: "left",
            opacity: 1,
            letterSpacing: -0.03,
          });
        });

        // Draw the horizontal line with the specified properties
        page.drawRectangle({
          x: 340,
          y: page.getHeight() - 1863, // Adjusted for top position
          width: 1200,
          height: 0,
          color: rgb(235 / 255, 237 / 255, 242 / 255), // #EBEDF2 color
          borderWidth: 2,
          borderColor: rgb(235 / 255, 237 / 255, 242 / 255), // #EBEDF2 color
          opacity: 1,
          rotate: degrees(90),
        });
        // Draw the verticle line with the specified properties
        page.drawRectangle({
          x: -38,
          y: page.getHeight() - 1863, // Adjusted for top position
          width: 1606,
          height: 0,
          color: rgb(235 / 255, 237 / 255, 242 / 255), // #EBEDF2 color
          borderWidth: 2,
          borderColor: rgb(235 / 255, 237 / 255, 242 / 255), // #EBEDF2 color
          opacity: 1,
        });
        // Draw the text "Learn more about L.A.R.A at www.altercall.com/aai"
        page.drawText("Learn more about L.A.R.A at", {
          x: 499,
          y: page.getHeight() - 1933,
          size: 20,
          color: rgb(0, 0, 0),
          fontFamily: "lexend",
          width: 894,
          height: 41,
          lineHeight: 24,
          fontWeight: 400,
          textAlign: "left",
          opacity: 1,
          letterSpacing: -0.03,
        });

        // Draw the clickable link "www.altercall.com/aai"
        page.drawText("www.altercall.com/aai", {
          x: 770,
          y: page.getHeight() - 1933,
          size: 20,
          color: rgb(0, 0, 1),
          fontFamily: "lexend",
          width: 894,
          height: 41,
          lineHeight: 24,
          fontWeight: 400,
          textAlign: "left",
          opacity: 1,
          letterSpacing: -0.03,
          underline: true,
        });

        page.drawImage(vectorFooterBlackImage, {
          x: 54,
          y: page.getHeight() - 1933,
          width: 199,
          height: 38,
          opacity: 1,
        });

        // Draw the page number
        page.drawText(domainSec.page, {
          x: 1400,
          y: 50,
          size: 18,
          color: rgb(1, 94 / 255, 0), // Orange color (#FF5E00)
          font: helveticaFont,
        });
      }

      //new page
      const page3 = pdfDoc.addPage([1500, 1974]); // Custom size
      // Draw the background for the new page
      const page3BackgroundSvg = `
                    <svg width="1500" height="106" viewBox="0 0 1500 106" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 0H1500V106H0V0Z" fill="url(#paint0_linear_4572_1028)"/>
                    <defs>
                    <linearGradient id="paint0_linear_4572_1028" x1="1298.72" y1="10.9752" x2="1274.91" y2="261.569" gradientUnits="userSpaceOnUse">
                    <stop/>
                    <stop offset="1" stop-color="#3480FF"/>
                    </linearGradient>
                    </defs>
                    </svg>
                    `;

      const page3BackgroundPng = await svgToPng(page3BackgroundSvg);
      const page3BackgroundImage = await pdfDoc.embedPng(page3BackgroundPng);

      page3.drawImage(page3BackgroundImage, {
        x: 0,
        y: page3.getHeight() - 106,
        width: 1500,
        height: 106,
        opacity: 1,
      });

      // Draw the logo image on the new page
      page3.drawImage(logoImage3, {
        x: 80,
        y: page3.getHeight() - 33 - 44,
        width: 229,
        height: 44,
        opacity: 1,
      });
      // Draw the text "how we" with specified styles
      page3.drawText("Your", {
        x: 121,
        y: page3.getHeight() - 210 - 75, // Adjusted for top position and line height
        size: 67,
        color: rgb(0, 0, 0),
        width: 277,
        height: 150,
        lineHeight: 75,
        fontWeight: 300,
        textAlign: "left",
        opacity: 1,
        fontFamily: "Poppins",
      });

      // Draw the text "Score" with specified styles
      page3.drawText("Traits", {
        x: 121,
        y: page3.getHeight() - 210 - 150, // Adjusted for top position and height
        size: 67,
        color: rgb(1, 94 / 255, 0),
        width: 277,
        height: 150,
        lineHeight: 75,
        fontWeight: 300,
        textAlign: "left",
        opacity: 1,
        fontFamily: "Poppins",
      });

      // // Define the text content
      const text1 =
        "This page offers a detailed explanation of these sub-scores, shedding light on how they shape your behaviors, preferences, & interactions in various settings:";
      const text2 =
        " Openness, Conscientiousness, Extraversion, Agreeableness, and Neuroticism.";

      // Define the layout properties
      const layout = {
        width: 913, // Total width for the text block
        height: 168, // Height of the block
        top: 250, // Starting position from the top
        left: 482, // Left margin for alignment
        gap: 0, // No gap between text1 and text2
        opacity: 1, // Text opacity
        fontFamily: "Lexend", // Font family
        fontSize: 32, // Font size for both texts
        fontWeight: 300, // Regular font weight for text1
        lineHeight: 42, // Line height for both texts
        letterSpacing: -0.03, // Letter spacing for both texts
        textAlign: "left", // Left aligned
      };

      // Wrap the text using the wrapText function
      const text1Lines = wrapText(
        text1,
        layout.width,
        layout.fontSize,
        helveticaFont
      );
      const text2Lines = wrapText(
        text2,
        layout.width,
        layout.fontSize,
        helveticaFont
      );

      // Draw text1 with black color
      let text1Y = page3.getHeight() - layout.top;
      text1Lines.forEach((line, index) => {
        page3.drawText(line, {
          x: layout.left,
          y: text1Y - index * layout.lineHeight,
          size: layout.fontSize,
          font: helveticaFont,
          color: rgb(0, 0, 0), // Black color for text1
          lineHeight: layout.lineHeight,
          fontWeight: layout.fontWeight, // Regular weight
          textAlign: layout.textAlign,
          opacity: layout.opacity,
          letterSpacing: layout.letterSpacing,
        });
      });

      // Get the width of the last line of text1
      const lastLine = text1Lines[text1Lines.length - 1];
      let lastLineWidth = helveticaFont.widthOfTextAtSize(
        lastLine,
        layout.fontSize
      ); // Width of the last line of text1

      // Calculate how much of text2 can fit on the same line
      let remainingWidth = layout.width - lastLineWidth; // Remaining width on the last line of text1
      let currentText = ""; // To store the portion of text2 that fits
      let index = 0; // Index for characters in text2

      // Loop to calculate the portion of text2 that fits on the same line
      while (
        index < text2.length &&
        helveticaFont.widthOfTextAtSize(
          currentText + text2[index],
          layout.fontSize
        ) < remainingWidth
      ) {
        currentText += text2[index];
        index++;
      }

      // Draw the portion of text2 that fits on the same line
      if (currentText) {
        page3.drawText(currentText, {
          x: layout.left + lastLineWidth,
          y: text1Y - (text1Lines.length - 1) * layout.lineHeight, // Same line as the last line of text1
          size: layout.fontSize,
          font: helveticaFont,
          color: rgb(0, 118 / 255, 186 / 255), // Blue color for text2
          lineHeight: layout.lineHeight,
          fontWeight: 700, // Bold font for text2
          textAlign: layout.textAlign,
          opacity: layout.opacity,
          letterSpacing: layout.letterSpacing,
        });
        text2Lines.shift(); // Remove the first line as it is already drawn
      }

      // Draw the remaining part of text2 on the next line with consistent line height
      let remainingText = text2.substring(index); // Get the remaining part of text2 that doesn't fit

      if (remainingText) {
        page3.drawText(remainingText, {
          x: layout.left, // Start from the left
          y: text1Y - text1Lines.length * layout.lineHeight, // Same spacing as other lines
          size: layout.fontSize,
          font: helveticaFont,
          color: rgb(0, 118 / 255, 186 / 255), // Blue color for text2
          lineHeight: layout.lineHeight,
          fontWeight: 700, // Bold font for text2
          textAlign: layout.textAlign,
          opacity: layout.opacity,
          letterSpacing: layout.letterSpacing,
        });
      }
      // Draw the horizontal line with the specified properties
      page3.drawRectangle({
        x: -38,
        y: page3.getHeight() - 499, // Adjusted for top position
        width: 1606,
        height: 0,
        color: rgb(235 / 255, 237 / 255, 242 / 255), // #EBEDF2 color
        borderWidth: 2,
        borderColor: rgb(235 / 255, 237 / 255, 242 / 255), // #EBEDF2 color
        opacity: 1,
      });
      // Draw the vertical line with the specified properties
      page3.drawRectangle({
        x: 354,
        y: page3.getHeight() - 1863,
        width: 1364,
        height: 0,
        color: rgb(235 / 255, 237 / 255, 242 / 255), // #EBEDF2 color
        borderWidth: 2,
        borderColor: rgb(235 / 255, 237 / 255, 242 / 255), // #EBEDF2 color
        opacity: 1,
        rotate: degrees(90),
      });
      // Draw the horizontal line with the specified properties
      page3.drawRectangle({
        x: -38,
        y: page3.getHeight() - 1863, // Adjusted for top position
        width: 1606,
        height: 0,
        color: rgb(235 / 255, 237 / 255, 242 / 255), // #EBEDF2 color
        borderWidth: 2,
        borderColor: rgb(235 / 255, 237 / 255, 242 / 255), // #EBEDF2 color
        opacity: 1,
      });
      page3.drawText("Openness", {
        x: 149,
        y: page3.getHeight() - 552,
        size: 26,
        color: rgb(86 / 255, 143 / 255, 243 / 255),
        width: 161,
        height: 39,
        lineHeight: 28,
        fontWeight: 700,
        textAlign: "left",
        opacity: 1,
        fontFamily: "Lexend",
        letterSpacing: 2,
      });
      // Define the text content
      const opennessContent =
        "Open people are intellectually curious, appreciative of art, and sensitive to beauty. They tend to be, compared to closed people, more aware of their feelings. They tend to think and act in individualistic and nonconforming ways. Intellectuals typically score high on Openness to Experience; consequently, this factor has also been called Culture or Intellect.";

      // Define the layout properties
      const opennessLayout = {
        width: 1022,
        height: 112,
        top: 550,
        left: 402,
        gap: 0,
        opacity: 1,
        fontFamily: "Lexend",
        fontSize: 20,
        fontWeight: 400,
        lineHeight: 28,
        letterSpacing: -0.03,
        textAlign: "left",
        color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
      };

      // Wrap the text using the wrapText function
      const opennessLines = wrapText(
        opennessContent,
        opennessLayout.width,
        opennessLayout.fontSize,
        helveticaFont
      );

      // Draw the text with specified styles
      let opennessY = page3.getHeight() - opennessLayout.top;
      opennessLines.forEach((line, index) => {
        page3.drawText(line, {
          x: opennessLayout.left,
          y: opennessY - index * opennessLayout.lineHeight,
          size: opennessLayout.fontSize,
          font: helveticaFont,
          color: opennessLayout.color,
          lineHeight: opennessLayout.lineHeight,
          fontWeight: opennessLayout.fontWeight,
          textAlign: opennessLayout.textAlign,
          opacity: opennessLayout.opacity,
          letterSpacing: opennessLayout.letterSpacing,
        });
      });
      page3.drawText("Conscientiousness", {
        x: 115,
        y: page3.getHeight() - 819,
        size: 26,
        color: rgb(86 / 255, 143 / 255, 243 / 255),
        width: 175,
        height: 78,
        lineHeight: 39,
        fontWeight: 700,
        textAlign: "right",
        opacity: 1,
        fontFamily: "Lexend",
        letterSpacing: 2,
      });
      // Define the text content
      const conscientiousnessContent =
        "Conscientiousness concerns the way in which we control, regulate, and direct our impulses. Impulses are not inherently bad; occasionally time constraints require a snap decision, and acting on our first impulse can be an effective response. Also, in times of play rather than work, acting spontaneously and impulsively can be fun. Impulsive individuals can be seen by others as colorful, fun-to-be-with, and zany.";

      // Define the layout properties
      const conscientiousnessLayout = {
        width: 1022,
        height: 112,
        top: 819,
        left: 402,
        gap: 0,
        opacity: 1,
        fontFamily: "Lexend",
        fontSize: 20,
        fontWeight: 400,
        lineHeight: 28,
        letterSpacing: -0.03,
        textAlign: "left",
        color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
      };

      // Wrap the text using the wrapText function
      const conscientiousnessLines = wrapText(
        conscientiousnessContent,
        conscientiousnessLayout.width,
        conscientiousnessLayout.fontSize,
        helveticaFont
      );

      // Draw the text with specified styles
      let conscientiousnessY = page3.getHeight() - conscientiousnessLayout.top;
      conscientiousnessLines.forEach((line, index) => {
        page3.drawText(line, {
          x: conscientiousnessLayout.left,
          y: conscientiousnessY - index * conscientiousnessLayout.lineHeight,
          size: conscientiousnessLayout.fontSize,
          font: helveticaFont,
          color: conscientiousnessLayout.color,
          lineHeight: conscientiousnessLayout.lineHeight,
          fontWeight: conscientiousnessLayout.fontWeight,
          textAlign: conscientiousnessLayout.textAlign,
          opacity: conscientiousnessLayout.opacity,
          letterSpacing: conscientiousnessLayout.letterSpacing,
        });
      });

      page3.drawText("Extraversion", {
        x: 140,
        y: page3.getHeight() - 1090,
        size: 26,
        color: rgb(86 / 255, 143 / 255, 243 / 255),
        width: 235,
        height: 39,
        lineHeight: 39,
        fontWeight: 700,
        textAlign: "left",
        opacity: 1,
        fontFamily: "Lexend",
        letterSpacing: 2,
      });

      // Define the text content
      const extraversionContent =
        "Extraversion is marked by pronounced engagement with the external world. Extraverts enjoy being with people, are full of energy, and often experience positive emotions. They tend to be enthusiastic, action-oriented, individuals who are likely to say 'Yes!' or 'Let's go!' to opportunities for excitement. In groups they like to talk, assert themselves, and draw attention to themselves.";

      // Define the layout properties
      const extraversionLayout = {
        width: 1022,
        height: 112,
        top: 1088,
        left: 402,
        gap: 0,
        opacity: 1,
        fontFamily: "Lexend",
        fontSize: 20,
        fontWeight: 400,
        lineHeight: 28,
        letterSpacing: -0.03,
        textAlign: "left",
        color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
      };

      // Wrap the text using the wrapText function
      const extraversionLines = wrapText(
        extraversionContent,
        extraversionLayout.width,
        extraversionLayout.fontSize,
        helveticaFont
      );

      // Draw the text with specified styles
      let extraversionY = page3.getHeight() - extraversionLayout.top;
      extraversionLines.forEach((line, index) => {
        page3.drawText(line, {
          x: extraversionLayout.left,
          y: extraversionY - index * extraversionLayout.lineHeight,
          size: extraversionLayout.fontSize,
          font: helveticaFont,
          color: extraversionLayout.color,
          lineHeight: extraversionLayout.lineHeight,
          fontWeight: extraversionLayout.fontWeight,
          textAlign: extraversionLayout.textAlign,
          opacity: extraversionLayout.opacity,
          letterSpacing: extraversionLayout.letterSpacing,
        });
      });
      page3.drawText("Agreeableness", {
        x: 140,
        y: page3.getHeight() - 1359,
        size: 26,
        color: rgb(86 / 255, 143 / 255, 243 / 255),
        width: 254,
        height: 39,
        lineHeight: 39,
        fontWeight: 700,
        textAlign: "left",
        opacity: 1,
        fontFamily: "Lexend",
        letterSpacing: 2,
      });
      // Define the text content
      const agreeablenessContent =
        "Agreeableness reflects individual differences in concern with cooperation and social harmony. Agreeable individuals value getting along with others. `They are therefore considerate, friendly, generous, helpful, and willing to compromise their interests with others'. Agreeable people also have an optimistic view of human nature. They believe people are basically honest, decent, and trustworthy.";

      // Define the layout properties
      const agreeablenessLayout = {
        width: 1022,
        height: 140,
        top: 1357,
        left: 402,
        gap: 0,
        opacity: 1,
        fontFamily: "Lexend",
        fontSize: 20,
        fontWeight: 400,
        lineHeight: 28,
        letterSpacing: -0.03,
        textAlign: "left",
        color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
      };

      // Wrap the text using the wrapText function
      const agreeablenessLines = wrapText(
        agreeablenessContent,
        agreeablenessLayout.width,
        agreeablenessLayout.fontSize,
        helveticaFont
      );

      // Draw the text with specified styles
      let agreeablenessY = page3.getHeight() - agreeablenessLayout.top;
      agreeablenessLines.forEach((line, index) => {
        page3.drawText(line, {
          x: agreeablenessLayout.left,
          y: agreeablenessY - index * agreeablenessLayout.lineHeight,
          size: agreeablenessLayout.fontSize,
          font: helveticaFont,
          color: agreeablenessLayout.color,
          lineHeight: agreeablenessLayout.lineHeight,
          fontWeight: agreeablenessLayout.fontWeight,
          textAlign: agreeablenessLayout.textAlign,
          opacity: agreeablenessLayout.opacity,
          letterSpacing: agreeablenessLayout.letterSpacing,
        });
      });
      page3.drawText("Neuroticism", {
        x: 140,
        y: page3.getHeight() - 1628,
        size: 26,
        color: rgb(86 / 255, 143 / 255, 243 / 255),
        width: 219,
        height: 39,
        lineHeight: 39,
        fontWeight: 700,
        textAlign: "left",
        opacity: 1,
        fontFamily: "Lexend",
        letterSpacing: 2,
      });
      // Define the text content
      const neuroticismContent =
        "Neuroticism refers to the tendency to experience negative feelings. Freud originally used the term neurosis to describe a condition marked by mental distress, emotional suffering, and an inability to cope effectively with the normal demands of life. He suggested that everyone shows some signs of neurosis, but that we differ in our degree of suffering and our specific symptoms of distress. Today neuroticism refers to the tendency to experience negative feelings.";

      // Define the layout properties
      const neuroticismLayout = {
        width: 1022,
        height: 168,
        top: 1626,
        left: 402,
        gap: 0,
        opacity: 1,
        fontFamily: "Lexend",
        fontSize: 20,
        fontWeight: 400,
        lineHeight: 28,
        letterSpacing: -0.03,
        textAlign: "left",
        color: rgb(12 / 255, 12 / 255, 12 / 255), // #0C0C0C color
      };

      // Wrap the text using the wrapText function
      const neuroticismLines = wrapText(
        neuroticismContent,
        neuroticismLayout.width,
        neuroticismLayout.fontSize,
        helveticaFont
      );

      // Draw the text with specified styles
      let neuroticismY = page3.getHeight() - neuroticismLayout.top;
      neuroticismLines.forEach((line, index) => {
        page3.drawText(line, {
          x: neuroticismLayout.left,
          y: neuroticismY - index * neuroticismLayout.lineHeight,
          size: neuroticismLayout.fontSize,
          font: helveticaFont,
          color: neuroticismLayout.color,
          lineHeight: neuroticismLayout.lineHeight,
          fontWeight: neuroticismLayout.fontWeight,
          textAlign: neuroticismLayout.textAlign,
          opacity: neuroticismLayout.opacity,
          letterSpacing: neuroticismLayout.letterSpacing,
        });
      });
      page3.drawText("Learn more about L.A.R.A at", {
        x: 499,
        y: page3.getHeight() - 1933,
        size: 20,
        color: rgb(0, 0, 0),
        fontFamily: "lexend",
        width: 894,
        height: 41,
        lineHeight: 24,
        fontWeight: 400,
        textAlign: "left",
        opacity: 1,
        letterSpacing: -0.03,
      });

      // Draw the clickable link "www.altercall.com/aai"
      page3.drawText("www.altercall.com/aai", {
        x: 770,
        y: page3.getHeight() - 1933,
        size: 20,
        color: rgb(0, 0, 1),
        fontFamily: "lexend",
        width: 894,
        height: 41,
        lineHeight: 24,
        fontWeight: 400,
        textAlign: "left",
        opacity: 1,
        letterSpacing: -0.03,
        underline: true,
      });

      page3.drawImage(vectorFooterBlackImage, {
        x: 54,
        y: page3.getHeight() - 1933,
        width: 199,
        height: 38,
        opacity: 1,
      });

      // Draw the page number
      page3.drawText("08", {
        x: 1400,
        y: 50,
        size: 18,
        color: rgb(1, 94 / 255, 0), // Orange color (#FF5E00)
        font: helveticaFont,
      });
      //new page
      const page4 = pdfDoc.addPage([1500, 1974]); // Custom size
      page4.drawRectangle({
        x: 0,
        y: 0,
        width: page4.getWidth(),
        height: page4.getHeight(),
        color: rgb(0, 0, 0),
        opacity: 1,
      });

      // Draw the gradient overlay
      const gradientSvg = `
                    <svg width="1500" height="1974" viewBox="0 0 1500 1974" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 0H1500V1974H0V0Z" fill="url(#paint0_linear_4460_2602)"/>
                      <defs>
                        <linearGradient id="paint0_linear_4460_2602" x1="631.5" y1="1858.5" x2="2361.99" y2="710.973" gradientUnits="userSpaceOnUse">
                          <stop/>
                          <stop offset="1" stop-color="#3480FF"/>
                        </linearGradient>
                      </defs>
                    </svg>
                  `;

      const gradientPng = await svgToPng(gradientSvg);
      const gradientImage = await pdfDoc.embedPng(gradientPng);

      page4.drawImage(gradientImage, {
        x: 0,
        y: 0,
        width: page4.getWidth(),
        height: page4.getHeight(),
      });

      // const aaiLogoImageBytes = await fetch(aaiLogoVerticalBlack).then((res) => res.arrayBuffer());
      // const aaiLogoImage = await pdfDoc.embedPng(aaiLogoImageBytes);
      // Embed the altercall.ai logo on page 5
      const logoSvg = `
                  <svg width="276" height="156" viewBox="0 0 276 156" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M166.664 65.6049C171.849 74.6734 177.038 83.7497 182.275 92.9114C180.762 93.7765 179.295 94.6178 177.737 95.5076C172.618 86.5573 167.518 77.6407 162.454 68.7871C146.173 78.2908 130.011 78.2908 113.703 68.7882C108.651 77.6204 103.549 86.5415 98.4221 95.5053C96.8901 94.6291 95.4199 93.7877 93.8833 92.9103C99.1083 83.7699 104.296 74.6947 109.498 65.5993C98.0791 55.4612 92.592 42.8349 93.7135 27.5742C94.4874 17.0604 99.6797 6.18097 106.508 0C107.692 1.23732 108.879 2.47689 110.063 3.71533C93.2658 20.2538 96.0577 47.4141 112.216 60.8491C120.815 45.8123 129.405 30.7868 138.08 15.6161C146.746 30.771 155.337 45.7954 163.931 60.8255C179.511 48.0732 183.339 21.1975 165.96 3.60735C167.175 2.4274 168.392 1.24744 169.611 0.0663653C186.49 15.4035 188.643 48.053 166.663 65.6061H166.664V65.6049ZM116.396 64.0785C130.164 73.4574 148.411 72.1526 159.735 64.0279C152.545 51.4522 145.351 38.8721 138.08 26.1558C130.804 38.8811 123.611 51.4612 116.396 64.0774L116.396 64.0785Z" fill="white"/>
                  <path d="M20.5421 148.821L6.00242 148.821L2.87102 155.771L1.66699e-10 155.771L11.9304 129.751L14.6513 129.751L26.5817 155.771L23.6735 155.771L20.5421 148.821ZM19.5351 146.59L13.2723 132.614L7.00948 146.59L19.5351 146.59Z" fill="white"/>
                  <path d="M38.3623 129.751L41.1217 129.751L41.1217 153.392L55.7358 153.392L55.7358 155.771L38.3637 155.771L38.3637 129.751H38.3623Z" fill="white"/>
                  <path d="M70.423 132.13L61.252 132.13L61.252 129.751L82.3534 129.751L82.3534 132.13L73.1824 132.13L73.1824 155.771L70.423 155.771L70.423 132.13Z" fill="white"/>
                  <path d="M112.662 153.392L112.662 155.771L94.2085 155.771L94.2085 129.751L112.103 129.751L112.103 132.13L96.9665 132.13L96.9665 141.385L110.462 141.385L110.462 143.727L96.9665 143.727L96.9665 153.392L112.662 153.392Z" fill="white"/>
                  <path d="M144.424 155.771L138.459 147.407C137.788 147.482 137.117 147.519 136.372 147.519L129.363 147.519L129.363 155.771L126.604 155.771L126.604 129.751L136.372 129.751C143.008 129.751 147.033 133.096 147.033 138.673C147.033 142.762 144.87 145.623 141.068 146.85L147.442 155.771L144.423 155.771L144.424 155.771ZM144.275 138.673C144.275 134.509 141.516 132.13 136.297 132.13L129.363 132.13L129.363 145.177L136.297 145.177C141.517 145.177 144.275 142.76 144.275 138.671V138.673Z" fill="white"/>
                  <path d="M158.701 142.762C158.701 135.143 164.517 129.529 172.382 129.529C176.184 129.529 179.615 130.792 181.926 133.32L180.174 135.067C178.05 132.911 175.439 131.982 172.457 131.982C166.157 131.982 161.422 136.592 161.422 142.762C161.422 148.932 166.157 153.542 172.457 153.542C175.439 153.542 178.048 152.612 180.174 150.42L181.926 152.167C179.615 154.695 176.186 155.995 172.345 155.995C164.516 155.995 158.701 150.383 158.701 142.762Z" fill="white"/>
                  <path d="M210.969 148.821L196.43 148.821L193.298 155.771L190.427 155.771L202.358 129.751L205.079 129.751L217.009 155.771L214.101 155.771L210.969 148.821ZM209.962 146.59L203.7 132.614L197.437 146.59L209.962 146.59Z" fill="white"/>
                  <path d="M228.79 129.751L231.549 129.751L231.549 153.392L246.163 153.392L246.163 155.771L228.791 155.771L228.791 129.751H228.79Z" fill="white"/>
                  <path d="M258.203 129.751L260.963 129.751L260.963 153.392L275.577 153.392L275.577 155.771L258.205 155.771L258.205 129.751H258.203Z" fill="white"/>
                  </svg>
                  `;

      const logoImagePng = await svgToPng(logoSvg);
      const logoImageEmbedded = await pdfDoc.embedPng(logoImagePng);

      page4.drawImage(logoImageEmbedded, {
        x: 612,
        y: page4.getHeight() - 112 - 156,
        width: 275.58,
        height: 156,
        opacity: 1,
      });
      // Draw the first text block

      const text11 = "AlterCall AI is a";
      const text22 = "revolutionary";
      const ltext3 = "Leadership Platform";
      const text4 = "we measure the";
      const text5 = "previously";
      const text6 = "unmeasurable.";

      const pageWidth = page4.getWidth();
      const pageHeight = page4.getHeight();

      // Text Configuration
      const lfontSize = 78;
      const lineHeight = 88;
      const left = 136;
      const top = pageHeight - 987;
      const colorWhite = rgb(1, 1, 1); // White color
      const colorOrange = rgb(1, 94 / 255, 0); // Orange color

      // Draw Text 1
      page4.drawText(text11, {
        x: left,
        y: top,
        size: lfontSize,
        fontFamily: "poppins",
        color: colorWhite,
        lineHeight: lineHeight,
        textAlign: "left",
      });

      // Draw Text 2
      page4.drawText(text22, {
        x: left,
        y: top - lineHeight,
        size: lfontSize,
        fontFamily: "poppins",
        color: colorWhite,
        lineHeight: lineHeight,
        textAlign: "left",
      });

      // Draw Text 3
      page4.drawText(ltext3, {
        x: left,
        y: top - 2 * lineHeight,
        size: lfontSize,
        fontFamily: "poppins",
        color: colorWhite,
        lineHeight: lineHeight,
        textAlign: "left",
      });

      // Draw Text 4
      page4.drawText(text4, {
        x: left,
        y: top - 3 * lineHeight,
        size: lfontSize,
        fontFamily: "poppins",
        color: colorOrange,
        lineHeight: lineHeight,
        textAlign: "left",
      });

      // Draw Text 5
      page4.drawText(text5, {
        x: left,
        y: top - 4 * lineHeight,
        size: lfontSize,
        fontFamily: "poppins",
        color: colorOrange,
        lineHeight: lineHeight,
        textAlign: "left",
      });

      // Draw Text 6
      page4.drawText(text6, {
        x: left,
        y: top - 5 * lineHeight,
        size: lfontSize,
        fontFamily: "poppins",
        color: colorOrange,
        lineHeight: lineHeight,
        textAlign: "left",
      });
      // Draw the new text block
      const newText1 =
        "We are pioneering the coaching industry with a specialized";
      const newText2 =
        "AI platform that doesn't just speculate about your potential;";
      const newText3 = "it knows it,";
      const newText4 = "and more importantly, it's here to unlock it.";

      const newFontSize = 24;
      const newLineHeight = 35;
      const newLeft = 136;
      const newTop = pageHeight - 1544;
      const newColor = rgb(1, 1, 1); // White color

      // Draw new Text 1
      page4.drawText(newText1, {
        x: newLeft,
        y: newTop,
        size: newFontSize,
        fontFamily: "Lexend",
        color: newColor,
        lineHeight: newLineHeight,
        textAlign: "left",
        fontWeight: 300,
      });

      // Draw new Text 2
      page4.drawText(newText2, {
        x: newLeft,
        y: newTop - newLineHeight,
        size: newFontSize,
        fontFamily: "Lexend",
        color: newColor,
        lineHeight: newLineHeight,
        textAlign: "left",
        fontWeight: 300,
      });

      // Draw new Text 3
      // Draw new Text 3
      page4.drawText(newText3, {
        x: newLeft,
        y: newTop - 2 * newLineHeight,
        size: newFontSize,
        fontFamily: "Lexend",
        color: newColor,
        lineHeight: newLineHeight,
        textAlign: "left",
        fontWeight: 700,
      });

      // Draw new Text 4
      page4.drawText(newText4, {
        x: newLeft + 115,
        y: newTop - 2 * newLineHeight,
        size: newFontSize,
        fontFamily: "Lexend",
        color: newColor,
        lineHeight: newLineHeight,
        textAlign: "left",
        fontWeight: 1400,
      });

      // Draw a horizontal line with the specified properties
      page4.drawRectangle({
        x: -38,
        y: page4.getHeight() - 1863,
        width: 1606,
        height: 0,
        borderColor: rgb(235 / 255, 237 / 255, 242 / 255),
        borderWidth: 2,
        opacity: 1,
        rotate: degrees(0),
      });
      page4.drawText("Learn more about L.A.R.A at", {
        x: 499,
        y: page4.getHeight() - 1933,
        size: 20,
        font: helveticaFont,
        color: rgb(1, 1, 1),
        width: 894,
        height: 41,
        lineHeight: 24,
        fontWeight: 400,
        textAlign: "left",
        opacity: 1,
        letterSpacing: -0.03,
      });

      // Draw the clickable link "www.altercall.com/aai"
      page4.drawText("www.altercall.com/aai", {
        x: 770,
        y: page4.getHeight() - 1933,
        size: 20,
        font: helveticaFont,
        color: rgb(0, 0, 1),
        width: 894,
        height: 41,
        lineHeight: 24,
        fontWeight: 400,
        textAlign: "left",
        opacity: 1,
        letterSpacing: -0.03,
        underline: true,
      });

      page4.drawText("09", {
        x: 1437,
        y: page4.getHeight() - 1933,
        size: 18,
        color: rgb(1, 1, 1),
        width: 18,
        height: 19,
        opacity: 1,
      });

      page4.drawImage(vectorFooterwhiteImage, {
        x: 35.72,
        y: page4.getHeight() - 1933,
        width: 38,
        height: 35.72,
        opacity: 1,
        //rotate: degrees(-90),
      });

      // Serialize the PDF to Uint8Array and start the download
      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      saveAs(blob, `${name} Assessment.pdf`);
    } catch (error) {
      console.error("Error generating PDF report: ", error);
    }
  };

  const downloadPDFButton = async () => {
    setLoadingPDF(true);

    // Create a delay of 3 seconds
    const delay = new Promise((resolve) => setTimeout(resolve, 3000));

    try {
      // Run both the PDF download and the 3-second delay simultaneously
      await Promise.all([downloadPDF(), delay]);

      setLoadingPDF(false);
    } catch (error) {
      console.error("Error generating PDF report: ", error);
      setLoadingPDF(false);
    }
  };

  return (
    <GlobalPageComponent user={user} userData={userData}>
      <ClientLimitReached user={user} userData={userData} />

      <Box
        sx={{
          backgroundColor: "#EFF3F6",
          width: "100%",
          height: "auto",
          padding: "16px",
          display: {
            xs: !collapsed ? "none" : "block",
          },
          overflowY: "auto",
        }}
      >
        <Box sx={{ height: "100%" }}>
          {/* <Typography
                        sx={{
                            color: "black",
                            fontFamily: "Poppins",
                            fontSize: "36px",
                            fontWeight: 300,
                            marginBottom: "16px",
                        }}
                    >
                        Assessments
                    </Typography> */}

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              height: "100%",
            }}
          >
            <Box
              sx={{
                background: "white",
                borderRadius: "40px",
                padding: "30px",
                width: "100%",
                height: "auto",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: {
                    xs: "center",
                    md: "space-between",
                  },
                  flexDirection: {
                    xs: "column",
                    md: "row",
                  },
                  gap: {
                    xs: "20px",
                    md: "0px",
                  },
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    color: "black",
                    fontFamily: "Poppins",
                    fontSize: "24px",
                    fontWeight: 300,
                  }}
                >
                  Individual Breakdown
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: {
                      xs: "start",
                      md: "center",
                    },
                    justifyContent: "space-between",
                    border: "1px solid #D9D9D9",
                    height: {
                      xs: "auto",
                      md: "45px",
                    },
                    paddingX: "10px",
                    paddingY: "10px",
                    borderRadius: "20px",
                    flexDirection: {
                      xs: "column",
                      md: "row",
                    },
                    gap: {
                      xs: "20px",
                      md: "8px",
                    },
                  }}
                >
                  {teamId && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: "#E3E3E3",
                          borderRadius: "50%",
                          width: "28px",
                          height: "28px",
                        }}
                      />
                      <Typography
                        sx={{
                          color: "black",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          fontWeight: 300,
                        }}
                      >
                        {"N/A"}
                      </Typography>
                    </Box>
                  )}

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <Avatar
                      sx={{
                        width: "22px",
                        height: "22px",
                      }}
                      src={""}
                    />
                    <Typography
                      sx={{
                        color: "black",
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontWeight: 300,
                      }}
                    >
                      {loading
                        ? ""
                        : coacheeData?.full_name
                        ? coacheeData?.full_name
                        : ""}
                    </Typography>
                  </Box>

                  <Box sx={{ display: "flex", gap: "8px" }}>
                    <Box sx={{ display: "flex", gap: "6px" }}>
                      <Typography
                        sx={{
                          color: "#B9B9B9",
                          font: "10px",
                          fontWeight: "500",
                          textTransform: "capitalize",
                        }}
                      >
                        Date:
                      </Typography>
                      <Typography
                        sx={{
                          color: "#000000",
                          font: "10px",
                          fontWeight: "500",
                        }}
                      >
                        {loading
                          ? ""
                          : assessmentData?.updatedAt
                          ? ` ${new Date(
                              assessmentData?.updatedAt
                            ).toLocaleDateString()}`
                          : ""}
                      </Typography>
                    </Box>

                    <Typography
                      sx={{ color: "#000000", font: "10px", fontWeight: "500" }}
                    >
                      |
                    </Typography>

                    <Box sx={{ display: "flex", gap: "6px" }}>
                      <Typography
                        sx={{
                          color: "#B9B9B9",
                          font: "10px",
                          fontWeight: "500",
                          textTransform: "capitalize",
                        }}
                      >
                        Time:
                      </Typography>
                      <Typography
                        sx={{
                          color: "#000000",
                          font: "10px",
                          fontWeight: "500",
                        }}
                      >
                        {loading
                          ? ""
                          : assessmentData?.updatedAt
                          ? new Date(
                              assessmentData?.updatedAt
                            ).toLocaleTimeString([], {
                              hour: "2-digit",
                              minute: "2-digit",
                            })
                          : ""}
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Button
                  onClick={downloadPDFButton}
                  variant="outlined"
                  sx={{
                    width: "160px",
                    height: "45px",
                    borderRadius: "22px",
                    backgroundColor: loadingPDF ? "black" : "white", // Change background based on loading state
                    color: loadingPDF ? "white" : "black", // Change text color based on loading state
                    border: "1px solid black",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "0 16px",
                    outline: "none",
                    "&:focus": {
                      outline: "none",
                    },
                    "&:hover": {
                      cursor: "pointer",
                      background: loadingPDF ? "black" : "black", // Keep background black if loading
                      borderColor: "black",
                    },
                    "&:hover *": {
                      color: loadingPDF ? "white" : "white", // Keep text color white if loading
                    },
                    "&:hover svg *": {
                      stroke: loadingPDF ? "white" : "white", // Keep SVG stroke color white if loading
                    },
                  }}
                >
                  {loadingPDF ? (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        animation: "spin 1s linear infinite", // Apply spinning animation
                      }}
                    >
                      <path
                        d="M12 2V6M12 18V22M4.93 4.93L7.76 7.76M16.24 16.24L19.07 19.07M2 12H6M18 12H22M4.93 19.07L7.76 16.24M16.24 7.76L19.07 4.93"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  ) : (
                    <>
                      {/* Your Provided Download Icon */}
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ marginRight: "8px" }}
                      >
                        <path
                          d="M3 15.5C3 18.2614 5.23858 20.5 8 20.5H16C18.7614 20.5 21 18.2614 21 15.5M9 12.6885C9.74024 13.6755 10.599 14.5668 11.5564 15.3426C11.6859 15.4475 11.843 15.5 12 15.5M15 12.6885C14.2598 13.6755 13.401 14.5668 12.4436 15.3426C12.3141 15.4475 12.157 15.5 12 15.5M12 15.5V4.5"
                          stroke="black"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span style={{textTransform:"none" , fontSize: "12px", fontFamily:"Poppins", fontWeight:500, letterSpacing:"0.05em", lineHeight:"18px"}}>Download</span>
                      </>
                  )}
                </Button>

                <Button
                  onClick={() => {
                    navigate(-1);
                    }}
                    variant="outlined"
                    sx={{
                    width: "90px",
                    height: "35px",
                    borderRadius: "20px",
                    backgroundColor: "white",
                    color: "black",
                    border: "1px solid black",
                    textTransform: "none",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    letterSpacing: "0.05em",
                    lineHeight: "18px",
                    "&:hover": {
                      cursor: "pointer",
                      background: "black",
                      borderColor: "black",
                      color: "white"
                    },
                    "&:hover *": {
                      color: "white",
                    },
                    "&:hover svg *": {
                      stroke: "white",
                    },
                    }}
                    >
                    Back
                    </Button>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                    background: "white",
                    borderRadius: "40px",
                    padding: "30px",
                    width: "100%",
                    height: "100%",
                    overflowY: "auto",
                    "&::-webkit-scrollbar": {
                    width: "8px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "rgb(207, 207, 207)",
                    borderRadius: "4px",
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                    backgroundColor: "rgb(207, 207, 207)",
                    },
                    "&::-webkit-scrollbar-track": {
                    backgroundColor: "transparent",
                    },
                    }}
                  >
                    <Box sx={{ paddingX: "0" }}>
                    <Box
                    sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "40px",
                    }}
                    >
                    <Typography
                    sx={{
                      color: "black",
                      fontSize: "24px",
                      fontFamily: "Poppins",
                      fontWeight: 300,
                      wordWrap: "break-word",
                    }}
                    >
                    Results
                    </Typography>
                    <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: "20px",
                      flexDirection: {
                      xs: "column",
                      md: "row",
                      },
                    }}
                    >
                    {tagNames.map((name, index) => (
                      <Box
                      key={index}
                      onClick={() => setSelectedTag(name)}
                      sx={{
                      width: "160px",
                      height: "35px",
                      borderRadius: "60px",
                      border:
                      selectedTag === name
                        ? `2px solid ${
                        domainColors[domains.find((d) => d.title === name)?.domain]
                        }`
                        : "2px solid #8F8F8F",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      "&:hover": {
                      borderColor: domainColors[domains.find((d) => d.title === name)?.domain],
                      backgroundColor: domainColors[domains.find((d) => d.title === name)?.domain],
                      "& *": {
                        color: "white", // Ensure all child elements change color to white
                      },
                      "& svg *": {
                        stroke: "white", // Ensure SVG elements change stroke color to white
                      },
                      },
                      }}
                      >
                      <Typography
                      sx={{
                      color:
                        selectedTag === name
                        ? domainColors[domains.find((d) => d.title === name)?.domain]
                        : "#8F8F8F",
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      fontWeight: 500,
                      letterSpacing: "0.05em",
                      lineHeight: "18px",
                      textTransform: "capitalize",
                      paddingX: "10px",
                      "&:hover": {
                        color: "white",
                      },
                      }}
                      >
                      {name}
                      </Typography>
                      </Box>
                    ))}
                    </Box>
                    </Box>

                    {assessmentData && assessmentData?.results && (
                    <>
                    {selectedDomain?.domain &&
                    selectedDomain?.domain === "G" ? (
                      <Box
                      sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "40px",
                      }}
                      >
                      {/* Section to align Object.keys mapping and Assessment Summary */}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: { xs: "column", md: "row" },
                            gap: "15px",
                            alignItems: "stretch",
                          }}
                        >
                          {/* Left side: Assessment Summary */}
                          <Box
                            sx={{
                              flex: 1, // Ensure the box grows equally with the right side
                              display: "flex",
                              flexDirection: "column",
                              maxHeight: "300px", // Set the maximum height
                              gap: "15px",
                              paddingRight: "8px",
                              overflowY: "auto", // Enable scrolling on overflow
                              "&::-webkit-scrollbar": {
                                width: "8px", // Custom scrollbar width
                              },
                              "&::-webkit-scrollbar-thumb": {
                                backgroundColor: "rgb(207, 207, 207)", // Custom scrollbar thumb color
                                borderRadius: "4px", // Rounded scrollbar thumb
                              },
                              "&::-webkit-scrollbar-thumb:hover": {
                                backgroundColor: "rgb(170, 170, 170)", // Thumb color on hover
                              },
                              "&::-webkit-scrollbar-track": {
                                backgroundColor: "transparent", // Custom scrollbar track
                              },
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#93989A",
                                fontSize: 15.9,
                                fontFamily: "Lexend",
                                fontWeight: "600",
                                wordWrap: "break-word",
                                textTransform: "capitalize",
                              }}
                            >
                              Assessment Summary
                            </Typography>
                            <Typography
                              sx={{
                                color: "#373737",
                                fontSize: 18,
                                fontFamily: "Lexend",
                                fontWeight: "400",
                                wordWrap: "break-word",
                              }}
                            >
                              {assessmentData?.summary ??
                                "The summary is being generated."}
                            </Typography>
                          </Box>

                          {/* Right side: Object.keys mapping */}
                          <Box
                            sx={{
                              flex: 1,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "flex-start",
                              gap: "20px",
                            }}
                          >
                            {domainOrder.map((domainKey) => {
                              const domainData =
                                assessmentData.results[domainKey] || {};
                              const scoreValue = Number(domainData.score) || 0;
                              const scaledScore = Math.round(
                                (scoreValue / 120) * 100
                              );
                              const filledCircles = Math.max(
                                Math.round(scaledScore / 10),
                                0
                              );
                              const emptyCircles = Math.max(
                                10 - filledCircles,
                                0
                              );

                              return (
                                <Box
                                  key={domainKey}
                                  sx={{
                                    marginBottom: "20px",
                                    display: "flex",
                                    gap: "20px",
                                    textAlign: "left",
                                  }}
                                >
                                  {!loading && (
                                    <>
                                      <Typography
                                        sx={{
                                          color:
                                            domainColors[domainKey] || "#000",
                                          fontFamily: "Lexend",
                                          fontSize: "20px",
                                          fontWeight: 400,
                                          minWidth: "200px",
                                        }}
                                      >
                                        {domains.find(
                                          (d) => d.domain === domainKey
                                        )?.title || domainKey}
                                      </Typography>

                                      {/* Circles for scores */}
                                      <Box sx={{ display: "flex", gap: "5px" }}>
                                        {Array.from(Array(filledCircles)).map(
                                          (_, subIndex) => (
                                            <Box
                                              key={subIndex}
                                              sx={{
                                                width: {
                                                  xs: "18px",
                                                  md: "27px",
                                                },
                                                height: {
                                                  xs: "18px",
                                                  md: "27px",
                                                },
                                                borderRadius: "50%",
                                                backgroundColor:
                                                  domainColors[domainKey],
                                              }}
                                            />
                                          )
                                        )}
                                        {Array.from(Array(emptyCircles)).map(
                                          (_, subIndex) => (
                                            <Box
                                              key={subIndex}
                                              sx={{
                                                width: {
                                                  xs: "18px",
                                                  md: "27px",
                                                },
                                                height: {
                                                  xs: "18px",
                                                  md: "27px",
                                                },
                                                borderRadius: "50%",
                                                backgroundColor: "#EFF3F6",
                                              }}
                                            />
                                          )
                                        )}
                                      </Box>

                                      <Typography
                                        sx={{
                                          color: "#8F8F8F",
                                          fontFamily: "Lexend",
                                          fontSize: "16px",
                                        }}
                                      >
                                        {scaledScore} / 100
                                      </Typography>
                                    </>
                                  )}
                                </Box>
                              );
                            })}
                          </Box>
                        </Box>

                        {/* Section for the dropdowns taking the remaining space */}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          {[
                            {
                              key: "communication_tips",
                              label: "Communication Tips",
                            },
                            {
                              key: "communication_style",
                              label: "Communication Style",
                            },
                            {
                              key: "communication_preference",
                              label: "Communication Preference",
                            },
                          ].map((section, index) => (
                            <Box
                              key={section.key}
                              sx={{ maxWidth: "100%", marginBottom: "10px" }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <Typography
                                    sx={{
                                      color: "#160042",
                                      fontSize: 30,
                                      fontFamily: "Poppins",
                                      fontWeight: 300,
                                      lineHeight: "48px",
                                      wordWrap: "break-word",
                                      marginLeft: "15px",
                                    }}
                                  >
                                    {section.label}
                                  </Typography>
                                </Box>

                                {/* IconButton with SectionDropDownIcon */}
                                <IconButton
                                  sx={{
                                    width: "71",
                                    height: "71",
                                    padding: 0,
                                    flexShrink: 0,
                                    borderRadius: "50%",
                                    backgroundColor:
                                      openSections === section.key
                                        ? "#0076BA"
                                        : "transparent", // Blue background when open
                                    transition: "background-color 0.3s ease",
                                    "& svg": {
                                      transform:
                                        openSections === section.key
                                          ? "rotate(180deg)"
                                          : "rotate(0deg)", // Rotate icon when open
                                      transition: "transform 0.3s ease", // Smooth transition for rotation
                                      "&:hover": {
                                        backgroundColor:
                                          openSections === section.key
                                            ? "#0076BA"
                                            : "transparent", // Maintain color on hover
                                        borderRadius: "50%",
                                      },
                                    },
                                  }}
                                  onClick={() => handleToggle(section.key)}
                                >
                                  <SectionDropDownIcon
                                    color={
                                      openSections === section.key
                                        ? "#FFFFFF"
                                        : "#383838"
                                    } // White icon when open, black when closed
                                  />
                                </IconButton>
                              </Box>

                              <Collapse in={openSections === section.key}>
                                <Box sx={{}}>
                                  <Typography
                                    sx={{
                                      color: "#292929",
                                      fontSize: 20,
                                      fontFamily: "Lexend",
                                      fontWeight: 400,
                                      wordWrap: "break-word",
                                      padding: "16px",
                                    }}
                                  >
                                    {assessmentData?.[section.key] ||
                                      "Your analysis is being generated and in process."}
                                  </Typography>
                                </Box>
                              </Collapse>

                              <Box
                                sx={{
                                  borderTop: "2px solid #CED9E0",
                                  marginY: "10px",
                                }}
                              />
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    ) : (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            width: "100%",
                            height: {
                              xs: "auto",
                              md: "300px",
                            },
                            flexDirection: {
                              xs: "column",
                              md: "row",
                            },
                          }}
                        >
                          <Box
                            sx={{
                              flex: 2,
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <Box>
                              <Typography
                                sx={{
                                  color:
                                    domainColors[selectedDomain?.domain] ||
                                    "#000000",
                                  fontFamily: "Lexend",
                                  fontSize: {
                                    xs: "35px",
                                    md: "44px",
                                  },
                                  fontWeight: 400,
                                  lineHeight: "160%",
                                }}
                              >
                                {selectedDomain?.title || selectedTag}
                              </Typography>
                            </Box>

                            {loading || !selectedDomain?.domain ? (
                              <>
                                <CircularProgress sx={{ color: "black" }} />
                              </>
                            ) : (
                              <Box>
                                <Typography
                                  sx={{
                                    color: "#0C0C0C",
                                    fontFamily: "Lexend",
                                    fontSize: "16px",
                                    fontWeight: 400,
                                    lineHeight: "160%",
                                  }}
                                >
                                  {selectedDomain?.shortDescription ||
                                    "No description available for this domain."}
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "#0C0C0C",
                                    fontFamily: "Lexend",
                                    fontSize: "16px",
                                    fontWeight: 400,
                                    lineHeight: "160%",
                                  }}
                                >
                                  {selectedDomain?.results?.find(
                                    (result) =>
                                      result?.score ===
                                      assessmentData?.results[
                                        selectedDomain?.domain
                                      ]?.result
                                  )?.text ||
                                    "No description available for this result."}
                                </Typography>
                              </Box>
                            )}
                          </Box>

                          <Box
                            sx={{
                              flex: 1,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "50%",
                                marginY: {
                                  xs: "20px",
                                  md: "0px",
                                },
                                width: {
                                  xs: "80px",
                                  md: "142px",
                                },
                                height: {
                                  xs: "80px",
                                  md: "142px",
                                },
                                backgroundColor:
                                  domainColors[selectedDomain?.domain] || "000",
                              }}
                            >
                              <Typography
                                sx={{
                                  margin: 0,
                                  padding: 0,
                                  color: "FFF" || "000",
                                  fontFamily: "Lexend",
                                  fontSize: "44px",
                                  fontWeight: 400,
                                  lineHeight: "160%",
                                }}
                              >
                                {selectedDomain?.domain
                                  ? Math.round(
                                      (assessmentData?.results[
                                        selectedDomain?.domain
                                      ]?.score /
                                        120) *
                                        100
                                    )
                                  : ""}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            backgroundColor: "#EFF3F6",
                            width: "100%",
                            height: "7px",
                            borderRadius: "10px",
                            marginY: {
                              xs: "30px",
                            },
                          }}
                        ></Box>

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: {
                              xs: "40px",
                              md: "20px",
                            },
                            marginTop: "30px",
                          }}
                        >
                          {selectedDomain?.facets?.map((facet, index) => {
                            const facetData =
                              assessmentData?.results[selectedDomain.domain]
                                ?.facet[index + 1] || {}; // Default to empty object if no data
                            const scoreValue = Number(facetData.score) || 0; // Ensure score is a valid number, default to 0
                            const countValue = Number(facetData.count) || 1; // Default to 1 to avoid division by 0
                            const scaledFacetScore = Math.min(
                              Math.max(
                                Math.round(
                                  ((scoreValue / (countValue * 5)) * 100) / 10
                                ),
                                0
                              ),
                              10
                            ); // Adjust to scale 0-10

                            return (
                              <Box
                                key={index}
                                sx={{
                                  display: "flex",
                                  width: "100%",
                                  flexDirection: { xs: "column", md: "row" },
                                  gap: { xs: "20px", md: "30px" },
                                  marginBottom: "20px", // Add spacing between each facet
                                  flexWrap: "wrap", // Allow wrapping on small screens
                                }}
                              >
                                {/* Facet Title and Description */}
                                <Box
                                  sx={{
                                    flex: 2,
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    gap: "13px",
                                    minWidth: "250px", // Ensure a minimum width to avoid shrinkage
                                    padding: "10px", // Add some padding for better spacing
                                  }}
                                >
                                  <Box>
                                    <Typography
                                      sx={{
                                        color: "#222222", // Color based on the domain
                                        fontFamily: "Lexend",
                                        fontSize: { xs: "18px", md: "24px" }, // Responsive font size
                                        fontWeight: 400,
                                        lineHeight: "160%",
                                        marginBottom: "8px", // Avoid text overlapping
                                      }}
                                    >
                                      {facet.title}
                                    </Typography>
                                  </Box>

                                  <Box>
                                    <Typography
                                      sx={{
                                        color: "#0C0C0C",
                                        fontFamily: "Lexend",
                                        fontSize: { xs: "14px", md: "16px" }, // Responsive font size
                                        fontWeight: 400,
                                        lineHeight: "160%",
                                        overflowWrap: "break-word", // Handle long text wrapping
                                      }}
                                    >
                                      {facet.text}
                                    </Typography>
                                  </Box>
                                </Box>

                                {/* Scoring Circles */}
                                <Box
                                  sx={{
                                    flex: 1,
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    paddingX: "20px",
                                    rowGap: "10px",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      gap: "5px",
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    {" "}
                                    {/* Add flexWrap to handle overflow */}
                                    {/* Render the filled circles */}
                                    {Array.from(Array(scaledFacetScore)).map(
                                      (item, subIndex) => (
                                        <Box
                                          key={subIndex}
                                          sx={{
                                            width: { xs: "18px", md: "27px" },
                                            height: { xs: "18px", md: "27px" },
                                            borderRadius: "50%",
                                            backgroundColor:
                                              domainColors[
                                                selectedDomain?.domain
                                              ] || "000", // Circle color
                                          }}
                                        />
                                      )
                                    )}
                                    {/* Render the empty circles */}
                                    {Array.from(
                                      Array(10 - scaledFacetScore)
                                    ).map((item, subIndex) => (
                                      <Box
                                        key={subIndex}
                                        sx={{
                                          width: { xs: "18px", md: "27px" },
                                          height: { xs: "18px", md: "27px" },
                                          borderRadius: "50%",
                                          backgroundColor: "#EFF3F6", // Light gray for empty circles
                                        }}
                                      />
                                    ))}
                                  </Box>
                                </Box>
                              </Box>
                            );
                          })}
                        </Box>
                      </>
                    )}
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </GlobalPageComponent>
  );
};
const styles = document.createElement("style");
styles.innerHTML = `
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;
document.head.appendChild(styles);
export default CoachIndividualBreakdown;
