import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CloseIcon from '@mui/icons-material/Close';


export const _LeadershipCenterTeamFilter = ({
    value,
    handleChange,
    items = []
  }) => {
    const [opened, setOpened] = useState(false);
  
    const selectedTeamName = items.find(item => item.value === value)?.key || "Team";
  
    return (
      <Box
        sx={{
          padding: "10px",
          position: "relative",
          borderWidth: opened === true ? "1px 1px 0" : "1px",
          borderColor: "#CBD0C8",
          borderStyle: "solid",
          borderRadius: opened === true ? "8px 8px 0 0" : "8px",
          width: "auto", 
          minWidth: "125px", //increase it for more wider space
          maxWidth: "100%",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            cursor: "pointer",
          }}
          onClick={() => setOpened(!opened)}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Poppins",
                color: "black",
                fontSize: "12px",
                fontWeight: 500,
                letterSpacing: "0.05em",
                lineHeight: "18px",
                textTransform: "capitalize",
              }}
            >
              {selectedTeamName}
            </Typography>
            {value !== "none" && value !== "" && value !== null && (
              <CloseIcon
                sx={{
                  color: "black",
                  fontSize: "12px",
                  cursor: "pointer",
                  background: "#AAA",
                  borderRadius: "50%",
                  padding: "2px",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleChange("none");
                }}
              />
            )}
          </Box>
          <KeyboardArrowDownIcon
            sx={{
              color: "black",
              transition: "all 0.3s ease",
              transform: `rotate(${opened === true ? '180deg' : '0deg'})`,
              width: "12px",
              height: "12px",
            }}
          />
        </Box>
        <Box
          sx={{
            display: opened === true ? "flex" : "none",
            flexDirection: "column",
            position: "absolute",
            top: "30px",
            left: "-1px",
            zIndex: 10,
            width: "calc(100% + 2px)",
            borderWidth: "0 1px 1px",
            borderColor: "#CBD0C8",
            borderStyle: "solid",
            borderRadius: "0 0 8px 8px",
            maxHeight: "210px",
            overflowY: "auto",
            background: "white",
          }}
        >
          {items.map((item, index) =>
            item.value !== "none" && item.value && (
              <Box
                key={index}
                sx={{
                  padding: "12px",
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontWeight: "500",
                  lineHeight: "1.5",
                  color: "black",
                  background: "white",
                  cursor: "pointer",
                  "&:hover": {
                    background: "#F5F6F4",
                  }
                }}
                onClick={() => {
                  setOpened(false);
                  handleChange(item.value);
                }}
              >
                {item.key}
              </Box>
            )
          )}
        </Box>
        {opened === true && (
          <Box
            sx={{
              position: "fixed",
              width: "100%",
              height: "100%",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              zIndex: 9,
            }}
            onClick={() => setOpened(false)}
          />
        )}
      </Box>
    );
  };
  