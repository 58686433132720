import React from "react";

function AssignIcon({ color }) {
  if (color === "disabled") {
    return (
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.432 15.8692C19.0365 19.0633 15.8494 21.2954 12.1409 21.2954C7.14617 21.2954 3.09717 17.2464 3.09717 12.2517C3.09717 8.54322 5.32932 5.35608 8.52339 3.96053M12.1409 4.56457L12.1409 10.8952C12.1409 11.6444 12.7482 12.2517 13.4974 12.2517L19.828 12.2517C20.5772 12.2517 21.1953 11.6408 21.0842 10.8999C20.4978 6.98741 17.4052 3.89478 13.4927 3.30837C12.7518 3.19732 12.1409 3.81537 12.1409 4.56457Z" stroke="#B0B7BD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    );
  } else if (color === "enabled") {
    return (
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.432 15.8692C19.0365 19.0633 15.8494 21.2954 12.1409 21.2954C7.14617 21.2954 3.09717 17.2464 3.09717 12.2517C3.09717 8.54322 5.32932 5.35608 8.52339 3.96053M12.1409 4.56457L12.1409 10.8952C12.1409 11.6444 12.7482 12.2517 13.4974 12.2517L19.828 12.2517C20.5772 12.2517 21.1953 11.6408 21.0842 10.8999C20.4978 6.98741 17.4052 3.89478 13.4927 3.30837C12.7518 3.19732 12.1409 3.81537 12.1409 4.56457Z" stroke="#252525" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    );
  } else {
    return (
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.432 15.8692C19.0365 19.0633 15.8494 21.2954 12.1409 21.2954C7.14617 21.2954 3.09717 17.2464 3.09717 12.2517C3.09717 8.54322 5.32932 5.35608 8.52339 3.96053M12.1409 4.56457L12.1409 10.8952C12.1409 11.6444 12.7482 12.2517 13.4974 12.2517L19.828 12.2517C20.5772 12.2517 21.1953 11.6408 21.0842 10.8999C20.4978 6.98741 17.4052 3.89478 13.4927 3.30837C12.7518 3.19732 12.1409 3.81537 12.1409 4.56457Z" stroke="url(#paint0_linear)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <defs>
          <linearGradient id="paint0_linear" x1="12.5" y1="0" x2="12.5" y2="25" gradientUnits="userSpaceOnUse">
            <stop stop-color="#242F8C" />
            <stop offset="0.401042" stop-color="#03093A" />
            <stop offset="1" stop-color="#D8659B" />
          </linearGradient>
        </defs>
      </svg>
    );
  }
}

export default AssignIcon;
