import { useState, useEffect } from "react";
import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import * as Frigade from "@frigade/react";

const Circle = ({ id, color, score, radius, fill, strokeDashoffset }) => {
  const r = radius;
  const circ = 2 * Math.PI * r;
  const strokescore = ((100 - score) * circ) / 100;

  return (
    <circle
      r={r}
      cx={100}
      cy={100}
      fill={fill ?? "transparent"}
      stroke={color ? color : `url(#${id})`}
      strokeWidth={"6px"}
      strokeDasharray={circ}
      strokeDashoffset={strokeDashoffset}
      strokeLinecap="round"
      style={{ transition: "stroke-dashoffset 1s ease-out" }}
    ></circle>
  );
};

const Text = ({ score, textSize }) => {
  return (
    <text
      x="50%"
      y="50%"
      dominantBaseline="central"
      textAnchor="middle"
      fontSize={textSize}
      fontFamily="Poppins"
      fontWeight={275}
    >
      {isNaN(score) ? "-" : score === 0 ? "0" : Math.round(score)}
    </text>
  );
};

const getPieChartColor = (score) => {
  if (score >= 1 && score <= 20) return "#DA0000"; // Red
  if (score >= 21 && score <= 40) return "#F90"; // Orange
  if (score >= 41 && score <= 60) return "#FFD600"; // Yellow
  if (score >= 61 && score <= 70) return "#A2D831"; // Light Blue
  if (score >= 71 && score <= 80) return "#70CD27"; // Green
  if (score >= 81 && score <= 95) return "#4BC500"; // Green
  if (score >= 95 && score <= 100) return "#37AB00"; // Green
  return "#D3D3D3"; // Grey for undefined or zero
};

const Pie = ({ score, radius, textSize, fillStopColor, isOverall }) => {
  const [strokeDashoffset, setStrokeDashoffset] = useState(
    2 * Math.PI * radius
  );
  const gradientId = `gradient-${Math.random()}`;
  const fillGradientId = `fill-gradient-${Math.random()}`;

  useEffect(() => {
    const circ = 2 * Math.PI * radius;
    const strokescore = ((100 - score) * circ) / 100;
    setStrokeDashoffset(strokescore);
  }, [score, radius]);

  const color = getPieChartColor(score);

  return (
    <svg width={200} height={200}>
      <defs>
        <linearGradient id={gradientId}>
          <stop offset="0%" stopColor="#e383eb" />
          <stop offset="40%" stopColor="#8e33b8" />
          <stop offset="60%" stopColor="#568FF3" />
          <stop offset="100%" stopColor="#62C1EB" />
        </linearGradient>
        <linearGradient id={fillGradientId}>
          <stop
            offset="0%"
            stopColor={fillStopColor ?? color}
            stopOpacity=".2"
          />
          <stop offset="100%" stopColor="#FFFFFF" stopOpacity="0" />
        </linearGradient>
      </defs>
      <g transform={`rotate(-90 ${"100 100"})`}>
        {" "}
        {/* Added to rotate the pie chart */}
        <Circle
          color="lightgrey"
          radius={radius}
          strokeDashoffset={0}
          fill={`url(#${fillGradientId})`}
        />
        <Circle
          id={gradientId}
          radius={radius}
          strokeDashoffset={strokeDashoffset}
          color={isOverall ? `url(#${gradientId})` : color}
        />
      </g>
      {isNaN(score) ? (
        <Text score="-" textSize={textSize} />
      ) : (
        <Text score={score} textSize={textSize} />
      )}
    </svg>
  );
};

const LaraClientOverview = ({ clientName, client_id, laraData, loading }) => {
  const navigate = useNavigate();
  return (
    <>
      <Grid
        item
        xs={12}
        sx={{
          width: "100%",
        }}
      >
        <Frigade.Tour
          flowId="flow_FnM7KnhQ"
          defaultOpen={true}
          sequential={true}
          dismissible={true}
          css={{
            ".fr-card": {
              backgroundColor: "#EFF3F6",
            },
            ".fr-button-primary": {
              backgroundColor: "white",
              color: "black",
              borderColor: "black",
              borderRadius: "50px",
              borderWidth: "1px",
              borderStyle: "solid",
              transition: "background-color 0.3s, color 0.3s",
              "&:hover": {
                backgroundColor: "black",
                color: "white",
              },
            },
          }}
        />
        <Box
          sx={{
            padding: "24px",
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            width: "100%",
            borderRadius: "40px",
            background: "white",
            overflow: "auto",
            minHeight: "220px",
            alignItems: "center",
            "&::-webkit-scrollbar": {
              height: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgb(207, 207, 207)",
              borderRadius: "20px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "rgb(207, 207, 207)",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "transparent",
            },
          }}
        >
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%", // Ensure the Box takes up the full width
                height: "100%", // Ensure the Box takes up the full height
              }}
            >
              <CircularProgress
                sx={{
                  color: "black",
                }}
              />
            </Box>
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                }}
              >
                <Pie
                  score={
                    isNaN(laraData.overall_score) ? "-" : laraData.overall_score
                  }
                  radius={90}
                  textSize={"54px"}
                  fillStopColor={"#62C1EB"}
                  isOverall={true}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    maxWidth: "330px",
                    minWidth: "330px",
                    marginLeft: "15px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Lexend",
                      color: "black",
                      fontWeight: 600,
                      fontSize: "24px",
                    }}
                  >
                    {/* {clientName + 'LARA Score'} */}
                    {clientName}'s <br /> Overall Score
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Lexend",
                      color: "#8F8F8F",
                      fontSize: "14px",
                      maxWidth: "90%",
                    }}
                  >
                    {/* {laraSummary} */}
                  </Typography>
                  <Button
                    sx={{
                      paddingX: "10px",
                      paddingY: "10px",
                      background: "white",
                      border: "1px solid black",
                      color: "black",
                      borderRadius: "43px",
                      display: "flex",
                      width: "70%",
                      alignItems: "center",
                      transition: "all 0.3s",
                      "&:hover": {
                        cursor: "pointer",
                        background: "black",
                        borderColor: "black",
                      },
                      "&:hover *": {
                        color: "white",
                      },
                      "&:hover svg *": {
                        stroke: "white",
                      },
                    }}
                    onClick={() =>
                      navigate(
                        `/platform/coach/lara-analytics?clientName=${clientName}`
                      )
                    }
                    data-cy="explore-lara-analytics"
                  >
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "12px",
                        lineHeight: "18px",
                        fontWeight: 500,
                        letterSpacing: "0.05em",
                        textTransform: "capitalize",
                      }}
                    >
                      <span id="hint-anchor-1">Explore LARA analytics</span>
                    </Typography>
                  </Button>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <Pie
                  score={
                    isNaN(laraData?.listen_score) ? "-" : laraData.listen_score
                  }
                  radius={70}
                  color={"#62C1EB"}
                  textSize={"40px"}
                  fillStopColor={"#62C1EB"}
                />
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "black",
                  }}
                >
                  Listen
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <Pie
                  score={
                    isNaN(laraData?.affirm_score) ? "-" : laraData.affirm_score
                  }
                  radius={70}
                  color={"#568FF3"}
                  textSize={"40px"}
                  fillStopColor={"#568FF3"}
                />
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "black",
                  }}
                >
                  Affirm
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <Pie
                  score={
                    isNaN(laraData?.respond_score)
                      ? "-"
                      : laraData.respond_score
                  }
                  radius={70}
                  color={"#e383eb"}
                  textSize={"40px"}
                  fillStopColor={"#e383eb"}
                />
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "black",
                  }}
                >
                  Respond
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <Pie
                  score={isNaN(laraData?.ask_score) ? "-" : laraData.ask_score}
                  radius={70}
                  color={"#8e33b8"}
                  textSize={"40px"}
                  fillStopColor={"#8e33b8"}
                />
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "black",
                  }}
                >
                  Ask
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </Grid>
    </>
  );
};

export default LaraClientOverview;
