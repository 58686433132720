import { Box, Button, MenuItem, Modal, Select, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useFormik } from "formik";
import * as Yup from "yup";
import { v4 as uuid } from "uuid";
import { useState } from "react";
//import { API } from "aws-amplify";
import { toast } from "react-toastify";
import { Check, KeyboardArrowDown } from "@mui/icons-material";
import NegativeActionButton from "components/NegativeActionButton";
import PositiveActionButton from "components/PositiveActionButton";
import axios from "axios";

const ClientAddGoal = ({ user, clientData, coaches, loading, setLoading, fetchClientGoalsData }) => {
    const [openAddGoalModal, setOpenAddGoalModal] = useState(false);
    const [goalDate, setGoalDate] = useState("");
    const [selectedCoach, setSelectedCoach] = useState("");

    const CustomIcon = (props) => (
      <KeyboardArrowDown {...props} style={{ color: 'black' }} />
    );

    const handleClose = () => {
        setOpenAddGoalModal(false)
        formik2.resetForm();
        setGoalDate("")
      }
      const handleFormSubmit = (e) => {
        e.preventDefault();
        formik2.handleSubmit();
    };
    

    const formik2 =
    useFormik({
      initialValues: {
        goal_title: "",
        goal_description: "",
        goal_type: "",
        coach_id: "personal_goal"
      },
      enableReinitialize: true,
      validationSchema: Yup.object().shape({
        goal_title: Yup.string().required("required"),
        goal_description: Yup.string().required("required"),
      }),
      onSubmit: async (values) => {
        try {
          const body = {
            client_id: clientData.user_id,
            goal_id: uuid(),
            title: values.goal_title,
            description: values.goal_description,
            goal_type: values.goal_type,
            due_date: goalDate ? goalDate : null,
            created_by_userID: user?.sub,
            created_by_name: user?.given_name + " " + user?.family_name,
            coach_id: values.coach_id !== "personal_goal" ? values.coach_id : null,
            add: true,
          };
          setOpenAddGoalModal(false);
          setLoading(true);
          const response = await axios.put(`${process.env.REACT_APP_API_ENDPOINT}/zoom/save-client-goal-test`, body);
          if(response){
            toast(`Goal created`, {
              hideProgressBar: true,
              style: {
                background: "white",
                boxShadow: "14px 37px 17px rgba(136, 136, 136, 0.12)",
                borderRadius: "8px",
                padding: "16px 28px",
                color: "#160042",
              },
              icon: () => <Check color="success" />,
            });
          }
          await fetchClientGoalsData();
          setLoading(false);
        } catch (err) {
          console.log("Error in update", err.message);
          toast.error(err.message);
          setLoading(false);
        }
      },
    });

    const addGoalModal = (
        <Modal
          open={openAddGoalModal}
          onClose={() => handleClose()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              backgroundColor: "white",
              color: "black",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 1000,
              boxShadow: 24,
              padding: {
                xs: "24px",
                lg: "64px",
              },
              gap: {
                xs: "24px",
                lg: "unset",
              },
              borderRadius: "8px",
              display: "flex",
              flexDirection: {
                xs: "column",
                lg: "row",
              },
              justifyContent: "space-between",
              maxWidth: "calc(100% - 40px)",
              margin: "20px auto",
              overflowY: "auto",
            }}
          >
            <Box
              sx={{
                width: {
                  xs: "100%",
                  lg: "50%",
                },
                display: "flex",
                flexDirection: "column",
                gap: {
                  xs: "8px",
                  md: "16px",
                }
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Lexend",
                  maxWidth: {
                    md: "350px",
                  },
                  fontSize: "36px",
                  color: "black",
                }}
              >
                Create a New Goal
              </Typography>
              <Typography
                sx={{
                  maxWidth: {
                    md: "280px",
                  },
                  fontSize: "14px",
                  lineHeight: "1.5",
                  letterSpacing: "0.3px",
                  color: "black",
                }}
              >
                Create a personal goal or a goal that a team member can manage.
              </Typography>
            </Box>
            <Box
              sx={{
                width: {
                  xs: "100%",
                  lg: "50%",
                },
                maxWidth: {
                  xs: "100%",
                  lg: "340px",
                },
              }}
            >
              <form onSubmit={formik2.handleSubmit}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "22px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Lexend",
                        fontSize: "12px",
                        lineHeight: "1.5",
                        letterSpacing: "2px",
                        fontWeight: "bold",
                        color: "black",
                        textTransform: "capitalize"
                      }}
                    >
                      Goal Title
                    </Typography>
                    <TextField
                      required
                      fullWidth
                      autoFocus
                      error={formik2.touched.goal_title && Boolean(formik2.errors.goal_title)}
                      {...formik2.getFieldProps("goal_title")}
                      InputProps={{
                        style: {
                          color: "black",
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: "black",
                        },
                      }}
                      sx={{
                        color: "#22272F",
                        background: "white",
                        borderRadius: "16px",
                        width: "100%",
                        border: "1px solid #C4CAD4",
                        "&:before, &:after": {
                          display: "none",
                        },
                        "& fieldset": {
                          border: "none !important",
                        },
                      }}
                    />
                    {formik2.touched.goal_title && formik2.errors.goal_title && 
                      <Typography
                        variant="caption"
                        sx={{
                          color: "#BE0808",
                          letterSpacing: "2px",
                          fontSize: "8px",
                          fontWeight: "bold",
                          textTransform: "uppercase"       
                        }}
                      >
                        *{formik2.touched.goal_title && formik2.errors.goal_title}
                      </Typography>
                    }
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Lexend",
                        fontSize: "12px",
                        lineHeight: "1.5",
                        letterSpacing: "2px",
                        fontWeight: "bold",
                        color: "black",
                        textTransform: "capitalize"
                      }}
                    >
                      Description
                    </Typography>
                    <TextField
                      required
                      fullWidth
                      autoFocus
                      multiline
                      rows={4}
                      error={formik2.touched.goal_description && Boolean(formik2.errors.goal_description)}
                      {...formik2.getFieldProps("goal_description")}
                      InputProps={{
                        style: {
                          color: "black",
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: "black",
                        },
                      }}
                      sx={{
                        color: "#22272F",
                        background: "white",
                        borderRadius: "16px",
                        width: "100%",
                        border: "1px solid #C4CAD4",
                        "&:before, &:after": {
                          display: "none",
                        },
                        "& fieldset": {
                          border: "none !important",
                        },
                      }}
                    />
                    {formik2.touched.goal_description && formik2.errors.goal_description && 
                      <Typography
                        variant="caption"
                        sx={{
                          color: "#BE0808",
                          letterSpacing: "2px",
                          fontSize: "8px",
                          fontWeight: "bold",
                          textTransform: "capitalize"       
                        }}
                      >
                        *{formik2.touched.goal_description && formik2.errors.goal_description}
                      </Typography>
                    }
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <Typography
                     sx={{
                      fontFamily: "Lexend",
                      fontSize: "12px",
                      lineHeight: "1.5",
                      letterSpacing: "2px",
                      fontWeight: "bold",
                      color: "black",
                      textTransform: "capitalize"
                    }}
                    >
                      Due Date
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        format="YYYY-MM-DD"
                        onChange={(date) => {
                          if (date) {
                            const formattedDate = dayjs(date).format("YYYY-MM-DD");
                            setGoalDate(formattedDate);
                          }
                        }}
                        sx={{
                          borderRadius: "16px",
                          border: "1px solid #C4CAD4",
                          "& svg": {
                            color: "#22272F",
                          },
                          "& input": {
                            color: "#22272F",
                          },
                          "& fieldset": {
                            border: "none !important",
                          }
                        }}
                      />
                    </LocalizationProvider>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Lexend",
                        fontSize: "12px",
                        lineHeight: "1.5",
                        letterSpacing: "2px",
                        fontWeight: "bold",
                        color: "black",
                        textTransform: "capitalize"
                      }}
                    >
                      Coach
                    </Typography>
                    <Select
                      fullWidth
                      required
                      value={formik2.values.coach_id}
                      onChange={(event) => {
                        formik2.setFieldValue('coach_id', event.target.value);
                        setSelectedCoach(event.target.value);
                      }}
                      IconComponent={CustomIcon}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            backgroundColor: 'white',
                          },
                        },
                      }}
                      sx={{
                        color: "black",
                        background: "white",
                        borderRadius: "16px",
                        border: "1px solid #C4CAD4",
                        "&:before, &:after": {
                          display: "none",
                        },
                        "& fieldset": {
                          border: "none !important",
                        },
                      }}
                    >
                      <MenuItem value="personal_goal" sx={{background: "white", color: "black"}}>
                        Personal Goal
                      </MenuItem>
                      {coaches.length > 0 && coaches.map((coach) => (
                        <MenuItem key={coach.user_id} value={coach.user_id} sx={{background: "white", color: "black"}}>
                          {coach.full_name}
                        </MenuItem>
                      ))}
                    </Select>
                    {formik2.touched.coach_id && formik2.errors.coach_id && 
                      <Typography
                        variant="caption"
                        sx={{
                          color: "#BE0808",
                          letterSpacing: "2px",
                          fontSize: "8px",
                          fontWeight: "bold",
                          textTransform: "uppercase"       
                        }}
                      >
                        *{formik2.touched.coach_id && formik2.errors.coach_id}
                      </Typography>
                    }
                  </Box>
                </Box>
                <Box
                  marginTop="42px"
                  display="flex"
                  justifyContent="flex-end"
                  gap="15px"
                >
                  
                  <NegativeActionButton 
                    onClick={handleClose}
                    label={"Cancel"}
                  />
                <PositiveActionButton 
                    onClick={handleFormSubmit}
                    label="Create Goal"
                    disabled={!formik2.values.goal_title || !formik2.values.goal_description || !formik2.values.coach_id || !goalDate}
                />
                </Box>
              </form>
            </Box>
          </Box>
        </Modal>
      )

    return(
      <>
        {addGoalModal}
        <Button
            onClick={() => setOpenAddGoalModal(true)}
            sx={{
            width: "165px",
            padding: "10px 0",
            background:  "linear-gradient(315deg, #0076BA 0%, #03093A 60%, #242F8C 100%)",
            borderRadius: "52px",
            display: "flex",
            alignItems: "center",
            gap: "10px",
            transition: "all 0.3s",
            "& svg": {
                fill: "white",
            },
            "&:hover": {
              background: "linear-gradient(315deg, rgba(0, 118, 186, 0.8) 0%, rgba(3, 9, 58, 0.8) 60%, rgba(36, 47, 140, 0.8) 100%)",

            },
            }}
        >
            <Typography
                sx={{
                    fontFamily: "Poppins",
                    color: "white",
                    fontSize: "12px",
                    lineHeight: "18px",
                    fontWeight: "500",
                    letterSpacing: "0.05em",
                    textTransform: "none",
                }}
                >
                Add a Goal
            </Typography>
        </Button>
      </>
    );
}

export default ClientAddGoal;