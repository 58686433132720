/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import * as Frigade from "@frigade/react";
import { Box, Typography, TextField, useTheme, useMediaQuery, Button } from "@mui/material";
import { v4 as uuid } from "uuid";
import ReactMarkdown from 'react-markdown'
import vcProfileImage from '../../assets/vc-profile-picture.png';
import ContentLibraryCloseIcon from 'components/Icons/ContentLibraryCloseIcon.svg';
import ContentLibraryOpenIcon from 'components/Icons/ContentLibraryOpenIcon.svg';
import { _FilterContentCategory } from './Filters/FilterContentCategory';
import { _FilterContentFacilitator } from './Filters/FilterContentFacilitator';
import { _FilterContentFormat } from './Filters/FilterContentFormat';
import PaginationForContentLibrary from './PaginationForContentLibrary';
import ContentTitleSearch from './Filters/ContentTitleSearch';
import VirtualCoachSendIcon from '../Icons/VirtualCoachSendIcon.svg';
import UserGuideIcon from 'components/Icons/UserGuideIcon.svg';
import CircularProgress from '@mui/material/CircularProgress';

import axios from 'axios';
import remarkGfm from 'remark-gfm';
import TypingLottie from './TypingLottie';
import { toast } from 'react-toastify';
import LoadingSkeleton from './LoadingSkeleton';
import VideoPlayer from './VideoPlayer';
import StartingPrompts from './StartingPrompts';
import NewThreadIcon from '../Icons/NewThreadIcon.svg';
import ToggleThreadSidebarIcon from 'components/Icons/ToggleThreadSidebarIcon.svg';
import DeleteThreadIcon from 'components/Icons/DeleteThreadIcon.svg'
import VideoIconForVC from 'components/Icons/VideoIconForVC.svg';
import PdfIconForVC from '../../components/Icons/PdfIconForVC.svg'
import PdfModal from './Pdf/PdfModal';
import PdfDownloadIconForVC from 'components/Icons/PdfDownloadIconForVC';
import PdfFullscreenIcon from 'components/Icons/PdfFullscreenIcon';

const RetuneIframe = ({ user, userData }) => {
    const userId = userData?.oldUserId || user?.sub;
    const baseApiUrl = process.env.REACT_APP_VC_BASE_API_URL;
    // const baseApiUrl = 'http://localhost:8000';

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Mobile screens
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md')); // Tablet screens
    const isDesktop = useMediaQuery(theme.breakpoints.up('md')); // Desktop and larger screens

    const [openPdfModal, setOpenPdfModal] = useState(false)

    const [threadNames, setThreadNames] = useState([]);
    const [selectedThread, setSelectedThread] = useState(null);
    const [selectedThreadMessages, setSelectedThreadMessages] = useState([]);

    const [todaysThreads, setTodaysThreads] = useState([]);
    const [last7DaysThreads, setLast7DaysThreads] = useState([]);
    const [moreThan7DaysThreads, setMoreThan7DaysThreads] = useState([]);

    const [loading, setLoading] = useState(false);
    const [loadingForAiResponse, setLoadingForAiResponse] = useState(false);
    const [showLoadingSkeleton, setShowLoadingSkeleton] = useState(false);
    const [showPaginationBar, setShowPaginationBar] = useState(true);
    const [paginationSelectedPage, setPaginationSelectedPage] = useState(1);
    const [paginationCount, setPaginationCount] = useState(670);
    const [selectedVideoInfo, setSelectedVideoInfo] = useState({});
    const [selectedPdfUrl, setSelectedPdfUrl] = useState('');

    const [newMessageFromInput, setNewMessageFromInput] = useState('');
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [contentLibraryOpen, setContentLibraryOpen] = useState(false);
    const [s3UrToken, setS3UrToken] = useState('');

    const [filteredContentLibraryData, setFilteredContentLibraryData] = useState([])
    const [searchQuery, setSearchQuery] = useState(''); // For title search
    const [selectedTitle, setSelectedTitle] = useState({}); // For title filter

    const [loadingContentLibrary, setLoadingContentLibrary] = useState(false);

    const [selectedCategory, setSelectedCategory] = useState('none');
    const [selectedFacilitator, setSelectedFacilitator] = useState('none');
    const [selectedFormat, setSelectedFormat] = useState('none');

    const [categoryOptions, setCategoryOptions] = useState([])
    const [facilitatorOptions, setFacilitatorOptions] = useState([]);
    const [formatOptions, setFormatOptions] = useState([])

    const [isNewThreadBtnHovered, setIsNewThreadBtnHovered] = useState(false);
    const [isUserGuideBtnHovered, setIsUserGuideBtnHovered] = useState(false);

    const messageRef = useRef(null);

    useEffect(() => {
        const effectFunction = async () => {
            await getThreadNames()
            await fetchContentFilterOption()
            await getTokenForUrlPrefix()
        }
        effectFunction()

    }, [])

    useEffect(() => {

        const effectFunction = async () => {

            // console.log('selected title changed', selectedTitle);
            setShowPaginationBar(false);
            if (!selectedTitle.suggestion) {
                await fetchContentLibrary();
                return;
            }
            if (selectedTitle.type === 'title') {
                await fetchContentByTitle();
                return;
            }
            if (selectedTitle.type === 'keyword') {
                await fetchContentByKeyword();
                return;
            }
            if (selectedTitle.type === 'rawInput') {
                await fetchContentWithoutUsingSuggestions();
                return;
            }

        }
        effectFunction()

    }, [selectedTitle]);

    useEffect(() => {
        const effectFunction = async () => {
            if (selectedThread?.startsWith('new-thread') || !selectedThread) {
                setLoading(false);
                const newThreadMessage = {
                    id: uuid(),
                    role: 'assistant',
                    content: 'What would you like to learn?',
                    newThread: true
                }
                setSelectedThreadMessages([newThreadMessage])
                return;
            }
            await getSingleThreadMessages(selectedThread)
            scrollToBottom();
        }
        effectFunction()
    }, [selectedThread])

    useEffect(() => {
        if (loading === false) scrollToBottom();
    }, [loading])

    useEffect(() => () => {
        scrollToBottom();
    }, [loadingForAiResponse])

    useEffect(() => {
        setPaginationSelectedPage(1);
        fetchFilteredData();
    }, [selectedFormat, selectedFacilitator, selectedCategory]);

    useEffect(() => {
        if (!threadNames.length) return;
        const todayItems = threadNames?.filter(item => isToday(item?.lastMessageAt));
        const last7DaysItems = threadNames?.filter(item => isLastSevenDays(item?.lastMessageAt));
        const olderThan7DaysItems = threadNames?.filter(item => isOlderThanSevenDays(item?.lastMessageAt));

        setTodaysThreads(todayItems);
        setLast7DaysThreads(last7DaysItems);
        setMoreThan7DaysThreads(olderThan7DaysItems);

    }, [threadNames])

    const toggleSidebar = () => {
        setSidebarOpen((prevState) => !prevState);
    }

    const handleOpenPdfModal = () => setOpenPdfModal(true);
    const handleClosePdfModal = () => setOpenPdfModal(false);

    const handlePromptSelection = (prompt, format) => {
        setNewMessageFromInput(prompt);
        if (format === 'Video') {
            setNewMessageFromInput(`${prompt} Provide the video.`);
        } else if (format === 'Download') {
            setNewMessageFromInput(`${prompt} Provide the PDF.`);
        } else {
            setNewMessageFromInput(prompt);
        }
    };

    const toggleContentLibrary = () => {
        setContentLibraryOpen((prevState => !prevState));
    }

    const scrollToBottom = () => {
        messageRef.current?.scrollIntoView({ behavior: "smooth" });
    }

    const fetchContentLibrary = async (page = 1) => {
        try {
            setLoadingContentLibrary(true);
            const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/zoom/get-content-library-data`, {
                params: {
                    limit: 10,
                    page: page,
                    shouldGetCount: page === 1 ? true : false,

                }
            })
            setFilteredContentLibraryData(response.data.result)
            if (response.data.count !== 'null') setPaginationCount(response.data.count);
            setLoadingContentLibrary(false);
            setShowPaginationBar(true);
        } catch (error) {
            console.error("Error fetching content library:", error);
            toast.error('Oops! Something went wrong. Please Reload')
            setLoadingContentLibrary(false);
        }
    };

    const fetchFilteredData = async (page = 1) => {
        setSearchQuery('');
        setSelectedTitle('');
        setShowPaginationBar(true);
        try {
            if (selectedFormat === 'none' && selectedCategory === 'none' && selectedFacilitator === 'none') {
                await fetchContentLibrary(page);
                return;
            }
            setLoadingContentLibrary(true);
            const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/zoom/get-filtered-content-library`, {
                params: {
                    format: selectedFormat,
                    facilitator: selectedFacilitator,
                    category: selectedCategory,
                    limit: 10,
                    page: page,
                    shouldGetCount: page === 1 ? true : false,
                },
            });
            setFilteredContentLibraryData(response.data.result);
            if (response.data.count !== 'null') setPaginationCount(response.data.count);
            setLoadingContentLibrary(false);
        } catch (error) {
            console.error('Error fetching filtered data:', error);
            toast.error('Oops! Something went wrong. Please Reload')
            setLoadingContentLibrary(false);
        }
    };

    const fetchContentByTitle = async () => {
        if (!selectedTitle) return;
        try {
            setLoadingContentLibrary(true);
            const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/zoom/get-content-by-title`, {
                params: {
                    input: selectedTitle.suggestion,
                },
            });
            setFilteredContentLibraryData(response.data.result);
            setLoadingContentLibrary(false);
        } catch (error) {
            console.error('Error fetching filtered data:', error);
            toast.error('Oops! Something went wrong. Please Reload')
            setLoadingContentLibrary(false);
        }
    };

    const fetchContentByKeyword = async (page = 1) => {
        try {
            setLoadingContentLibrary(true);
            const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/zoom/get-content-by-keyword`, {
                params: {
                    input: selectedTitle.suggestion,
                    limit: 10,
                    page: page,
                    shouldGetCount: page === 1 ? true : false,
                }
            })
            setFilteredContentLibraryData(response.data.result)
            if (response.data.count !== 'null') setPaginationCount(response.data.count);
            setLoadingContentLibrary(false);
            setShowPaginationBar(true);
        } catch (error) {
            console.error("Error fetching content library:", error);
            toast.error('Oops! Something went wrong. Please Reload')
            setLoadingContentLibrary(false);
        }
    };

    const fetchContentWithoutUsingSuggestions = async (page = 1) => {
        try {
            setLoadingContentLibrary(true);
            const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/zoom/get-content-without-using-suggestions`, {
                params: {
                    input: selectedTitle.suggestion,
                    limit: 10,
                    page: page,
                    shouldGetCount: page === 1 ? true : false,

                }
            })
            console.log('fetched data -----------', response.data.result);
            setFilteredContentLibraryData(response.data.result)
            if (response.data.count !== 'null') setPaginationCount(response.data.count);
            setLoadingContentLibrary(false);
            setShowPaginationBar(true);
        } catch (error) {
            console.error("Error fetching content library:", error);
            toast.error('Oops! Something went wrong. Please Reload')
            setLoadingContentLibrary(false);
        }
    };

    const getSingleThreadMessages = async (threadId) => {
        try {
            setLoading(true);
            setSelectedThreadMessages([])
            setShowLoadingSkeleton(true);
            const response = await axios.post(`${baseApiUrl}/get-single-thread-messages`, { userId, threadId })
            const responseDataThreadMessages = response.data.threadWithMessages?.messages;
            const redoneThreadMessages = await redoThreadMessages(responseDataThreadMessages)
            // console.log('Redone Thread Messages:', redoneThreadMessages);
            setSelectedThreadMessages(redoneThreadMessages)
            setLoading(false);
            setShowLoadingSkeleton(false)
        } catch (error) {
            console.log(error);
            setLoading(false);
            setShowLoadingSkeleton(false)
        }
    }

    const getThreadNames = async () => {
        try {
            const response = await axios.post(`${baseApiUrl}/get-thread-names`, { userId })

            // Check if response and response.data.thread are valid
            if (response && response.data && Array.isArray(response.data.threads)) {
                const responseDataThread = response.data.threads;
                // console.log('Response from get-thread-names', responseDataThread);

                const sortedThreadsData = responseDataThread.sort((a, b) => new Date(b.messages[0].createdAt) - new Date(a.messages[0].createdAt))
                const threadNamesArray = sortedThreadsData.map((thread) => {
                    const lastMessage = thread.messages && thread.messages.length > 0
                        ? thread.messages[0].content
                        : 'No messages'; // Provide fallback if there are no messages

                    return {
                        id: thread.id || uuid(), // Provide fallback for missing ID
                        name: thread.name ? thread.name : lastMessage,
                        lastMessageAt: thread.messages[0].createdAt,
                    }
                })


                setThreadNames(threadNamesArray);
                handleClickNewTheread();
            } else {
                toast.error('No thread data available. Please try again.');
            }
        } catch (error) {
            console.log(error);
            toast.error('Oops! Something went wrong. Please reload.');
        }
    }

    function isToday(utcDateStr) {
        if (!utcDateStr) return false;

        try {
            const localDate = new Date(utcDateStr);
            if (isNaN(localDate.getTime())) return false;

            const today = new Date();

            // Compare just the date portion
            return localDate.getFullYear() === today.getFullYear() &&
                localDate.getMonth() === today.getMonth() &&
                localDate.getDate() === today.getDate();
        } catch (error) {
            // console.error('Error in isToday:', error);
            return false;
        }
    }

    function isLastSevenDays(utcDateStr) {
        if (!utcDateStr) return false;

        try {
            const localDate = new Date(utcDateStr);
            if (isNaN(localDate.getTime())) return false;

            const today = new Date();
            const todayStart = new Date(today.getFullYear(), today.getMonth(), today.getDate());

            const sevenDaysAgo = new Date(todayStart);
            sevenDaysAgo.setDate(todayStart.getDate() - 7);

            const dateToCompare = new Date(localDate.getFullYear(), localDate.getMonth(), localDate.getDate());

            // Check if date is between 7 days ago and yesterday
            return dateToCompare >= sevenDaysAgo && dateToCompare < todayStart;
        } catch (error) {
            console.error('Error in isLastSevenDays:', error);
            return false;
        }
    }

    function isOlderThanSevenDays(utcDateStr) {
        if (!utcDateStr) return false;

        try {
            const localDate = new Date(utcDateStr);
            if (isNaN(localDate.getTime())) return false;

            const today = new Date();
            const todayStart = new Date(today.getFullYear(), today.getMonth(), today.getDate());

            const sevenDaysAgo = new Date(todayStart);
            sevenDaysAgo.setDate(todayStart.getDate() - 7);

            const dateToCompare = new Date(localDate.getFullYear(), localDate.getMonth(), localDate.getDate());

            // Check if date is before 7 days ago
            return dateToCompare < sevenDaysAgo;
        } catch (error) {
            console.error('Error in isOlderThanSevenDays:', error);
            return false;
        }
    }

    const getCurrentTimeInUTC = () => {
        const now = new Date();
        const formatted = now.toISOString().replace('T', ' ').replace('Z', '');
        return formatted;
    }

    const handleClickNewTheread = () => {
        if (selectedThreadMessages?.length === 1 && selectedThreadMessages[0]?.newThread) return;
        const newThreadId = `new-thread${uuid()}`;
        console.log('New Thread ID: ---------', newThreadId);

        const newThreadMessage = {
            id: uuid(),
            role: 'assistant',
            content: 'What would you like to learn?',
            newThread: true
        }

        setSelectedThread(newThreadId)
        setSelectedThreadMessages([newThreadMessage])
        setThreadNames((prevState) => [{ id: newThreadId, name: "Start learning", lastMessageAt: getCurrentTimeInUTC() }, ...prevState])
    }

    const deleteThread = async (threadId, threadName) => {
        try {
            await axios.post(`${baseApiUrl}/delete-a-thread`, { userId, threadId })
            const newThreads = threadNames.filter(thread => thread.id !== threadId);
            setThreadNames(newThreads);
            setSelectedThread(newThreads[0].id);
            toast.success(`Thread '${threadName}' Deleted Successfully!`)
        } catch (error) {
            console.log(error);
            toast.error('Failed to delete thread. Please try again!')
        }
    }

    const handlePaginationChange = async (event, value) => {
        setPaginationSelectedPage(value);
        if (setCategoryOptions === 'none' && selectedFacilitator === 'none' && selectedFormat === 'none') {
            await fetchContentLibrary(value)
        } else {
            await fetchFilteredData(value)
        }

    }

    const changeThreadNameAndIdOfNewThread = (threadName, oldThreadId, newThreadId) => {
        const threadNamesArray = [...threadNames];
        const indexOfSelectedThread = threadNamesArray.findIndex(thread => thread.id === oldThreadId);
        threadNamesArray[indexOfSelectedThread].name = threadName;
        threadNamesArray[indexOfSelectedThread].id = newThreadId;
        setThreadNames(threadNamesArray);
        setSelectedThread(newThreadId);
    }

    const sendMessageToBackend = async () => {
        if (!newMessageFromInput.trim()) return;
        const newMessageFromInputVar = newMessageFromInput.trim();
        setNewMessageFromInput('');
        setLoadingForAiResponse(true);
        console.log('Message sent to backend', newMessageFromInputVar);
        const inputTextId = uuid();

        const inputMessageObject = {
            id: uuid(),
            role: 'user',
            content: newMessageFromInputVar
        }

        setSelectedThreadMessages((prevState) => [...prevState, inputMessageObject])
        const isNewAndEmptyThread = selectedThreadMessages[selectedThreadMessages.length - 1].newThread || selectedThreadMessages[selectedThreadMessages.length - 2].newThread ? true : false
        try {
            setLoading(true);
            const response = await axios.post(`${baseApiUrl}/get-ai-response`, {
                userId,
                threadId: isNewAndEmptyThread && selectedThread?.startsWith('new-thread') ? selectedThread.slice(10) : selectedThread,
                inputText: newMessageFromInputVar,
                inputTextId,
                shouldCreateNewThread: isNewAndEmptyThread
            })
            const aiResponseMessage = response.data
            const redoneThreadMessagesArray = await redoThreadMessages([aiResponseMessage])
            const redoneThreadMessages = redoneThreadMessagesArray[0]
            setSelectedThreadMessages((prevState) => [...prevState, redoneThreadMessages])
            if (aiResponseMessage.threadName) {
                changeThreadNameAndIdOfNewThread(aiResponseMessage.threadName, selectedThread, selectedThread.slice(10))
            }
            setLoadingForAiResponse(false);
            setLoading(false);

        } catch (error) {
            console.log(error);
            setLoadingForAiResponse(false);
            toast.error('Oops! Something went wrong. Please Reload!')
        }

    }

    const truncateThreadName = (input) => {
        // Step 1: Remove URLs
        const cleanedInput = input.replace(/https?:\/\/[^\s]+/g, '');

        // Step 2: Remove asterisks (*)
        const noAsterisksInput = cleanedInput.replace(/\*/g, '');

        // Step 3: Truncate the string to 50 characters if needed
        return noAsterisksInput.length > 50
            ? `${noAsterisksInput.substring(0, 50)}...`
            : noAsterisksInput;
    };

    const getTokenForUrlPrefix = async () => {
        try {
            const timestamp = Math.floor(Date.now() / 1000 + 604799);
            // const proxyApiKey = process.env.PROXY_API_KEY
            const proxyApiKey = "CNhriIPmW41qPSnVeg1xr1qWvxagEjAe9XUxuWQX"

            const response = await fetch(`https://9msizvyhec.execute-api.us-east-1.amazonaws.com/default/token_generator?apiKey=${proxyApiKey}&timestamp=${timestamp}`)

            const responseJson = await response.json();
            const token = responseJson.token;
            setS3UrToken(token)
        } catch (error) {
            console.log('error while getting token ', error);
            toast.error('Oops! Something went wrong. Please Reload!')
        }

    }

    const generateProxyUrl = async (url) => {
        const videoTimeStamp = url.split("#####").pop();
        const urlWithoutTimestamp = url.split("#####")[0];
        let prefix = '';
        let type = '';
        if (url.includes('https://video.altercall.com/')) {
            const base64 = urlWithoutTimestamp.split("https://video.altercall.com/").pop();
            prefix = atob(base64);
            type = 'video';
        } else {
            const base64 = urlWithoutTimestamp.split("https://download.altercall.com/").pop();
            prefix = atob(base64);
            type = 'pdf';
        }

        try {
            const proxyGenUrl = new URL(
                `https://jd6b6g80f6.execute-api.us-east-1.amazonaws.com/default/proxy_gen`,
            );

            proxyGenUrl.searchParams.set("keyPrefix", prefix);
            proxyGenUrl.searchParams.set("token", s3UrToken);

            const response = await fetch(proxyGenUrl)
            const proxyUrl = await response.json();
            // console.log('proxy url -------', proxyUrl);
            return {
                url: proxyUrl,
                type,
                videoTimeStamp: type === "video" && videoTimeStamp ? videoTimeStamp : "null"
            }
        } catch (error) {
            console.log('Error in getting proxy url', error);
            toast.error('Oops! Something went wrong. Please Reload!')
        }

    }

    const getProxyUrls = async (urlArray) => {
        if (!urlArray?.length) return
        const validUrlsArray = await Promise.all(
            urlArray?.map(async (url) => {
                const validUrl = await generateProxyUrl(url);
                return validUrl;
            })
        )
        return validUrlsArray;
    }

    const extractUrlFromMessageContent = (content) => {
        const urlRegex = /(?<=\()\bhttps?:\/\/[^\s)]+(?=\))/g;
        const urlArray = content.match(urlRegex);
        return urlArray;
    }

    const redoThreadMessages = async (messagesArrayParam) => {
        if (!messagesArrayParam) return;
        if (messagesArrayParam[0]?.newThread) return messagesArrayParam;

        let newThreadObject = []

        // Create an array of promises for processing each message
        const promises = messagesArrayParam.map(async (message) => {
            const urlArray = extractUrlFromMessageContent(message.content);

            if (urlArray) {
                const validUrlsArray = await getProxyUrls(urlArray); // url, type, videoTimeStamp
                let newMessageContent = message.content;

                // Replace each URL with its corresponding proxy URL
                urlArray.forEach((url, index) => {
                    const newUrl = validUrlsArray[index].url + '#####' + validUrlsArray[index].type + '-videoTimeStamp=' + validUrlsArray[index].videoTimeStamp;
                    newMessageContent = newMessageContent.replace(url, newUrl);
                });

                // // Remove everything between parentheses (globally) (Remove Links)
                // const linksRemovedContent = newMessageContent.replace(/\(.*?\)/g, '');

                // // Replace [ and ] with ** (Make the media titles bold)
                // const mediaTitlesBoldContent = linksRemovedContent.replace(/\[|\]/g, '**');

                // If needed filter out only the video urls in the validUrlsArray
                const videoUrlsArray = validUrlsArray.filter(url => url.type === 'video');

                return {
                    ...message,
                    content: newMessageContent,
                    metadata: videoUrlsArray
                };
            }

            return message;
        });

        newThreadObject = await Promise.all(promises);

        return newThreadObject;

    }

    const getConvoAreaWidth = () => {
        // Base logic for sidebar and content library open/closed
        if (!sidebarOpen && !contentLibraryOpen) {
            if (isMobile) return '100%';
            if (isTablet) return '100%';
            if (isDesktop) return '100%';
        }

        if (sidebarOpen && !contentLibraryOpen) {
            if (isMobile) return '0%';
            if (isTablet) return '0%';
            if (isDesktop) return '78%';
        }

        if (contentLibraryOpen && !sidebarOpen) {
            if (isMobile) return '0%';
            if (isTablet) return '0%';
            if (isDesktop) return '60%';
        }

        if (contentLibraryOpen && sidebarOpen) {
            if (isMobile) return '0%';
            if (isTablet) return '0%';
            if (isDesktop) return '40%';
        }

    };

    const handleMarkdownLinkClick = (event, props, messageId) => {
        event.preventDefault(); // Prevent default link behavior
        const rawUrl = props.href;
        const url = rawUrl.split('#####')[0];
        const typeAndTimeStamp = rawUrl.split('#####').pop();
        const typeAndTimeStampArray = typeAndTimeStamp.split('-videoTimeStamp=')
        const type = typeAndTimeStampArray[0];
        const videoTimeStamp = typeAndTimeStampArray[1];
        if (type === 'pdf') {
            // window.open(url, '_blank');
            // return;
            setSelectedPdfUrl(url);
            handleOpenPdfModal();
            return;
        }
        if (type === 'video') {
            setSelectedVideoInfo((prevState) => ({ ...prevState, [messageId]: { url, type, videoTimeStamp } }))
        }
    };

    const getUrlInfo = (props) => {
        const rawUrl = props.href;
        const url = rawUrl.split('#####')[0];
        const typeAndTimeStamp = rawUrl.split('#####').pop();
        const typeAndTimeStampArray = typeAndTimeStamp.split('-videoTimeStamp=')
        const type = typeAndTimeStampArray[0];
        const videoTimeStamp = typeAndTimeStampArray[1];
        return { url, type, videoTimeStamp }
    }

    const fetchContentFilterOption = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/zoom/get-content-library-filter-options`);
            const fetchedData = response.data
            const formats = fetchedData.format.map((data) => data.format)
            const facilitator = fetchedData.facilitator.map((data) => data.facilitator)
            const categories = fetchedData.category.map((data) => data.sub_category)
            setFormatOptions(formats)
            setFacilitatorOptions(facilitator.sort())
            setCategoryOptions(categories.sort())
        } catch (error) {
            console.log(error);
            toast.error('Something went wrong')
        }
    }

    const handleContentLibraryFilterOptionChange = async (field, value) => {
        if (field === 'category') setSelectedCategory(value);
        if (field === 'facilitator') setSelectedFacilitator(value);
        if (field === 'format') setSelectedFormat(value);

        // Build the query
        const query = new URLSearchParams({
            selectedFormat: field === 'format' ? value : selectedFormat === 'none' ? '' : selectedFormat,
            selectedFacilitator: field === 'facilitator' ? value : selectedFacilitator === 'none' ? '' : selectedFacilitator,
            selectedCategory: field === 'category' ? value : selectedCategory === 'none' ? '' : selectedCategory,
        }).toString();

        try {

            const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/zoom/get-dynamic-content-library-filter-options?${query}`);
            const fetchedData = response.data
            // console.log('fetched data after filter change -----------', fetchedData);

            if (selectedCategory === 'none' && field !== 'category') {
                const categories = fetchedData.categoryOptions;
                setCategoryOptions(categories.sort())
            }

            if (selectedFacilitator === 'none' && field !== 'facilitator') {
                const facilitator = fetchedData.facilitatorOptions;
                setFacilitatorOptions(facilitator.sort())
            }

            if (selectedFormat === 'none' && field !== 'format') {
                const formats = fetchedData.formatOptions;
                setFormatOptions(formats)
            }



        } catch (error) {
            console.log(error);
            toast.error('Something went wrong')
        }


    }

    const handleFilterOptionCancel = async (field) => {
        if (field === 'category') setSelectedCategory('none');
        if (field === 'facilitator') setSelectedFacilitator('none');
        if (field === 'format') setSelectedFormat('none');

        // Build the query
        const query = new URLSearchParams({
            selectedFormat: field === 'format' ? '' : selectedFormat === 'none' ? '' : selectedFormat,
            selectedFacilitator: field === 'facilitator' ? '' : selectedFacilitator === 'none' ? '' : selectedFacilitator,
            selectedCategory: field === 'category' ? '' : selectedCategory === 'none' ? '' : selectedCategory,
        }).toString();

        try {

            const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/zoom/get-dynamic-content-library-filter-options?${query}`);
            const fetchedData = response.data
            // console.log('fetched data after cancelling filter  -----------', fetchedData);

            if (selectedCategory === 'none' || field === 'category') {
                const categories = fetchedData.categoryOptions;
                setCategoryOptions(categories.sort())
            }

            if (selectedFacilitator === 'none' || field === 'facilitator') {
                const facilitator = fetchedData.facilitatorOptions;
                setFacilitatorOptions(facilitator.sort())
            }

            if (selectedFormat === 'none' || field === 'format') {
                const formats = fetchedData.formatOptions;
                setFormatOptions(formats)
            }


        } catch (error) {
            console.log(error);
            toast.error('Something went wrong')
        }
    }

    return (
        <Box sx={{
            height: '100%',
            width: '100%',
            maxWidth: "100%",
            display: 'flex',
            boxSizing: 'border-box',
            fontFamily: "Lexend",
            position: 'relative',

        }}>

            {/* Floating buttons for sidebar toggle, new thread creation, userguide download */}
            <Box
                sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '20px',
                    height: '6%',
                    position: 'absolute',
                    top: '20px',
                    left: '25px',
                    display: {
                        xs: contentLibraryOpen ? 'none' : 'flex',
                        sm: contentLibraryOpen ? 'none' : 'flex',
                        md: 'flex',
                    },
                }}
            >
                {/* Sidebar Toggler */}
                <span id="hint-anchor-3">
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",   // Center vertically,
                            textAlign: "center",
                            cursor: 'pointer',
                            borderRadius: '50%',
                            width: '52px',          // Match the icon size
                            height: '52px',         // Match the icon size
                            transition: 'background-color 0.6s ease',
                            '&:hover': {
                                backgroundColor: '#EFF3F6'
                            }
                        }}
                        onClick={toggleSidebar}
                    >
                        <ToggleThreadSidebarIcon />
                    </Box>
                </span>
                {/*  New Thread For Mobile */}
                <Box sx={{
                    padding: '8px',
                    cursor: 'pointer',
                    // border: '2px solid black',
                    backgroundColor: 'black',
                    borderRadius: '50%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: {
                        xs: 'flex',
                        sm: 'flex',
                        md: 'none',
                    },
                }}
                    onClick={handleClickNewTheread}
                >
                    <NewThreadIcon color={"#fff"} />
                </Box>

                {/*  New Thread For Dekstop */}
                {/* Working Here ------------------ */}
                <Box
                    onMouseEnter={() => setIsNewThreadBtnHovered(true)}
                    onMouseLeave={() => setIsNewThreadBtnHovered(false)}
                    onClick={handleClickNewTheread}
                    sx={{
                        display: {
                            xs: 'none',
                            sm: 'none',
                            md: 'flex',
                        },
                        alignItems: 'center',
                        gap: '10px',
                        backgroundColor: isNewThreadBtnHovered ? 'black' : 'white',
                        border: '2px solid black',
                        ml: sidebarOpen ? "250px" : "0px",
                        borderRadius: '50px',
                        paddingX: '20px',
                        paddingY: '8px',
                        cursor: 'pointer',
                        transition: 'background-color 0.3s ease, margin-left 0.4s ease',
                    }}>
                    <NewThreadIcon
                        color={isNewThreadBtnHovered ? "#FFFFFF" : "#111111"}
                    />

                    <Typography
                        sx={{
                            font: '10px',
                            fontWeight: 500,
                            color: isNewThreadBtnHovered ? "#FFFFFF" : "#111111"
                        }}> <span id="hint-anchor-4" >New Thread</span>
                    </Typography>
                </Box>

                {/* Download user guide for mobile */}
                <Box sx={{
                    display: {
                        xs: 'block',
                        sm: 'block',
                        md: 'none',
                    },
                }}>
                    <a href="https://ac-media-files.s3.amazonaws.com/guides/Virtual+Coach+Guide+V2.pdf"
                        download
                        target="_blank"
                        rel='noreferrer'
                        style={{ textDecoration: "none" }}>
                        <Box sx={{
                            padding: '7px',
                            cursor: 'pointer',
                            border: '2px solid black',
                            borderRadius: '50%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}

                        >
                            <UserGuideIcon />
                        </Box>
                    </a>
                </Box>

                {/* Download user guide for desktop */}
                <Box sx={{
                    display: {
                        xs: 'none',
                        sm: 'none',
                        md: 'block',
                    },

                }}>
                    <a href="https://ac-media-files.s3.amazonaws.com/guides/Virtual+Coach+Guide+V2.pdf"
                        download
                        target="_blank"
                        rel='noreferrer'
                        style={{ textDecoration: "none" }}>


                        <Box
                            onMouseEnter={() => setIsUserGuideBtnHovered(true)}
                            onMouseLeave={() => setIsUserGuideBtnHovered(false)}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                                backgroundColor: isUserGuideBtnHovered ? 'black' : 'white',
                                border: '2px solid black',
                                borderRadius: '50px',
                                paddingX: '20px',
                                paddingY: '8px',
                                cursor: 'pointer',
                                transition: 'background-color 0.3s ease',


                            }}>
                            <UserGuideIcon color={isUserGuideBtnHovered ? '#FFFFFF' : '#111111'} />
                            <Typography
                                sx={{
                                    font: '10px',
                                    fontWeight: 500,
                                    color: isUserGuideBtnHovered ? 'white' : 'black'
                                }}>User Guides</Typography>
                        </Box>
                    </a>
                </Box>
            </Box>

            {/* The thread area */}
            <Box sx={{
                width: {
                    xs: sidebarOpen ? '60%' : '0%',
                    sm: sidebarOpen ? '40%' : '0%',
                    md: sidebarOpen ? '22%' : '0%',
                },
                transition: "width 0.5s ease",
                height: '100%',
                // border: '1px solid green',
                display: 'flex',
                border: '0',
                alignItems: 'end',
                borderTopLeftRadius: { xs: '0px', md: '40px' },
                borderBottomLeftRadius: '40px',
                backgroundColor: '#D9E4EC',
                overflow: 'hidden',
            }}>

                <Box sx={{
                    width: '100%',
                    // border: '1px solid red',
                    height: '87%',
                    paddingY: '20px',
                    paddingX: sidebarOpen ? '15px' : '0px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    boxSizing: 'border-box',
                    overflowY: 'scroll',
                    scrollbarWidth: 'none',
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    },

                }}>
                    <Typography
                        sx={{
                            color: 'black',
                            fontWeight: '500',
                            fontFamily: 'Poppins',
                            fontSize: '14px',
                            paddingLeft: '7px',
                        }}
                    >Today</Typography>
                    {todaysThreads &&
                        todaysThreads?.map((thread, index) =>
                            <Box key={thread.id} sx={{
                                // border: '1px solid blue',
                                display: sidebarOpen ? 'flex' : 'none',
                                width: "100%",
                                gap: "6px",
                                justifyContent: 'space-between'
                            }}>
                                <Box
                                    onClick={() => setSelectedThread(thread.id)}
                                    key={index}
                                    sx={{
                                        // border: '1px solid green',
                                        paddingY: '7px',
                                        paddingX: '7px',
                                        borderRadius: '5px',
                                        backgroundColor: selectedThread === thread.id ? '#EFF3F6' : '',
                                        cursor: 'pointer',
                                        "&:hover": {
                                            backgroundColor: "#eaf0f5"
                                        },
                                    }}  >
                                    <Typography sx={{
                                        color: 'black',
                                        fontSize: '12px',
                                        fontFamily: "Lexend",
                                    }}>{truncateThreadName(thread.name)}</Typography>

                                </Box>

                                <Box
                                    onClick={() => deleteThread(thread.id, thread.name)}
                                    sx={{
                                        cursor: 'pointer',
                                        width: '17px',
                                        height: '17px',
                                        my: 'auto'
                                    }}
                                >
                                    <DeleteThreadIcon />
                                </Box>
                            </Box>
                        )
                    }

                    <Typography
                        sx={{
                            display: last7DaysThreads.length ? 'block' : 'none',
                            color: 'black',
                            fontWeight: '500',
                            fontFamily: 'Poppins',
                            fontSize: '14px',
                            paddingLeft: '7px',
                            marginTop: '20px',
                        }}
                    >
                        Last 7 days
                    </Typography>

                    {last7DaysThreads &&
                        last7DaysThreads?.map((thread, index) =>
                            <Box sx={{
                                // border: '1px solid blue',
                                display: sidebarOpen ? 'flex' : 'none',
                                width: "100%",
                                gap: "6px",
                                justifyContent: 'space-between'
                            }}>
                                <Box
                                    onClick={() => setSelectedThread(thread.id)}
                                    key={index}
                                    sx={{
                                        // border: '1px solid green',
                                        paddingY: '7px',
                                        paddingX: '7px',
                                        borderRadius: '5px',
                                        backgroundColor: selectedThread === thread.id ? '#EFF3F6' : '',
                                        cursor: 'pointer',
                                        "&:hover": {
                                            backgroundColor: "#eaf0f5"
                                        },
                                    }}  >
                                    <Typography sx={{
                                        color: 'black',
                                        fontSize: '12px',
                                        fontFamily: "Lexend",
                                    }}>{truncateThreadName(thread.name)}</Typography>

                                </Box>

                                <Box
                                    onClick={() => deleteThread(thread.id, thread.name)}
                                    sx={{
                                        cursor: 'pointer',
                                        width: '17px',
                                        height: '17px',
                                        my: 'auto'
                                    }}
                                >
                                    <DeleteThreadIcon />
                                </Box>
                            </Box>
                        )
                    }


                    <Typography
                        sx={{
                            display: moreThan7DaysThreads.length ? 'block' : 'none',
                            color: 'black',
                            fontWeight: '500',
                            fontFamily: 'Poppins',
                            fontSize: '14px',
                            paddingLeft: '7px',
                            marginTop: '20px',

                        }}
                    >Older than 7 days</Typography>

                    {moreThan7DaysThreads &&
                        moreThan7DaysThreads?.map((thread, index) =>
                            <Box sx={{
                                // border: '1px solid blue',
                                display: sidebarOpen ? 'flex' : 'none',
                                width: "100%",
                                gap: "6px",
                                justifyContent: 'space-between'
                            }}>
                                <Box
                                    onClick={() => setSelectedThread(thread.id)}
                                    key={index}
                                    sx={{
                                        // border: '1px solid green',
                                        paddingY: '7px',
                                        paddingX: '7px',
                                        borderRadius: '5px',
                                        backgroundColor: selectedThread === thread.id ? '#EFF3F6' : '',
                                        cursor: 'pointer',
                                        "&:hover": {
                                            backgroundColor: "#eaf0f5"
                                        },
                                    }}  >
                                    <Typography sx={{
                                        color: 'black',
                                        fontSize: '12px',
                                        fontFamily: "Lexend",
                                    }}>{truncateThreadName(thread?.name)}</Typography>

                                </Box>

                                <Box
                                    onClick={() => deleteThread(thread.id, thread.name)}
                                    sx={{
                                        cursor: 'pointer',
                                        width: '17px',
                                        height: '17px',
                                        my: 'auto'
                                    }}
                                >
                                    <DeleteThreadIcon />
                                </Box>
                            </Box>
                        )
                    }


                </Box>

            </Box>


            {/* PDF Modal */}
            <PdfModal openPdfModal={openPdfModal} selectedPdfUrl={selectedPdfUrl} handleClosePdfModal={handleClosePdfModal} />


            {/* The Convo area */}
            <Box sx={{
                width: getConvoAreaWidth(),
                transition: 'width 0.5s ease',
                boxSizing: 'border-box',
                backgroundColor: 'white',
                display: {
                    xs: contentLibraryOpen ? 'none' : sidebarOpen ? 'none' : 'block',
                    sm: contentLibraryOpen ? 'none' : sidebarOpen ? 'none' : 'block',
                    md: 'block',
                },
                borderTopRightRadius: {
                    xs: contentLibraryOpen ? '0px' : '0px',
                    sm: contentLibraryOpen ? '0px' : '0px',
                    md: contentLibraryOpen ? '0px' : '40px',
                },
                borderBottomRightRadius: {
                    xs: contentLibraryOpen ? '0px' : '0px',
                    sm: contentLibraryOpen ? '0px' : '0px',
                    md: contentLibraryOpen ? '0px' : '40px',
                },
                borderTopLeftRadius: {
                    xs: sidebarOpen ? '0px' : '0px',
                    sm: sidebarOpen ? '0px' : '0px',
                    md: sidebarOpen ? '0px' : '40px',
                },
                borderBottomLeftRadius: {
                    xs: sidebarOpen ? '0px' : '0px',
                    sm: sidebarOpen ? '0px' : '0px',
                    md: sidebarOpen ? '0px' : '40px',
                },
                paddingTop: '100px',
            }}>
                {/* Messages Container */}
                <Box
                    sx={{
                        height: '90%',

                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                        gap: '30px',
                        position: 'relative',
                    }}>
                    {/* Frigade */}
                    <Frigade.Card
                        flowId="flow_hYmZL54G"
                        dismissible={true}
                        style={{
                            position: "fixed", // Makes the carousel fixed position relative to the viewport
                            top: "50%", // Positions the top edge of the carousel at the center of the viewport
                            left: "50%", // Positions the left edge of the carousel at the center of the viewport
                            transform: "translate(-50%, -50%)", // Shifts the carousel back to truly center it
                            maxWidth: "600px",
                            maxHeight: "600px",
                            width: "90%", // Responsive width, adjust as needed
                            margin: "0", // Removes auto margin since it's now absolutely positioned
                            boxShadow: "0 50px 100vw rgba(0, 0, 0, 0.8)", // X=0, Y=5, Blur=15, and a subtle opacity for better effect
                            border: "1px solid #ccc",
                            borderRadius: "10px",
                            overflow: "scroll",
                            padding: "20px",
                            zIndex: 1000, // High z-index to ensure it's on top of other content
                            background: "white", // Ensures the content is readable by providing a solid background
                            fontFamily: "Poppins",
                        }}
                    />
                    <Frigade.Tour
                        flowId="flow_TYcGa40F"
                        defaultOpen={true}
                        sequential={true}
                        dismissible={true}
                        css={{
                            ".fr-card": {
                                backgroundColor: "#EFF3F6",
                            },
                            ".fr-button-primary": {
                                backgroundColor: "white",
                                color: "black",
                                borderColor: "black",
                                borderRadius: "50px",
                                borderWidth: "1px",
                                borderStyle: "solid",
                                transition: "background-color 0.3s, color 0.3s",
                                "&:hover": {
                                    backgroundColor: "black",
                                    color: "white",
                                },
                            },
                        }}
                    />

                    {
                        !showLoadingSkeleton ?

                            // Messages display Area
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '30px',
                                height: 'auto',
                                width: '100%',
                                overflowY: 'scroll',
                                my: 'end',
                            }}>
                                {selectedThreadMessages &&
                                    selectedThreadMessages?.map((message, index) => {
                                        return message.role === 'assistant' ?
                                            // Assistant Messages
                                            <Box key={message?.id}
                                                sx={{
                                                    // border: '1px solid red',
                                                    display: 'flex',
                                                    justifyContent: 'flex-start',
                                                    width: {
                                                        xs: '85%',
                                                        sm: '80%',
                                                        md: '80%',
                                                    },
                                                    mx: 'auto',
                                                }}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-start',
                                                    maxWidth: "560px",
                                                }}>
                                                    <Box sx={{
                                                        height: 'auto',
                                                        paddingTop: '10px',
                                                    }}>
                                                        <Box
                                                            component="img"
                                                            src={vcProfileImage}
                                                            alt="Assistant"
                                                            sx={{
                                                                width: '40px',
                                                                height: '40px',
                                                                borderRadius: '50%',
                                                            }}
                                                        />
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            paddingX: '10px',
                                                            color: 'black',
                                                            maxWidth: '100%',
                                                            wordWrap: 'break-word',
                                                        }}
                                                    >
                                                        <ReactMarkdown
                                                            remarkPlugins={[remarkGfm]} // Enables table support
                                                            components={{
                                                                a: ({ node, ...props }) => {
                                                                    return (
                                                                        <a
                                                                            href={props.href}
                                                                            onClick={(e) => handleMarkdownLinkClick(e, props, message.id)}
                                                                            style={{ color: '#4A90E2', cursor: 'pointer', fontWeight: 'bold', textDecoration: 'none' }}
                                                                        >
                                                                            {
                                                                                getUrlInfo(props).type === 'video' &&
                                                                                <Box component='span' sx={{
                                                                                    // border: '1px solid red',
                                                                                    display: 'inline-flex',
                                                                                    alignItems: 'center',
                                                                                    paddingY: '0',
                                                                                    paddingRight: '2px',


                                                                                }}>
                                                                                    <VideoIconForVC color='#4A90E2' />
                                                                                </Box>

                                                                            }
                                                                            {
                                                                                getUrlInfo(props).type === 'pdf' &&
                                                                                <Box component='span' sx={{
                                                                                    display: 'inline-flex',
                                                                                    alignItems: 'center',
                                                                                    paddingY: '0',
                                                                                    paddingRight: '2px',


                                                                                }}>
                                                                                    <PdfIconForVC color='#4A90E2' />
                                                                                </Box>
                                                                            }

                                                                            {props.children}
                                                                        </a>
                                                                    );
                                                                },
                                                                table: ({ node, ...props }) => (
                                                                    <table style={{ width: '100%', borderCollapse: 'collapse', }} {...props} />
                                                                ),
                                                                th: ({ node, ...props }) => (
                                                                    <th style={{ border: '1px solid #ddd', padding: '10px', backgroundColor: '#EFF3F6' }} {...props} />
                                                                ),
                                                                td: ({ node, ...props }) => (
                                                                    <td style={{ border: '1px solid #ddd', padding: '10px' }} {...props} />
                                                                ),
                                                            }}
                                                        >
                                                            {message?.content}
                                                        </ReactMarkdown>
                                                        {/* Video Player */}
                                                        {
                                                            message.metadata && Array.isArray(message?.metadata) &&
                                                            message?.metadata[0]?.type === 'video' && message?.metadata[0]?.url && (
                                                                <VideoPlayer urlInfo={selectedVideoInfo[message.id] || message?.metadata[0]} />

                                                            )

                                                        }
                                                    </Box>
                                                </Box>
                                            </Box>
                                            :
                                            // User Messages
                                            <Box
                                                key={message?.id}
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                    width: {
                                                        xs: '85%',
                                                        sm: '80%',
                                                        md: '80%',
                                                    },
                                                    mx: 'auto',
                                                    // border: '1px solid green',
                                                }}>
                                                <Box sx={{
                                                    padding: '15px',
                                                    borderRadius: '10px',
                                                    backgroundColor: '#EFF3F6',
                                                    color: 'black',
                                                    maxWidth: "560px",
                                                    wordWrap: 'break-word',
                                                }}>
                                                    <Typography sx={{
                                                        color: 'black',
                                                        fontFamily: "Lexend",
                                                        fontSize: '15px',
                                                    }}>{message.content}</Typography>
                                                </Box>
                                            </Box>
                                    })
                                }
                                <TypingLottie loadingForAiResponse={loadingForAiResponse} />

                                {/* Invisible box to scroll to it  */}
                                <Box
                                    sx={{
                                        height: '1px',
                                        width: '100%',
                                        color: 'black',
                                    }}
                                    ref={messageRef}
                                />
                            </Box>

                            :
                            <LoadingSkeleton />
                    }

                    {/* Starting FLoating Prompts */}
                    {
                        selectedThreadMessages && selectedThreadMessages?.length === 1 && selectedThreadMessages[0]?.newThread &&
                        <StartingPrompts sidebarOpen={sidebarOpen} contentLibraryOpen={contentLibraryOpen} setNewMessageFromInput={setNewMessageFromInput} />
                    }



                </Box>

                {/* Text sending Input area */}
                <Box sx={{
                    width: '90%',
                    height: '8%',
                    color: 'black',
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: "#EFF3F6",
                    borderRadius: '40px',
                    paddingX: '10px',
                    mx: 'auto',
                }}>

                    <Box
                        sx={{
                            width: '95%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: loadingForAiResponse ? "not-allowed" : "text",
                        }}>
                        <TextField
                            disabled={loadingForAiResponse}

                            value={newMessageFromInput}
                            onChange={(e) => setNewMessageFromInput(e.target.value)}
                            onKeyDown={(e) => e.key === 'Enter' && sendMessageToBackend()}
                            id="outlined-basic"

                            variant="standard"
                            placeholder="Type your message here ...."
                            maxRows={2}
                            multiline
                            fullWidth
                            InputProps={{
                                disableUnderline: true,
                            }}
                            sx={{
                                color: 'black',
                                '& .MuiInputBase-input': {
                                    color: 'black',
                                },
                                paddingX: '10px',

                                fontFamily: 'Lexend',
                            }}
                            style={{
                                backgroundColor: 'transparent',
                                cursor: loadingForAiResponse ? "not-allowed" : "text"
                            }}
                        />
                    </Box>


                    <Button
                        onClick={() => !loadingForAiResponse && sendMessageToBackend()}
                        sx={{

                            borderRadius: '50%',
                            backgroundColor: loadingForAiResponse ? '' : '#E4E8EC', // Default background
                            cursor: loadingForAiResponse ? "not-allowed" : "pointer",
                            '&:hover': {
                                backgroundColor: !loadingForAiResponse ? '#337BE8' : '', // Hover background when not loading
                                '& svg path': {
                                    stroke: !loadingForAiResponse ? '#FFFFFF' : '#111111', // Change icon color on hover
                                },
                            },
                            width: "40px",
                            height: "40px",
                            minWidth: "40px",
                            minHeight: "40px",
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <span id="hint-anchor-1" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <VirtualCoachSendIcon
                                color={
                                    loadingForAiResponse
                                        ? '#111111'  // Black icon when loading
                                        : '#111111'  // Default black icon
                                }
                            />
                        </span>

                    </Button>



                </Box>


            </Box >



            {/* The content library (right) */}
            < Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '20px',
                    height: '6%',
                    position: 'absolute',
                    // border: '1px solid red',
                    top: '20px',
                    right: '25px',
                }}
            >
                <span id="hint-anchor-5">
                    <Box
                        sx={{
                            cursor: 'pointer',
                            borderRadius: '50%',
                            padding: '7px',
                            transition: 'background-color 0.6s ease',
                            '&:hover': {
                                backgroundColor: '#EFF3F6'
                            }

                        }}
                        onClick={toggleContentLibrary}
                    >
                        {contentLibraryOpen ? <ContentLibraryCloseIcon /> : <ContentLibraryOpenIcon />}
                    </Box>
                </span>
            </ Box>

            {contentLibraryOpen && (
                <Box
                    sx={{
                        width: {
                            xs: contentLibraryOpen ? '100%' : '0%',
                            sm: contentLibraryOpen ? '100%' : '0%',
                            md: contentLibraryOpen ? '40%' : '0%',
                        },
                        transition: "width 0.5s ease",
                        height: '100%',
                        backgroundColor: 'white',
                        borderTopRightRadius: '40px',
                        borderBottomRightRadius: '40px',
                        paddingTop: contentLibraryOpen ? '40px' : '0px',
                        paddingX: contentLibraryOpen ? '15px' : '0px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        boxSizing: 'border-box',
                        overflow: 'hidden',
                    }}>
                    <Typography
                        sx={{
                            color: 'black', fontSize: '18px', fontFamily: 'Poppins', fontWeight: '400', wordWrap: 'break-word'
                        }}
                    >
                        Content
                    </Typography>

                    {/* Content Library Filters and Search Bar */}
                    <Box
                        sx={{
                            display: contentLibraryOpen ? "flex" : 'none',
                            flexDirection: "column",
                            width: "100%"
                        }}
                    >
                        <ContentTitleSearch
                            // contentLibraryData={contentLibraryData}
                            setSearchQuery={setSearchQuery}
                            setSelectedTitle={setSelectedTitle}
                            setLoadingContentLibrary={setLoadingContentLibrary}
                        />
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "start",
                                flexDirection: 'column',

                                // display: 'grid',
                                // gridTemplateColumns: '1fr 1fr 1fr',

                                width: "100%",
                                marginTop: "20px",
                                gap: '8px'
                            }}
                        >
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'start',
                                gap: '10px',
                            }}>
                                <_FilterContentFormat
                                    value={selectedFormat}
                                    items={formatOptions}
                                    handleContentLibraryFilterOptionChange={handleContentLibraryFilterOptionChange}
                                    handleFilterOptionCancel={handleFilterOptionCancel}
                                />

                                <_FilterContentFacilitator
                                    value={selectedFacilitator}
                                    items={facilitatorOptions}
                                    handleContentLibraryFilterOptionChange={handleContentLibraryFilterOptionChange}
                                    handleFilterOptionCancel={handleFilterOptionCancel}
                                />
                            </Box>


                            <_FilterContentCategory
                                value={selectedCategory}
                                items={categoryOptions}
                                handleContentLibraryFilterOptionChange={handleContentLibraryFilterOptionChange}
                                handleFilterOptionCancel={handleFilterOptionCancel}
                            />



                        </Box>
                    </Box>

                    <Box
                        sx={{
                            flexGrow: 1, // Takes up remaining space in the container
                            overflowY: 'auto', // Enables scrolling when content overflows
                            paddingY: '15px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                        }}
                    >

                        {
                            loadingContentLibrary && <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
                                <CircularProgress sx={{
                                    color: 'black'
                                }} />
                            </Box>
                        }

                        {/* Render filtered content or recent uploads */}
                        {!loadingContentLibrary && filteredContentLibraryData && filteredContentLibraryData.length && filteredContentLibraryData?.map((item) => (
                            <Box
                                key={item.content_id}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    paddingY: '10px',
                                    borderRadius: '8px',
                                }}
                            >
                                <Typography sx={{ color: 'black', fontFamily: 'Poppins', fontSize: 16, fontWeight: 500 }}>
                                    {item.title}
                                </Typography>
                                <Typography sx={{ color: '#555', fontSize: 14, fontFamily: 'Lexend' }}>
                                    {item.facilitator} - {item.format === 'Download' ? 'PDF' : item.format}
                                </Typography>

                                {item.format === 'Video' ? (

                                    <VideoPlayer forContentLibrary={true} urlInfo={{
                                        url: item.s3_link,
                                        videoTimeStamp: item.time_stamp ? item.time_stamp : "null",
                                    }} />

                                ) : item.format === 'Download' ? (
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '100%',
                                        marginTop: '10px',

                                    }}>
                                        <Box
                                            onClick={() => {
                                                setSelectedPdfUrl(item.s3_link);
                                                handleOpenPdfModal();
                                            }}
                                            sx={{
                                                width: '100%',
                                                height: '200px',
                                                // border: '1px solid red',
                                                borderRadius: '8px 8px 0px 0px',
                                                backgroundColor: '#EFF3F6',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                cursor: 'pointer',
                                                position: 'relative'
                                            }}
                                        >
                                            <PdfIconForVC width='48' height='60' />
                                        </Box>

                                        <Box sx={{
                                            height: '15%',
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'end',
                                            gap: '10px',
                                            alignItems: 'center',
                                            paddingX: '10px',
                                            borderRadius: '0px 0px 8px 8px',
                                            backgroundColor: '#EFF3F6',

                                        }}>
                                            <Box
                                                onClick={() => {
                                                    window.open(item.s3_link, '_blank');
                                                }}
                                                sx={{ cursor: 'pointer', }}>
                                                <PdfDownloadIconForVC />
                                            </Box>
                                            <Box
                                                onClick={() => {
                                                    setSelectedPdfUrl(item.s3_link);
                                                    handleOpenPdfModal();
                                                }}
                                                sx={{ cursor: 'pointer', }}>
                                                <PdfFullscreenIcon />
                                            </Box>

                                        </Box>
                                    </Box>

                                ) : null}

                                <Typography sx={{ color: '#A1A1A1', fontSize: 10, fontFamily: 'Lexend', fontWeight: '500', marginTop: "10px" }}> Try these prompts</Typography>
                                <Box
                                    sx={{
                                        marginTop: '10px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '8px'
                                    }}
                                >
                                    {item.parsed_prompts.slice(0, 2).map((prompt, index) => (
                                        <Box
                                            key={index}
                                            onClick={() => handlePromptSelection(prompt, item.format)}
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                width: "100%",

                                                gap: "8px",
                                                justifyContent: 'space-between',
                                                cursor: 'pointer',
                                                px: '10px',
                                                py: '7px',
                                                borderRadius: '5px',
                                                borderBottom: '1px solid #D9E4EC',
                                                ':hover': {
                                                    backgroundColor: "#eaf0f5"
                                                }
                                            }}>
                                            <Typography
                                                key={index}
                                                sx={{
                                                    color: '#606060',
                                                    fontSize: 12,
                                                    fontFamily: 'Lexend',
                                                    fontWeight: '300',
                                                    letterSpacing: 0.40,
                                                    wordWrap: 'break-word'
                                                }}
                                            >
                                                {prompt}
                                            </Typography>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    cursor: 'pointer',
                                                    padding: '5px',
                                                }}

                                            >
                                                <VirtualCoachSendIcon />
                                            </Box>
                                        </Box>
                                    ))}
                                </Box>

                            </Box>


                        ))}
                        {
                            showPaginationBar && !loadingContentLibrary && <PaginationForContentLibrary paginationSelectedPage={paginationSelectedPage} paginationCount={paginationCount} handlePaginationChange={handlePaginationChange} />
                        }

                    </Box>


                </Box>
            )}



        </Box >


    )
}
export default RetuneIframe;
