import { Box, Button, Tab, Tabs, Typography } from "@mui/material";
import GeneralBigFiveScore from "components/Assessments/TheBigFive/GeneralBigFiveScore";
import { useEffect, useState } from "react";
import { generateClient } from "aws-amplify/data";
import { useNavigate } from "react-router-dom";
import { _AssessmentsFilter } from "./AssessmentsFilter";

/**
 * @type {import('aws-amplify/data').Client<import('../amplify/data/resource').Schema>}
 */
const resourceClient = generateClient({ authMode: "userPool" });

const Assessments = ({ user, userData, clientData, loading, setLoading }) => {
    const [tabIndex, setTabIndex] = useState(0);
    const [assessmentData, setAssessmentData] = useState([]);
    const [selectedAssessment, setSelectedAssessment] = useState(null);
    const [filterValue, setFilterValue] = useState(null);
    const navigate = useNavigate();

    const handleTabChange = (event, newIndex) => {
        setTabIndex(newIndex);
    };

    const fetchAssessmentData = async () => {
        try {
            const { data: assessmentRes, errors } = await resourceClient.models.assessments.list({
                filter: {
                    user_id: { eq: clientData.user_id },
                    test_type: { eq: "The Big Five Personality Test" },
                    coach_id: { eq: user.sub },
                },
            });

            if (errors) {
                console.error("GraphQL errors: ", errors);
                return;
            }

            const completedAssessments = (assessmentRes || []).filter(item => item.completed);
            const parsedAssessments = completedAssessments.map(item => ({
                ...item,
                results: item.results ? JSON.parse(item.results) : [],  // Parse `results` if present
            }));

            setAssessmentData(parsedAssessments);

            // Automatically select the most recent completed assessment
            if (parsedAssessments.length > 0) {
                const mostRecent = parsedAssessments.reduce((prev, current) => (
                    new Date(prev.createdAt) > new Date(current.createdAt) ? prev : current
                ));
                setSelectedAssessment(mostRecent);
                setFilterValue(mostRecent.id);  // Set the default filter to most recent
            }
        } catch (error) {
            console.error("Error fetching assessment data: ", error);
        }
    };

    useEffect(() => {
        if (user && clientData) {
            fetchAssessmentData();
        }
    }, [user, clientData]);

    // Handle changing selected assessment in filter
    const handleFilterChange = (assessmentId) => {
        if (assessmentId === "none") {
            // When filter is cleared, reset to the most recent assessment
            const mostRecent = assessmentData.reduce((prev, current) => (
                new Date(prev.createdAt) > new Date(current.createdAt) ? prev : current
            ));
            setSelectedAssessment(mostRecent);
            setFilterValue(mostRecent.id);  // Reset the filter to the most recent
        } else {
            const selected = assessmentData.find(assessment => assessment.id === assessmentId);
            setSelectedAssessment(selected);
            setFilterValue(assessmentId);  // Set the filter value to match the selected assessment
        }
    };

    // Create items for the filter dropdown
    const filterItems = assessmentData.map(assessment => ({
        key: new Date(assessment.createdAt).toLocaleDateString(), // Show `createdAt` date in the filter
        value: assessment.id, // Use `id` as the value for filtering
    }));

    return (
        <Box
            sx={{
                marginTop: "20px",
                display: "flex",
                width: "100%",
                flexDirection: "column",
                gap: "16px",
                maxHeight: "535px",
                flexGrow: 1,
                overflowY: {
                    xs: "auto",
                },
                paddingRight: {
                    lg: "8px",
                },
                alignItems: "flex-start"
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}
            >
                <Tabs
                    value={tabIndex}
                    onChange={handleTabChange}
                >
                    <Tab sx={{ ...tabStyle , textTransform: "capitalize"}} label="Big Five" />
                </Tabs>
                <Button
                    sx={{
                        color: "black",
                        background: 'white',
                        border: "1px solid black",
                        borderRadius: 52,
                        width: "150px",
                    }}
                    disabled={!selectedAssessment?.id}
                    onClick={() => navigate(`/platform/coach/assessments/view/individual-breakdown/${selectedAssessment?.id}/${selectedAssessment?.user_id}`)}
                >
                    <Typography
                        sx={{
                            fontFamily: "Poppins",
                            fontSize: 12,
                            letterSpacing: "0.05em",
                            lineHeight: "20px",
                            fontWeight: 500,
                            textTransform: "capitalize"
                        }}
                    >
                        View Assessment
                    </Typography>
                </Button>
            </Box>

            {/* Filter for Assessments */}
            <_AssessmentsFilter
                value={filterValue}
                handleChange={handleFilterChange}
                items={filterItems}
            />

            {/* Tab Panel to Display Selected Assessment */}
            <Box sx={{ marginTop: "20px", display: "flex", flexDirection: "column", width: "100%", alignItems: "flex-start" }}>
                {selectedAssessment ? (
                    <GeneralBigFiveScore assessmentData={selectedAssessment} />
                ) : (
                    <Typography>No assessment selected or available</Typography>
                )}
            </Box>
        </Box>
    );
};

export default Assessments;

const tabStyle = {
    color: "black",
    fontSize: 12,
    fontWeight: 500,
    letterSpacing: "0.05rem",
    lineHeight: "18px",
    fontFamily: "Poppins",
    borderBottom: "1px solid #E8E8E8",
    "&.Mui-selected": {
        borderBottom: "1px solid #000",
    },
};
