import React, { useState } from "react";

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Checkbox, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);


export const _CallListFilterSelect = ({
  value,
  handleChange,
  items,
}) => {
  const [opened, setOpened] = useState(false);

  return (
    <Box
      sx={{
        // minWidth: items[0]?.key === "Participant" ? "200px" : "160px",
        padding: {
          xs: "8px",
          // xl: "16px",
        },
        position: "relative",
        borderWidth: opened === true ? "1px 1px 0" : "1px",
        borderColor: "#CBD0C8",
        borderStyle: "solid",
        borderRadius: opened === true ? "8px 8px 0 0" : "8px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          cursor: "pointer",
        }}
        onClick={() => setOpened(!opened)}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              color: "black",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: 500,
              letterSpacing: "0.05em",
              lineHeight: "18px",
              textTransform: "capitalize"
            }}
          >
            {value === "none" ? items[0]?.key : value}
          </Typography>
          {value !== "none" && value !== "" && value !== null && (
            <CloseIcon
              sx={{
                color: "black",
                fontSize: {
                  xs: "10px",
                  // xl: "16px",
                },
                cursor: "pointer",
                background: "#AAA",
                borderRadius: "50%",
                padding: "2px",
              }}
              onClick={(e) => {
                e.stopPropagation();
                handleChange("none");
              }}
            />
          )}
        </Box>
        <KeyboardArrowDownIcon
          sx={{
            color: "black",
            transition: "all 0.3s ease",
            transform: `rotate(${opened === true ? '180deg' : '0deg'})`,
            width: "12px",
            height: "12px",
          }}
        />
      </Box>
      <Box
        sx={{
          display: opened === true ? "flex" : "none",
          flexDirection: "column",
          position: "absolute",
          top: {
            xs: "30px",
            // xl: "56px",
          },
          left: "-1px",
          zIndex: 10,
          width: "calc(100% + 2px)",
          borderWidth: "0 1px 1px",
          borderColor: "#CBD0C8",
          borderStyle: "solid",
          borderRadius: "0 0 8px 8px",
          maxHeight: "210px",
          overflowY: "auto",
          background: "white",
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "rgb(207, 207, 207)",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "rgb(207, 207, 207)",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "transparent",
          },
        }}
      >
        <Box
          sx={{
            width: "calc(100% - 30px)",
            borderTop: "1px solid #E0E3DE",
            margin: "0 15px 16px",
            position: "sticky",
            top: "0",
          }}
        />
        {items.map((item) =>
          item.value !== "none" && item.value !== "" && item.value !== null && (
            <Box
              sx={{
                padding: "12px",
                fontFamily: "Lexend",
                fontSize: {
                  xs: "10px",
                },
                fontWeight: "500",
                lineHeight: "1.5",
                letterSpacing: "-0.02",
                color: "black",
                background: "white",
                cursor: "pointer",
                "&:hover": {
                  background: "#F5F6F4",
                }
              }}
              onClick={() => {
                setOpened(false);
                handleChange(item.value);
              }}
            >
              {item.key}
            </Box>
          )
        )}
      </Box>
      {opened === true && (
        <Box
          sx={{
            position: "fixed",
            width: "100%",
            height: "100%",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 9,
          }}
          onClick={() => setOpened(false)}
        />
      )}
    </Box>
  );
};

export const _CallListFilterMultiSelect = ({
  value,
  handleChange,
  items,
}) => {
  const [opened, setOpened] = useState(false);

  return (
    <Box
      sx={{
        // minWidth: "220px",
        padding: {
          xs: "8px",
          // xl: "16px",
        },
        position: "relative",
        borderWidth: opened === true ? "1px 1px 0" : "1px",
        borderColor: "#CBD0C8",
        borderStyle: "solid",
        borderRadius: opened === true ? "8px 8px 0 0" : "8px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          cursor: "pointer",
        }}
        onClick={() => setOpened(!opened)}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            color: "black",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 500,
            letterSpacing: "0.05em",
            lineHeight: "18px",
            textTransform: "capitalize"
          }}
        >
          Session Status
        </Typography>
        <KeyboardArrowDownIcon
          sx={{
            color: "black",
            transition: "all 0.3s ease",
            transform: `rotate(${opened === true ? '180deg' : '0deg'})`,
            width: "12px",
            height: "12px",
          }}
        />
      </Box>
      <Box
        sx={{
          display: opened === true ? "flex" : "none",
          flexDirection: "column",
          position: "absolute",
          top: {
            xs: "30px",
            // xl: "56px",
          },
          left: "-1px",
          zIndex: 10,
          width: "calc(100% + 2px)",
          borderWidth: "0 1px 1px",
          borderColor: "#CBD0C8",
          borderStyle: "solid",
          borderRadius: "0 0 8px 8px",
          maxHeight: "210px",
          overflowY: "auto",
          background: "white",
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "rgb(207, 207, 207)",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "rgb(207, 207, 207)",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "transparent",
          },
        }}
      >
        {items.map((item) =>
          item.value !== "none" && item.value !== "" && (
            <Box
              sx={{
                padding: "12px",
                fontFamily: "Lexend",
                fontSize: {
                  xs: "10px",
                  // xl: "16px",
                },
                fontWeight: "500",
                lineHeight: "1.5",
                letterSpacing: "-0.02",
                color: "black",
                background: "white",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                "&:hover": {
                  background: "#F5F6F4",
                }
              }}
              onClick={() => {
                handleChange(item.value);
              }}
            >
              {item.key}
              <Checkbox
                sx={{
                  color: "#A0A0A0",
                  "&.Mui-checked": {
                    color: "#294F4A"
                  }
                }}
                checked={value?.indexOf(item.value) > -1}
              />
            </Box>
          )
        )}
      </Box>
      {opened === true && (
        <Box
          sx={{
            position: "fixed",
            width: "100%",
            height: "100%",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 9,
          }}
          onClick={() => setOpened(false)}
        />
      )}
    </Box>
  );
};

export const _CallListFilterDateRange = ({
  startDate,
  endDate,
  handleStartDateChange,
  handleEndDateChange,
}) => {
  const [opened, setOpened] = useState(false);

  return (
    <Box
      sx={{
        padding: {
          xs: "8px",
          // xl: "16px",
        },
        position: "relative",
        borderWidth: opened === true ? "1px 1px 0" : "1px",
        borderColor: "#CBD0C8",
        borderStyle: "solid",
        borderRadius: opened === true ? "8px 8px 0 0" : "8px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          cursor: "pointer",
        }}
        onClick={() => setOpened(!opened)}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            color: "black",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 500,
            letterSpacing: "0.05em",
            lineHeight: "18px",
            textTransform: "capitalize"
          }}
        >
          Session Dates
        </Typography>
        <KeyboardArrowDownIcon
          sx={{
            color: "black",
            transition: "all 0.3s ease",
            transform: `rotate(${opened === true ? '180deg' : '0deg'})`,
            width: "12px",
            height: "12px",
          }}
        />
      </Box>
      <Box
        sx={{
          display: opened === true ? "flex" : "none",
          flexDirection: "column",
          position: "absolute",
          top: {
            xs: "30px",
            // xl: "56px",
          },
          left: "-1px",
          zIndex: 10,
          width: "calc(100% + 2px)",
          borderWidth: "0 1px 1px",
          borderColor: "#CBD0C8",
          borderStyle: "solid",
          borderRadius: "0 0 8px 8px",
          maxHeight: "210px",
          overflowY: "auto",
          background: "white",
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "rgb(207, 207, 207)",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "rgb(207, 207, 207)",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "transparent",
          },
        }}
      >
        <Box
          sx={{
            fontFamily: "Lexend",
            fontSize: {
              xs: "10px",
              // xl: "16px",
            },
            fontWeight: "500",
            lineHeight: "1.5",
            letterSpacing: "-0.02",
            color: "black",
            background: "white",
            cursor: "pointer",
            "&:hover": {
              background: "#F5F6F4",
            },
            "& .MuiFormControl-root": {
              width: "100%",
            }
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format="YYYY-MM-DD"
              label="Start Date"
              value={startDate ? dayjs(startDate).utc() : null}
              onChange={(date) => {
                if (date) {
                  const formattedDate = dayjs(date).utc().format("YYYY-MM-DD");
                  handleStartDateChange(formattedDate);
                }
              }}
              sx={{
                fontFamily: "Lexend",
                fontWeight: 500,
                fontSize: {
                  xs: "10px",
                  // xl: "16px",
                },
                lineHeight: "1.5",
                letterSpacing: "-0.02",
                color: "black",
                width: "100%",
                height: "100%",
                "& svg": {
                  color: "black",
                },
                "& input": {
                  fontWeight: 500,
                  fontSize: {
                    xs: "10px",
                    // xl: "16px",
                  },
                  lineHeight: "1.5",
                  letterSpacing: "-0.02",
                  color: "black",
                },
                "& fieldset": {
                  border: "none !important",
                  outline: "none !important",
                },
                "& .MuiFormLabel-root": {
                  top: "50%",
                  left: "16px",
                  transform: "translateY(-50%)",
                  fontFamily: "Lexend",
                  fontWeight: 500,
                  fontSize: {
                    xs: "10px",
                    // xl: "16px",
                  },
                  lineHeight: "1.5",
                  letterSpacing: "-0.02",
                  color: "black",
                  "&.MuiFormLabel-filled, &.Mui-focused": {
                    display: "none"
                  }
                }
              }}
            />
          </LocalizationProvider>
        </Box>
        <Box
          sx={{
            fontFamily: "Lexend",
            fontSize: {
              xs: "10px",
              // xl: "16px",
            },
            fontWeight: "500",
            lineHeight: "1.5",
            letterSpacing: "-0.02",
            color: "black",
            background: "white",
            cursor: "pointer",
            "&:hover": {
              background: "#F5F6F4",
            },
            "& .MuiFormControl-root": {
              width: "100%",
            }
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format="YYYY-MM-DD"
              label="End Date"
              value={endDate ? dayjs(endDate).utc() : null}
              onChange={(date) => {
                if (date) {
                  const formattedDate = dayjs(date).utc().format("YYYY-MM-DD");
                  handleEndDateChange(formattedDate);
                }
              }}
              sx={{
                fontFamily: "Lexend",
                fontWeight: 500,
                fontSize: {
                  xs: "10px",
                  // xl: "16px",
                },
                lineHeight: "1.5",
                letterSpacing: "-0.02",
                color: "black",
                width: "100%",
                height: "100%",
                "& svg": {
                  color: "black",
                },
                "& input": {
                  fontWeight: 500,
                  fontSize: {
                  xs: "10px",
                  // xl: "16px",
                },
                  lineHeight: "1.5",
                  letterSpacing: "-0.02",
                  color: "black",
                },
                "& fieldset": {
                  border: "none !important",
                  outline: "none !important",
                },
                "& .MuiFormLabel-root": {
                  top: "50%",
                  left: "16px",
                  transform: "translateY(-50%)",
                  fontFamily: "Lexend",
                  fontWeight: 500,
                  fontSize: {
                  xs: "10px",
                  // xl: "16px",
                },
                  lineHeight: "1.5",
                  letterSpacing: "-0.02",
                  color: "black",
                  "&.MuiFormLabel-filled, &.Mui-focused": {
                    display: "none"
                  }
                }
              }}
            />
          </LocalizationProvider>
        </Box>
      </Box>
      {opened === true && (
        <Box
          sx={{
            position: "fixed",
            width: "100%",
            height: "100%",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 9,
          }}
          onClick={() => setOpened(false)}
        />
      )}
    </Box>
  );
};