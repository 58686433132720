import React from "react";

function ShareIcon({ color }) {
  if (color === "disabled") {
    return (
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.125 13.6852C20.125 15.0797 20.125 15.777 19.9868 16.3524C19.5479 18.1807 18.1205 19.6081 16.2922 20.047C15.7168 20.1852 15.0195 20.1852 13.625 20.1852L12.125 20.1852C9.32474 20.1852 7.92461 20.1852 6.85505 19.6402C5.91424 19.1609 5.14933 18.396 4.66997 17.4552C4.125 16.3856 4.125 14.9855 4.125 12.1852L4.125 11.6852C4.125 9.35541 4.125 8.19056 4.5056 7.2717C5.01307 6.04656 5.98644 5.07319 7.21158 4.56572C7.98844 4.24394 8.94114 4.19421 10.625 4.18652M19.8847 9.63961C20.1471 8.00676 20.1947 6.3549 20.0269 4.72644C20.0148 4.60834 19.963 4.5036 19.8847 4.42533M19.8847 4.42533C19.8065 4.34707 19.7017 4.29529 19.5836 4.28312C17.9552 4.11531 16.3033 4.16298 14.6705 4.42533M19.8847 4.42533L10.125 14.1851" stroke="#A6A6A6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    )
  } else if (color === "enabled") {
    return (
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.125 13.6852C20.125 15.0797 20.125 15.777 19.9868 16.3524C19.5479 18.1807 18.1205 19.6081 16.2922 20.047C15.7168 20.1852 15.0195 20.1852 13.625 20.1852L12.125 20.1852C9.32474 20.1852 7.92461 20.1852 6.85505 19.6402C5.91424 19.1609 5.14933 18.396 4.66997 17.4552C4.125 16.3856 4.125 14.9855 4.125 12.1852L4.125 11.6852C4.125 9.35541 4.125 8.19056 4.5056 7.2717C5.01307 6.04656 5.98644 5.07319 7.21158 4.56572C7.98844 4.24394 8.94114 4.19421 10.625 4.18652M19.8847 9.63961C20.1471 8.00676 20.1947 6.3549 20.0269 4.72644C20.0148 4.60834 19.963 4.5036 19.8847 4.42533M19.8847 4.42533C19.8065 4.34707 19.7017 4.29529 19.5836 4.28312C17.9552 4.11531 16.3033 4.16298 14.6705 4.42533M19.8847 4.42533L10.125 14.1851" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    )
  } else {
    return (
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.125 13.6852C20.125 15.0797 20.125 15.777 19.9868 16.3524C19.5479 18.1807 18.1205 19.6081 16.2922 20.047C15.7168 20.1852 15.0195 20.1852 13.625 20.1852L12.125 20.1852C9.32474 20.1852 7.92461 20.1852 6.85505 19.6402C5.91424 19.1609 5.14933 18.396 4.66997 17.4552C4.125 16.3856 4.125 14.9855 4.125 12.1852L4.125 11.6852C4.125 9.35541 4.125 8.19056 4.5056 7.2717C5.01307 6.04656 5.98644 5.07319 7.21158 4.56572C7.98844 4.24394 8.94114 4.19421 10.625 4.18652M19.8847 9.63961C20.1471 8.00676 20.1947 6.3549 20.0269 4.72644C20.0148 4.60834 19.963 4.5036 19.8847 4.42533M19.8847 4.42533C19.8065 4.34707 19.7017 4.29529 19.5836 4.28312C17.9552 4.11531 16.3033 4.16298 14.6705 4.42533M19.8847 4.42533L10.125 14.1851" stroke="url(#paint0_linear)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <defs>
          <linearGradient id="paint0_linear" x1="19.5443" y1="22.8955" x2="2.21504" y2="11.9907" gradientUnits="userSpaceOnUse">
            <stop stop-color="#242F8C" />
            <stop offset="0.401042" stop-color="#03093A" />
            <stop offset="1" stop-color="#D8659B" />
          </linearGradient>
        </defs>
      </svg>
    );
  }
}

export default ShareIcon;
